<template>
    <v-card class="elevation-0">
        <v-list-item one-line>
            <v-list-item-content style="padding:0px">
                <div class="overline">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"> {{name}} </span>
                        </template>
                        <span>{{description}}</span>
                    </v-tooltip>
                    <v-list-item-title style="line-height: 22px">
                        <v-icon dense class="pb-1" :color="color" small>
                            {{color === 'red' ?
                            "mdi-arrow-down-bold" : color==='green' ? "mdi-arrow-up-bold" : ''}}
                        </v-icon>
                        {{ numberWithCommas(roundDecimal(value, 2)) }}
                    </v-list-item-title>
                </div>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>
<script>
import { helper } from "@components/mixins/helper";
export default {
    mixins: [helper],
    props: {
        cols: { type: Number, default: 4 },
        name: { type: String, default: "" },
        value: { type: Number, default: -1 },
        description: { type: String, default: "-1" }
    },
    data () {
        return {
            color: "",
            initialvalue: 0,
            compare: Object,
            timeOut: null,
        };
    },
    watch:{
        value(nv){
            if (nv > this.initialvalue) {
                this.color = "green";
            } else if (nv < this.initialvalue) {
                this.color = "red";
            } else {
                this.color = "";
            }
            this.initialvalue = nv;
            this.timeOut = setTimeout(()=>{
                if(nv === this. initialvalue) {
                    this.color = ""
                }
            },1200)
        }
    },
    destroyed () {
        clearInterval(this.compare);
    }
};
</script>
