<template>
    <div>
        <v-card ref="card" class="flex-shrink-0 test1234" outlined>
            <v-card-title style="border-bottom: 1px solid #404040" class="pt-1">
                <div
                    class="text-left"
                    style="
                        margin-left: 0;
                        width: 50%;
                        font-size: 13px;
                        font-weight: normal;
                    "
                >
                    ALL BOOKS
                </div>
                <div class="text-right" style="margin-right: 0; width: 50%">
                    <v-tooltip bottom v-if="loadDailySummaryIcon === 1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                dark
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                class="mr-1"
                                @click="dailySummaryDialog = true"
                                >mdi-text-box</v-icon
                            >
                        </template>
                        <span>Historical Daily Summary</span>
                    </v-tooltip>
                    <!-- CSV导出 -->
                    <download-excel
                        :data="briefData"
                        :fields="allBookCsvFields"
                        style="display: inline"
                        name="All Book"
                    >
                        <v-icon
                            color="green"
                            dark
                            dense
                            style="cursor: pointer"
                        >
                            mdi-microsoft-excel
                        </v-icon>
                    </download-excel>
                    <!-- <v-menu offset-y dense>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                color="default"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="ml-1"
                                style="height: 19px"
                            >
                                {{
                                    allBookIntervalOptions.find(
                                        (option) =>
                                            option.value === selectedInterval
                                    ).key
                                }}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-for="item in allBookIntervalOptions"
                                :key="item.key"
                                @click="UPDATE_SELECTED_INTERVAL(item.value)"
                            >
                                <v-list-item-title>{{
                                    item.key
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu> -->
                </div>
            </v-card-title>

            <v-data-table
                dense
                :headers="briefHeader"
                :items="computedParentData"
                item-key="name"
                :hide-default-footer="true"
                class="elevation-1"
                :height="tableHeight"
                :items-per-page="-1"
                :header-props="{ sortIcon: null }"
                fixed-header
                :expanded="expanded"
            >
                <template v-slot:expanded-item="{ headers, item }">
                    <td
                        :colspan="headers.length"
                        v-if="expanded.includes(item.book)"
                        style="padding: 0px"
                    >
                        <v-data-table
                            class="all-book-expand-item"
                            dense
                            fixed-header
                            :headers="briefChildrenHeader"
                            :hide-default-header="true"
                            :hide-default-footer="true"
                            :key="item.book"
                            :header-props="{ sortIcon: null }"
                            :items="computedChildrenData(item.book)"
                            item-key="book"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="bookCell" align="left">
                                        <v-icon small color="primary"
                                            >mdi-subdirectory-arrow-right</v-icon
                                        ><a
                                            v-on:click="changeBook(item.book)"
                                            >{{ item.book }}</a
                                        >
                                    </td>
                                    <td class="NPNLCell" align="right">
                                        {{
                                            numberWithCommas(
                                                parseFloat(item.NPNL).toFixed(2)
                                            )
                                        }}
                                    </td>
                                    <td class="CPNLCell" align="right">
                                        {{
                                            numberWithCommas(
                                                parseFloat(item.CPNL).toFixed(2)
                                            )
                                        }}
                                    </td>

                                    <td class="FPNLCell" align="right">
                                        <div
                                            :style="
                                                'backgroundColor:' + item.color
                                            "
                                        >
                                            {{
                                                numberWithCommas(
                                                    parseFloat(
                                                        item.FPNL
                                                    ).toFixed(2)
                                                )
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        class="mtdPnlCell"
                                        align="right"
                                        v-if="
                                            briefChildrenHeader.find(
                                                (item) =>
                                                    item.value === 'mtdPnl'
                                            ).align !== ' d-none'
                                        "
                                    >
                                        {{ outputMtdPnl(item.book) }}
                                    </td>
                                    <td
                                        class="mtdVolCell"
                                        align="right"
                                        v-if="
                                            briefChildrenHeader.find(
                                                (item) =>
                                                    item.value === 'mtdVol'
                                            ).align !== ' d-none'
                                        "
                                    >
                                        {{ outputMtdVolume(item.book) }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </td>
                </template>
                <template v-slot:header.NPNL="{ header }">
                    <div style="min-width: 90px; height: 100%">
                        {{ header.text }}
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="grey"
                                    size="14"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-information
                                </v-icon>
                            </template>
                            <span>Current net PNL</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:header.CPNL="{ header }">
                    <div style="min-width: 90px; height: 100%">
                        {{ header.text }}
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="grey"
                                    size="14"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-information
                                </v-icon>
                            </template>
                            <span>Current closed PNL</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:header.FPNL="{ header }">
                    <div style="min-width: 90px; height: 100%">
                        {{ header.text }}
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="grey"
                                    size="14"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-information
                                </v-icon>
                            </template>
                            <span>Current floating PNL</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:header.mtdPnl="{ header }">
                    <div style="min-width: 90px; height: 100%">
                        {{ header.text }}
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="grey"
                                    size="14"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-information
                                </v-icon>
                            </template>
                            <span>Month to date PNL (Daily Updated)</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:header.mtdVol="{ header }">
                    <div style="min-width: 90px; height: 100%">
                        {{ header.text }}
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="grey"
                                    size="14"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-information
                                </v-icon>
                            </template>
                            <span>Month to date volume (Daily Updated)</span>
                        </v-tooltip>
                    </div>
                </template>

                <template v-slot:item="{ item }">
                    <tr @click="rowClicked(item)">
                        <td>
                            <a v-on:click="changeBook(item.book)">{{
                                item.book
                            }}</a>
                            <span>
                                <v-icon
                                    x-small
                                    v-if="
                                        item.hasChildren &&
                                        expanded.includes(item.book)
                                    "
                                    >mdi-arrow-down</v-icon
                                >
                                <v-icon x-small v-else-if="item.hasChildren"
                                    >mdi-arrow-right</v-icon
                                >
                            </span>
                        </td>
                        <td align="right">
                            {{
                                numberWithCommas(
                                    parseFloat(item.NPNL).toFixed(2)
                                )
                            }}
                        </td>
                        <td align="right">
                            {{
                                numberWithCommas(
                                    parseFloat(item.CPNL).toFixed(2)
                                )
                            }}
                        </td>
                        <td align="right">
                            <div :style="'backgroundColor:' + item.color">
                                {{
                                    numberWithCommas(
                                        parseFloat(item.FPNL).toFixed(2)
                                    )
                                }}
                            </div>
                        </td>
                        <td align="right">{{ outputMtdPnl(item.book) }}</td>
                        <td align="right">{{ outputMtdVolume(item.book) }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dailySummaryDialog" width="700" :persistent="true">
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">
                        Daily Summary
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-icon
                        :disabled="
                            dailySummaryTableData.length === 0 ? true : false
                        "
                        color="green"
                        @click="onDailySummaryDataDownload"
                    >
                        mdi-microsoft-excel
                    </v-icon>

                    <download-excel
                        id="dailySummaryDownload"
                        style="display: none"
                        :data="dailySummaryTableData"
                        :fields="test"
                        type="csv"
                        name="DailySummary.csv"
                    />

                    <v-btn
                        color="white"
                        icon
                        @click="dailySummaryDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="3" class="pb-0">
                            <v-menu
                                v-model="dateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="selectedDailySummaryDate"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="selectedDailySummaryDate"
                                    @input="dateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="1" class="pt-7 pl-0 pb-0">
                            <v-btn
                                :loading="loading"
                                fab
                                x-small
                                color="primary"
                                shape="circle"
                                @click="fetchDailySummary"
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>

                        <v-col cols="12"
                            ><v-data-table
                                dense
                                :loading="loading"
                                :headers="dailySummaryTableHeader"
                                :items="dailySummaryTableData"
                                :header-props="{ sortIcon: null }"
                                item-key="id"
                                class="elevation-0 flex-grow-1"
                                :items-per-page="25"
                                id="daily-summary-table"
                                fixed-header
                            />
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { helper } from "@components/mixins/helper";
import { permission } from "@components/mixins/permission";
import { getDailySummary } from "@services/statistic/daily-summary";
import { snackbar } from "@components/mixins/snackbar";

export default {
    data() {
        return {
            expanded: [],
            parentChildrenRelation: {},
            isWatching: true,
            loadDailySummaryIcon: 0,
            dailySummaryTableData: [],
            loading: false,
            selectedDailySummaryDate: null,
            dailySummaryDialog: false,
            dailySummaryTableHeader: [
                { text: "Book", value: "book", width: 30, align: "left" },
                {
                    text: "Close PNL EOD",
                    value: "total_close_pnl_at_eod",
                    width: 60,
                    align: "right",
                },
                {
                    text: "Net PNL EOD",
                    value: "total_net_pnl_at_eod",
                    width: 60,
                    align: "right",
                },
                {
                    text: "Open PNL EOD",
                    value: "total_open_pnl_at_eod",
                    width: 60,
                    align: "right",
                },
            ],
            dateMenu: false,
            dailySummaryTableData: [],
            test: {
                Book: "book",
                "Close PNL EOD": "total_close_pnl_at_eod",
                "Net PNL EOD": "total_net_pnl_at_eod",
                "Open PNL EOD": "total_open_pnl_at_eod",
            },
        };
    },
    mixins: [helper, permission, snackbar],
    props: {
        mtdPnl: null,
        mtdVolume: null,
        tableHeight: Number,
    },
    computed: {
        ...mapState("Home", [
            "briefHeader",
            "briefChildrenHeader",
            "briefData",
            "bookMap",
            "allBookCsvFields",
            "books",
            "allBookIntervalOptions",
            "selectedInterval",
        ]),

        computedParentData() {
            const output = this.briefData.filter((item) => !item.isChild);
            return output;
        },
    },
    watch: {
        computedParentData: {
            handler(nv) {
                const bookWidth =
                    document.getElementsByClassName("bookHeader")[0]
                        .clientWidth;
                const NPNLWidthWidth =
                    document.getElementsByClassName("NPNLHeader")[0]
                        .clientWidth;
                const CPNLWidthWidth =
                    document.getElementsByClassName("CPNLHeader")[0]
                        .clientWidth;
                const FPNLWidthWidth =
                    document.getElementsByClassName("FPNLHeader")[0]
                        .clientWidth;
                const MPNLWidthWidth =
                    document.getElementsByClassName("mtdPnlHeader")[0]
                        .clientWidth;
                const MVOLWidthWidth =
                    document.getElementsByClassName("mtdVolHeader")[0]
                        .clientWidth;

                Array.from(document.getElementsByClassName("bookCell")).forEach(
                    (el) => {
                        el.style.width = bookWidth + "px";
                    }
                );
                Array.from(document.getElementsByClassName("NPNLCell")).forEach(
                    (el) => {
                        el.style.width = NPNLWidthWidth + "px";
                    }
                );
                Array.from(document.getElementsByClassName("CPNLCell")).forEach(
                    (el) => {
                        el.style.width = CPNLWidthWidth + "px";
                    }
                );
                Array.from(document.getElementsByClassName("FPNLCell")).forEach(
                    (el) => {
                        el.style.width = FPNLWidthWidth + "px";
                    }
                );
                Array.from(
                    document.getElementsByClassName("mtdPnlCell")
                ).forEach((el) => {
                    el.style.width = MPNLWidthWidth + "px";
                });
                Array.from(
                    document.getElementsByClassName("mtdVolCell")
                ).forEach((el) => {
                    el.style.width = MVOLWidthWidth + "px";
                });
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations("Home", [
            "UPDATE_ALL_BOOK_HEADER",
            "UPDATE_SELECTED_INTERVAL",
        ]),
        computedChildrenData(parent) {
            const children = this.parentChildrenRelation[parent];
            const output = this.briefData.filter((item) =>
                children.includes(item.book)
            );
            return output;
        },
        outputMtdPnl(book) {
            let data = this.mtdPnl.filter((item) => item.book === book);
            if (data.length !== 0) {
                let isPos = true;
                let returnString = null;
                if (data[0].mtd_pnl < 0) {
                    isPos = false;
                }
                if (Math.abs(data[0].mtd_pnl) >= 1000000000) {
                    returnString =
                        (Math.abs(data[0].mtd_pnl) / 1000000000)
                            .toFixed(2)
                            .toString() + " B";
                } else if (Math.abs(data[0].mtd_pnl) >= 1000000) {
                    returnString =
                        (Math.abs(data[0].mtd_pnl) / 1000000)
                            .toFixed(2)
                            .toString() + " M";
                } else if (Math.abs(data[0].mtd_pnl) >= 1000) {
                    returnString =
                        (Math.abs(data[0].mtd_pnl) / 1000)
                            .toFixed(2)
                            .toString() + " K";
                } else {
                    returnString = Math.abs(data[0].mtd_pnl)
                        .toFixed(2)
                        .toString();
                }
                return isPos ? returnString : "-" + returnString;
            }
            return 0;
        },
        outputMtdVolume(book) {
            let data = this.mtdVolume.filter((item) => item.book === book);
            if (data.length !== 0) {
                let isPos = true;
                let returnString = null;
                if (data[0].mtd_vol < 0) {
                    isPos = false;
                }
                if (Math.abs(data[0].mtd_vol) >= 1000000000) {
                    returnString =
                        (Math.abs(data[0].mtd_vol) / 1000000000)
                            .toFixed(2)
                            .toString() + " B";
                } else if (Math.abs(data[0].mtd_vol) >= 1000000) {
                    returnString =
                        (Math.abs(data[0].mtd_vol) / 1000000)
                            .toFixed(2)
                            .toString() + " M";
                } else if (Math.abs(data[0].mtd_vol) >= 1000) {
                    returnString =
                        (Math.abs(data[0].mtd_vol) / 1000)
                            .toFixed(2)
                            .toString() + " K";
                } else {
                    returnString = Math.abs(data[0].mtd_vol)
                        .toFixed(2)
                        .toString();
                }
                return isPos ? returnString : "-" + returnString;
            }
            return 0;
        },
        /**
         * Call parent method to change selectedBook
         */
        changeBook(book) {
            this.$emit("changeBook", book);
        },
        rowClicked(data) {
            if (!data.hasChildren) {
                return;
            } else {
                if (this.expanded.includes(data.book)) {
                    this.expanded = this.expanded.filter(
                        (item) => item !== data.book
                    );
                } else {
                    this.expanded = [...this.expanded, data.book];
                    let listOfChildren = [];
                    this.books
                        .find((item) => item.book_name === data.book)
                        .children.map((item) =>
                            listOfChildren.push(item.book_name)
                        );
                    this.parentChildrenRelation[data.book] = listOfChildren;
                }
            }
        },
        onDailySummaryDataDownload() {
            document.getElementById("dailySummaryDownload").click();
        },
        fetchDailySummary() {
            this.loading = true;
            getDailySummary({ date: this.selectedDailySummaryDate })
                .then((res) => {
                    this.snackBarSuccess("Fetch daily summary successfully.");
                    const { data } = res;
                    this.dailySummaryTableData = data;
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.snackBarDanger(
                        "Something went wrong, please try again later."
                    );
                    this.loading = false;
                });
        },
    },
    created() {
        const userFunction = this.getFunction("book");

        if (userFunction.includes("mtdVolumeColumn")) {
            this.UPDATE_ALL_BOOK_HEADER("mtdVol");
        }
        if (userFunction.includes("mtdPnlColumn")) {
            this.UPDATE_ALL_BOOK_HEADER("mtdPnl");
        }
        this.isWatching = true;
        this.unwatchIsLiveProp = this.$watch("briefData", (nv) => {
            nv.map((item) => this.rowClicked(item));
            if (nv) {
                this.unwatchIsLiveProp();
                this.isWatching = false;
            }
        });
    },
    mounted() {
        const permission = JSON.parse(
            localStorage.getItem("permission")
        ).frontPermission;
        if (permission.book.function.includes("dailySummaryIcon")) {
            this.loadDailySummaryIcon = 1;
        }
    },
};
</script>

<style scoped>
div >>> .v-data-table__wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
}
div >>> .all-book-expand-item > .v-data-table__wrapper {
    overflow-y: hidden;
}

div >>> .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
div >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding-left: 6px;
    padding-right: 0px;
}
div >>> .v-dialog--persistent {
    overflow-y: hidden;
}
</style>
