export default {
    mockedPkData: {
        "S": [
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 2,
                "eodPriceAsk": 15826.81,
                "eodPriceBid": 15823.89,
                "eodPriceMap": {
                    "USTEC_1": "15826.81,15823.89,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 373.81,
                "floatingLongProfitEOD": -4.08,
                "floatingProfit": -3293.54,
                "floatingProfitEOD": 74.97,
                "floatingShortProfit": -3667.35,
                "floatingShortProfitEOD": 79.05,
                "force": false,
                "longPositions": 0.6,
                "priceAsk": 15820.06,
                "priceBid": 15817.14,
                "priority": 1,
                "ref": "B|USTEC",
                "shortPositions": -11.7,
                "weightedAvgLongPrice": 15194.13,
                "weightedAvgShortPrice": 15506.6,
                "symbol": "USTEC",
                "net": "-11.10",
                "dailyNet": "74.97"
            },
            {
                "closedLongPositions": 0.02,
                "closedLongProfit": 6.14,
                "closedLongProfitEOD": 2.92,
                "closedProfit": 0,
                "closedProfitEOD": 0.88,
                "closedShortPositions": 0.03,
                "closedShortProfit": -11.59,
                "closedShortProfitEOD": -2.04,
                "digits": 5,
                "eodPriceAsk": 0.59935,
                "eodPriceBid": 0.59913,
                "eodPriceMap": {
                    "NZDUSD-P_1": "0.59934,0.59915,1700179200",
                    "NZDUSD_1": "0.59935,0.59913,1700179200",
                    "NZDUSD-E_1": "0.59927,0.59921,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 3.47,
                "floatingLongProfitEOD": 8.55,
                "floatingProfit": -284.93,
                "floatingProfitEOD": -73.46,
                "floatingShortProfit": -288.4,
                "floatingShortProfitEOD": -82.01,
                "force": false,
                "longPositions": 0.05,
                "priceAsk": 0.60231,
                "priceBid": 0.6021,
                "priority": 1,
                "ref": "B|NZDUSD",
                "shortPositions": -0.39,
                "weightedAvgLongPrice": 0.60132,
                "weightedAvgShortPrice": 0.59471,
                "symbol": "NZDUSD",
                "net": "-0.34",
                "dailyNet": "-72.58"
            },
            {
                "closedLongPositions": 0.11,
                "closedLongProfit": 3.13,
                "closedLongProfitEOD": 23.84,
                "closedProfit": 0.7,
                "closedProfitEOD": 23.84,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.82217,
                "eodPriceBid": 0.82174,
                "eodPriceMap": {
                    "NZDCAD_1": "0.82217,0.82174,1700179200",
                    "NZDCAD-E_1": "0.82209,0.82182,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -5.71,
                "floatingProfitEOD": -5.71,
                "floatingShortProfit": -5.71,
                "floatingShortProfitEOD": -5.71,
                "force": false,
                "longPositions": 0,
                "priceAsk": 0.82524,
                "priceBid": 0.82494,
                "priority": 1,
                "ref": "B|NZDCAD",
                "shortPositions": -0.13,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0.82461,
                "symbol": "NZDCAD",
                "net": "-0.13",
                "dailyNet": "18.13"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.61038,
                "eodPriceBid": 0.61002,
                "eodPriceMap": {
                    "XRPUSD_1": "0.62508,0.62472,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 5.55,
                "floatingLongProfitEOD": 0,
                "floatingProfit": 5.55,
                "floatingProfitEOD": 0,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.42,
                "priceAsk": 0.62508,
                "priceBid": 0.62472,
                "priority": 1,
                "ref": "B|XRPUSD",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0.61812,
                "weightedAvgShortPrice": 0,
                "symbol": "XRPUSD",
                "net": "0.42",
                "dailyNet": "0.00"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0.96,
                "closedProfitEOD": 18.7,
                "closedShortPositions": 0.09,
                "closedShortProfit": 4.01,
                "closedShortProfitEOD": 18.7,
                "digits": 5,
                "eodPriceAsk": 1.82133,
                "eodPriceBid": 1.82089,
                "eodPriceMap": {
                    "EURNZD-P_1": "1.82133,1.82089,1700179200",
                    "EURNZD_1": "1.82134,1.82087,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -111.42,
                "floatingLongProfitEOD": -108.62,
                "floatingProfit": -913.65,
                "floatingProfitEOD": 261.11,
                "floatingShortProfit": -802.23,
                "floatingShortProfitEOD": 369.73,
                "force": false,
                "longPositions": 0.26,
                "priceAsk": 1.81385,
                "priceBid": 1.81349,
                "priority": 1,
                "ref": "B|EURNZD",
                "shortPositions": -0.84,
                "weightedAvgLongPrice": 1.8206,
                "weightedAvgShortPrice": 1.79799,
                "symbol": "EURNZD",
                "net": "-0.58",
                "dailyNet": "279.81"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": -11.94,
                "closedShortPositions": 0.09,
                "closedShortProfit": -17.18,
                "closedShortProfitEOD": -11.94,
                "digits": 5,
                "eodPriceAsk": 0.57725,
                "eodPriceBid": 0.57683,
                "eodPriceMap": {
                    "AUDCHF-E_1": "0.57717,0.57691,1700179200",
                    "AUDCHF_1": "0.57725,0.57683,1700179200",
                    "AUDCHF-P_1": "0.57724,0.57685,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -296.08,
                "floatingLongProfitEOD": 8.95,
                "floatingProfit": -296.08,
                "floatingProfitEOD": 8.95,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.05,
                "priceAsk": 0.57867,
                "priceBid": 0.57841,
                "priority": 1,
                "ref": "B|AUDCHF",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0.63076,
                "weightedAvgShortPrice": -0.5,
                "symbol": "AUDCHF",
                "net": "0.05",
                "dailyNet": "-2.99"
            },
            {
                "closedLongPositions": 0.35,
                "closedLongProfit": 86.25,
                "closedLongProfitEOD": 127.19,
                "closedProfit": -141.75,
                "closedProfitEOD": 410.43,
                "closedShortPositions": 2.83,
                "closedShortProfit": 235.78,
                "closedShortProfitEOD": 283.24,
                "digits": 2,
                "eodPriceAsk": 36576.25,
                "eodPriceBid": 36543.75,
                "eodPriceMap": {
                    "BTCUSD_1": "37019.81,36984.42,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 1902.16,
                "floatingLongProfitEOD": 584.45,
                "floatingProfit": -7424.95,
                "floatingProfitEOD": -545.66,
                "floatingShortProfit": -9327.11,
                "floatingShortProfitEOD": -1130.11,
                "force": false,
                "longPositions": 1.74,
                "priceAsk": 37353.74,
                "priceBid": 37339.74,
                "priority": 1,
                "ref": "B|BTCUSD",
                "shortPositions": -4.33,
                "weightedAvgLongPrice": 36246.53,
                "weightedAvgShortPrice": 35199.67,
                "symbol": "BTCUSD",
                "net": "-2.59",
                "dailyNet": "-135.23"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 2,
                "eodPriceAsk": 7083.46,
                "eodPriceBid": 7074.34,
                "eodPriceMap": {
                    "AUS200_1": "7083.46,7074.34,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -24.61,
                "floatingLongProfitEOD": -0.76,
                "floatingProfit": -24.61,
                "floatingProfitEOD": -0.76,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.1,
                "priceAsk": 7064.71,
                "priceBid": 7062.69,
                "priority": 1,
                "ref": "B|AUS200",
                "shortPositions": 0,
                "weightedAvgLongPrice": 7438.95,
                "weightedAvgShortPrice": 0,
                "symbol": "AUS200",
                "net": "0.10",
                "dailyNet": "-0.76"
            }
        ],
        "B": [
            {
                "closedLongPositions": 0.82,
                "closedLongProfit": 33.74,
                "closedLongProfitEOD": 21.3,
                "closedProfit": 7.4799999999999995,
                "closedProfitEOD": 484.39,
                "closedShortPositions": 3.84,
                "closedShortProfit": 38.22,
                "closedShortProfitEOD": 463.09,
                "digits": 5,
                "eodPriceAsk": 1.09139,
                "eodPriceBid": 1.09123,
                "eodPriceMap": {
                    "EURUSD_1": "1.09139,1.09123,1700179200",
                    "EURUSD-E_1": "1.09132,1.0913,1700179200",
                    "EURUSD-P_1": "1.09139,1.09124,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 125144.66,
                "floatingLongProfitEOD": 21151.61,
                "floatingProfit": -888557.14,
                "floatingProfitEOD": -64.81,
                "floatingShortProfit": -1013701.8,
                "floatingShortProfitEOD": -21216.42,
                "force": false,
                "longPositions": 242.58,
                "priceAsk": 1.09224,
                "priceBid": 1.09211,
                "priority": 1,
                "ref": "B|EURUSD",
                "shortPositions": -248.55,
                "weightedAvgLongPrice": 1.08694,
                "weightedAvgShortPrice": 1.05145,
                "symbol": "EURUSD",
                "net": "-5.97",
                "dailyNet": "419.58"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 1.49695,
                "eodPriceBid": 1.49682,
                "eodPriceMap": {
                    "EURCAD-E_1": "1.49695,1.49682,1700179200",
                    "EURCAD-P_1": "1.49702,1.49676,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -404.39,
                "floatingProfitEOD": 16.52,
                "floatingShortProfit": -404.39,
                "floatingShortProfitEOD": 16.52,
                "force": false,
                "longPositions": 0,
                "priceAsk": 1.49641,
                "priceBid": 1.49634,
                "priority": 1,
                "ref": "B|EURCAD",
                "shortPositions": -0.46,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 1.48436,
                "symbol": "EURCAD",
                "net": "-0.46",
                "dailyNet": "16.52"
            },
            {
                "closedLongPositions": 6.94,
                "closedLongProfit": 948.07,
                "closedLongProfitEOD": -1632.17,
                "closedProfit": -401.31,
                "closedProfitEOD": -2814.36,
                "closedShortPositions": 4.28,
                "closedShortProfit": -1455.55,
                "closedShortProfitEOD": -1182.19,
                "digits": 3,
                "eodPriceAsk": 76.182,
                "eodPriceBid": 76.148,
                "eodPriceMap": {
                    "USOUSD-E_1": "76.175,76.155,1700179200",
                    "USOUSD_1": "76.182,76.148,1700179200",
                    "USOUSD-P_1": "76.181,76.149,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -883612.89,
                "floatingLongProfitEOD": 39189.72,
                "floatingProfit": -869594.44,
                "floatingProfitEOD": 36457.87,
                "floatingShortProfit": 14018.45,
                "floatingShortProfitEOD": -2731.85,
                "force": false,
                "longPositions": 81.88,
                "priceAsk": 76.666,
                "priceBid": 76.628,
                "priority": 1,
                "ref": "B|USOUSD",
                "shortPositions": -6.49,
                "weightedAvgLongPrice": 87.419,
                "weightedAvgShortPrice": 78.822,
                "symbol": "USOUSD",
                "net": "75.39",
                "dailyNet": "33643.51"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 5.253,
                "eodPriceBid": 5.245,
                "eodPriceMap": {
                    "DOTUSD_1": "5.457,5.448,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0.09,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -0.01,
                "floatingProfitEOD": 0,
                "floatingShortProfit": -0.1,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.01,
                "priceAsk": 5.464,
                "priceBid": 5.457,
                "priority": 1,
                "ref": "B|DOTUSD",
                "shortPositions": -0.01,
                "weightedAvgLongPrice": 5.269,
                "weightedAvgShortPrice": 5.267,
                "symbol": "DOTUSD",
                "net": "0.00",
                "dailyNet": "0.00"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": -10.27,
                "closedShortPositions": 0.3,
                "closedShortProfit": -18.29,
                "closedShortProfitEOD": -10.27,
                "digits": 2,
                "eodPriceAsk": 33503.01,
                "eodPriceBid": 33494.99,
                "eodPriceMap": {
                    "JP225_1": "33503.01,33494.99,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": 0,
                "floatingProfitEOD": 0,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0,
                "priceAsk": 33573.01,
                "priceBid": 33564.99,
                "priority": 1,
                "ref": "B|JP225",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0,
                "symbol": "JP225",
                "net": "0.00",
                "dailyNet": "-10.27"
            },
            {
                "closedLongPositions": 0.26,
                "closedLongProfit": 29.22,
                "closedLongProfitEOD": 6.28,
                "closedProfit": 0,
                "closedProfitEOD": 706.28,
                "closedShortPositions": 5,
                "closedShortProfit": 115,
                "closedShortProfitEOD": 700,
                "digits": 5,
                "eodPriceAsk": 1.24641,
                "eodPriceBid": 1.24618,
                "eodPriceMap": {
                    "GBPUSD-E_1": "1.24634,1.24624,1700179200",
                    "GBPUSD_1": "1.24642,1.24616,1700179200",
                    "GBPUSD-P_1": "1.24641,1.24618,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -21587.09,
                "floatingLongProfitEOD": 1313.5,
                "floatingProfit": -45859.69,
                "floatingProfitEOD": -1445.36,
                "floatingShortProfit": -24272.6,
                "floatingShortProfitEOD": -2758.86,
                "force": false,
                "longPositions": 6.71,
                "priceAsk": 1.24839,
                "priceBid": 1.24817,
                "priority": 1,
                "ref": "B|GBPUSD",
                "shortPositions": -14.59,
                "weightedAvgLongPrice": 1.28033,
                "weightedAvgShortPrice": 1.23163,
                "symbol": "GBPUSD",
                "net": "-7.88",
                "dailyNet": "-739.08"
            },
            {
                "closedLongPositions": 0.04,
                "closedLongProfit": 2.78,
                "closedLongProfitEOD": 2.56,
                "closedProfit": 0,
                "closedProfitEOD": 2.56,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 1.70971,
                "eodPriceBid": 1.70932,
                "eodPriceMap": {
                    "GBPCAD-P_1": "1.70971,1.70932,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -1.92,
                "floatingLongProfitEOD": -1.59,
                "floatingProfit": -481.22,
                "floatingProfitEOD": -34.07,
                "floatingShortProfit": -479.3,
                "floatingShortProfitEOD": -32.48,
                "force": false,
                "longPositions": 0.06,
                "priceAsk": 1.71029,
                "priceBid": 1.71009,
                "priority": 1,
                "ref": "B|GBPCAD",
                "shortPositions": -0.92,
                "weightedAvgLongPrice": 1.71052,
                "weightedAvgShortPrice": 1.70315,
                "symbol": "GBPCAD",
                "net": "-0.86",
                "dailyNet": "-31.51"
            },
            {
                "closedLongPositions": 0.01,
                "closedLongProfit": -1.14,
                "closedLongProfitEOD": -2.45,
                "closedProfit": 0.8700000000000001,
                "closedProfitEOD": 11.16,
                "closedShortPositions": 0.06,
                "closedShortProfit": 9.36,
                "closedShortProfitEOD": 13.61,
                "digits": 3,
                "eodPriceAsk": 168.97,
                "eodPriceBid": 168.933,
                "eodPriceMap": {
                    "CHFJPY-E_1": "168.962,168.941,1700179200",
                    "CHFJPY_1": "168.97,168.933,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -997.23,
                "floatingProfitEOD": 226.47,
                "floatingShortProfit": -997.23,
                "floatingShortProfitEOD": 226.47,
                "force": false,
                "longPositions": 0,
                "priceAsk": 168.488,
                "priceBid": 168.456,
                "priority": 1,
                "ref": "B|CHFJPY",
                "shortPositions": -0.7,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 166.365,
                "symbol": "CHFJPY",
                "net": "-0.70",
                "dailyNet": "237.63"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 81.107,
                "eodPriceBid": 81.015,
                "eodPriceMap": {
                    "UKOUSD-P_1": "81.107,81.015,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 5.78,
                "floatingLongProfitEOD": 5.78,
                "floatingProfit": 5.78,
                "floatingProfitEOD": 5.78,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.02,
                "priceAsk": 81.661,
                "priceBid": 81.619,
                "priority": 1,
                "ref": "B|UKOUSD",
                "shortPositions": 0,
                "weightedAvgLongPrice": 81.33,
                "weightedAvgShortPrice": 0,
                "symbol": "UKOUSD",
                "net": "0.02",
                "dailyNet": "5.78"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 2,
                "eodPriceAsk": 77.61,
                "eodPriceBid": 77.58,
                "eodPriceMap": {
                    "BABA_1": "77.61,77.58,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -1.65,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -1.65,
                "floatingProfitEOD": 0,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 5,
                "priceAsk": 77.61,
                "priceBid": 77.58,
                "priority": 1,
                "ref": "B|BABA",
                "shortPositions": 0,
                "weightedAvgLongPrice": 77.91,
                "weightedAvgShortPrice": 0,
                "symbol": "BABA",
                "net": "5.00",
                "dailyNet": "0.00"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": -18.69,
                "closedProfitEOD": -7.8,
                "closedShortPositions": 0.05,
                "closedShortProfit": -18.72,
                "closedShortProfitEOD": -7.8,
                "digits": 5,
                "eodPriceAsk": 0.87579,
                "eodPriceBid": 0.87549,
                "eodPriceMap": {
                    "EURGBP_1": "0.87579,0.87549,1700179200",
                    "EURGBP-P_1": "0.87578,0.87551,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -5.41,
                "floatingLongProfitEOD": -7.87,
                "floatingProfit": -1508.54,
                "floatingProfitEOD": 209.58,
                "floatingShortProfit": -1503.13,
                "floatingShortProfitEOD": 217.45,
                "force": false,
                "longPositions": 0.1,
                "priceAsk": 0.87502,
                "priceBid": 0.87487,
                "priority": 1,
                "ref": "B|EURGBP",
                "shortPositions": -2.28,
                "weightedAvgLongPrice": 0.8753,
                "weightedAvgShortPrice": 0.86973,
                "symbol": "EURGBP",
                "net": "-2.18",
                "dailyNet": "201.78"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 2,
                "eodPriceAsk": 15951.26,
                "eodPriceBid": 15946.34,
                "eodPriceMap": {
                    "DE40_1": "15951.26,15946.34,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -1105.2,
                "floatingProfitEOD": 26.8,
                "floatingShortProfit": -1105.2,
                "floatingShortProfitEOD": 26.8,
                "force": false,
                "longPositions": 0,
                "priceAsk": 15946.36,
                "priceBid": 15942.64,
                "priority": 1,
                "ref": "B|DE40",
                "shortPositions": -5,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 15744.01,
                "symbol": "DE40",
                "net": "-5.00",
                "dailyNet": "26.80"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": -69.05000000000001,
                "closedProfitEOD": 46.2,
                "closedShortPositions": 0.27,
                "closedShortProfit": -43.27,
                "closedShortProfitEOD": 46.2,
                "digits": 3,
                "eodPriceAsk": 163.32,
                "eodPriceBid": 163.289,
                "eodPriceMap": {
                    "EURJPY-P_1": "163.319,163.291,1700179200",
                    "EURJPY-E_1": "163.312,163.297,1700179200",
                    "EURJPY_1": "163.32,163.289,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -332.53,
                "floatingLongProfitEOD": -167.99,
                "floatingProfit": -11979.45,
                "floatingProfitEOD": 1024.32,
                "floatingShortProfit": -11646.92,
                "floatingShortProfitEOD": 1192.31,
                "force": false,
                "longPositions": 0.48,
                "priceAsk": 162.718,
                "priceBid": 162.699,
                "priority": 1,
                "ref": "B|EURJPY",
                "shortPositions": -3,
                "weightedAvgLongPrice": 163.731,
                "weightedAvgShortPrice": 156.944,
                "symbol": "EURJPY",
                "net": "-2.52",
                "dailyNet": "1070.52"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 1.10404,
                "eodPriceBid": 1.10369,
                "eodPriceMap": {
                    "GBPCHF-P_1": "1.10404,1.10369,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -9.23,
                "floatingLongProfitEOD": 0.07,
                "floatingProfit": -9.23,
                "floatingProfitEOD": 0.07,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.02,
                "priceAsk": 1.10395,
                "priceBid": 1.10372,
                "priority": 1,
                "ref": "B|GBPCHF",
                "shortPositions": 0,
                "weightedAvgLongPrice": 1.10779,
                "weightedAvgShortPrice": 0,
                "symbol": "GBPCHF",
                "net": "0.02",
                "dailyNet": "0.07"
            },
            {
                "closedLongPositions": 0.99,
                "closedLongProfit": 7,
                "closedLongProfitEOD": -0.18,
                "closedProfit": 0,
                "closedProfitEOD": -0.18,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 13.653,
                "eodPriceBid": 13.643,
                "eodPriceMap": {
                    "LNKUSD_1": "14.986,14.979,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -2.3,
                "floatingProfitEOD": -2.3,
                "floatingShortProfit": -2.3,
                "floatingShortProfitEOD": -2.3,
                "force": false,
                "longPositions": 0,
                "priceAsk": 15.133,
                "priceBid": 15.122,
                "priority": 1,
                "ref": "B|LNKUSD",
                "shortPositions": -5,
                "weightedAvgLongPrice": -8,
                "weightedAvgShortPrice": 15.087,
                "symbol": "LNKUSD",
                "net": "-5.00",
                "dailyNet": "-2.48"
            },
            {
                "closedLongPositions": 0.02,
                "closedLongProfit": -5.65,
                "closedLongProfitEOD": -3.73,
                "closedProfit": -2.74,
                "closedProfitEOD": -3.73,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 109.105,
                "eodPriceBid": 109.09,
                "eodPriceMap": {
                    "CADJPY-E_1": "109.105,109.09,1700179200",
                    "CADJPY_1": "109.113,109.082,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": 0,
                "floatingProfitEOD": 0,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0,
                "priceAsk": 108.722,
                "priceBid": 108.702,
                "priority": 1,
                "ref": "B|CADJPY",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0,
                "symbol": "CADJPY",
                "net": "0.00",
                "dailyNet": "-3.73"
            },
            {
                "closedLongPositions": 0.1,
                "closedLongProfit": -9.18,
                "closedLongProfitEOD": -0.9,
                "closedProfit": 0,
                "closedProfitEOD": 0.07,
                "closedShortPositions": 0.02,
                "closedShortProfit": 6.51,
                "closedShortProfitEOD": 0.97,
                "digits": 5,
                "eodPriceAsk": 0.88583,
                "eodPriceBid": 0.8856,
                "eodPriceMap": {
                    "USDCHF-P_1": "0.88583,0.8856,1700179200",
                    "USDCHF-E_1": "0.88576,0.88566,1700179200",
                    "USDCHF_1": "0.88584,0.88558,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -160.07,
                "floatingLongProfitEOD": -49.04,
                "floatingProfit": -160.07,
                "floatingProfitEOD": -49.04,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.32,
                "priceAsk": 0.88442,
                "priceBid": 0.88424,
                "priority": 1,
                "ref": "B|USDCHF",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0.88864,
                "weightedAvgShortPrice": 0,
                "symbol": "USDCHF",
                "net": "0.32",
                "dailyNet": "-48.97"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.53089,
                "eodPriceBid": 0.53058,
                "eodPriceMap": {
                    "NZDCHF_1": "0.53089,0.53058,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0.48,
                "floatingLongProfitEOD": 0.48,
                "floatingProfit": 0.48,
                "floatingProfitEOD": 0.48,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.01,
                "priceAsk": 0.53264,
                "priceBid": 0.53239,
                "priority": 1,
                "ref": "B|NZDCHF",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0.53197,
                "weightedAvgShortPrice": 0,
                "symbol": "NZDCHF",
                "net": "0.01",
                "dailyNet": "0.48"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 41.85,
                "closedProfitEOD": 6.36,
                "closedShortPositions": 0.05,
                "closedShortProfit": 9.01,
                "closedShortProfitEOD": 6.36,
                "digits": 5,
                "eodPriceAsk": 2.08004,
                "eodPriceBid": 2.07948,
                "eodPriceMap": {
                    "GBPNZD_1": "2.08005,2.07946,1700179200",
                    "GBPNZD-P_1": "2.08004,2.07948,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -75.05,
                "floatingLongProfitEOD": -16.58,
                "floatingProfit": -75.78,
                "floatingProfitEOD": -8.14,
                "floatingShortProfit": -0.73,
                "floatingShortProfitEOD": 8.44,
                "force": false,
                "longPositions": 0.04,
                "priceAsk": 2.0730399999999998,
                "priceBid": 2.07259,
                "priority": 1,
                "ref": "B|GBPNZD",
                "shortPositions": -0.02,
                "weightedAvgLongPrice": 2.10375,
                "weightedAvgShortPrice": 2.07243,
                "symbol": "GBPNZD",
                "net": "0.02",
                "dailyNet": "-1.78"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 227.256,
                "eodPriceBid": 226.553,
                "eodPriceMap": {
                    "BCHUSD_1": "228.956,228.253,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -0.22,
                "floatingLongProfitEOD": 0.03,
                "floatingProfit": -0.48,
                "floatingProfitEOD": -0.04,
                "floatingShortProfit": -0.26,
                "floatingShortProfitEOD": -0.07,
                "force": false,
                "longPositions": 0.03,
                "priceAsk": 229.806,
                "priceBid": 229.123,
                "priority": 1,
                "ref": "B|BCHUSD",
                "shortPositions": -0.08,
                "weightedAvgLongPrice": 236.386,
                "weightedAvgShortPrice": 226.519,
                "symbol": "BCHUSD",
                "net": "-0.05",
                "dailyNet": "-0.04"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": -8.27,
                "closedShortPositions": 0.04,
                "closedShortProfit": -17.41,
                "closedShortProfitEOD": -8.27,
                "digits": 5,
                "eodPriceAsk": 0.89373,
                "eodPriceBid": 0.89351,
                "eodPriceMap": {
                    "AUDCAD-P_1": "0.89373,0.89351,1700179200",
                    "AUDCAD-E_1": "0.89366,0.89357,1700179200",
                    "AUDCAD_1": "0.89374,0.89349,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -1295.52,
                "floatingProfitEOD": -486.7,
                "floatingShortProfit": -1295.52,
                "floatingShortProfitEOD": -486.7,
                "force": false,
                "longPositions": 0,
                "priceAsk": 0.89652,
                "priceBid": 0.89629,
                "priority": 1,
                "ref": "B|AUDCAD",
                "shortPositions": -2.62,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0.88961,
                "symbol": "AUDCAD",
                "net": "-2.62",
                "dailyNet": "-494.97"
            },
            {
                "closedLongPositions": 0.23,
                "closedLongProfit": 23.05,
                "closedLongProfitEOD": 7.62,
                "closedProfit": 5.08,
                "closedProfitEOD": 7.62,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.6516,
                "eodPriceBid": 0.65142,
                "eodPriceMap": {
                    "AUDUSD_1": "0.65161,0.6514,1700179200",
                    "AUDUSD-E_1": "0.65153,0.65148,1700179200",
                    "AUDUSD-P_1": "0.6516,0.65142,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -441.25,
                "floatingLongProfitEOD": 255.81,
                "floatingProfit": -2543.97,
                "floatingProfitEOD": -315.86,
                "floatingShortProfit": -2102.72,
                "floatingShortProfitEOD": -571.67,
                "force": false,
                "longPositions": 1.01,
                "priceAsk": 0.65436,
                "priceBid": 0.65414,
                "priority": 1,
                "ref": "B|AUDUSD",
                "shortPositions": -2.26,
                "weightedAvgLongPrice": 0.65843,
                "weightedAvgShortPrice": 0.64489,
                "symbol": "AUDUSD",
                "net": "-1.25",
                "dailyNet": "-308.24"
            },
            {
                "closedLongPositions": 0.2,
                "closedLongProfit": 28.31,
                "closedLongProfitEOD": 10.75,
                "closedProfit": 23.77,
                "closedProfitEOD": 72.85,
                "closedShortPositions": 0.23,
                "closedShortProfit": 41.39,
                "closedShortProfitEOD": 62.1,
                "digits": 3,
                "eodPriceAsk": 186.524,
                "eodPriceBid": 186.483,
                "eodPriceMap": {
                    "GBPJPY-E_1": "186.517,186.489,1700179200",
                    "GBPJPY_1": "186.525,186.481,1700179200",
                    "GBPJPY-P_1": "186.524,186.483,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -240.23,
                "floatingLongProfitEOD": -179.83,
                "floatingProfit": -1372.52,
                "floatingProfitEOD": 274.79,
                "floatingShortProfit": -1132.29,
                "floatingShortProfitEOD": 454.62,
                "force": false,
                "longPositions": 0.51,
                "priceAsk": 185.966,
                "priceBid": 185.947,
                "priority": 1,
                "ref": "B|GBPJPY",
                "shortPositions": -1.3,
                "weightedAvgLongPrice": 186.657,
                "weightedAvgShortPrice": 184.693,
                "symbol": "GBPJPY",
                "net": "-0.79",
                "dailyNet": "347.64"
            },
            {
                "closedLongPositions": 0.01,
                "closedLongProfit": 1.81,
                "closedLongProfitEOD": -0.74,
                "closedProfit": 0,
                "closedProfitEOD": 111.55,
                "closedShortPositions": 0.57,
                "closedShortProfit": 34.69,
                "closedShortProfitEOD": 112.29,
                "digits": 5,
                "eodPriceAsk": 1.91323,
                "eodPriceBid": 1.91282,
                "eodPriceMap": {
                    "GBPAUD-E_1": "1.91316,1.91288,1700179200",
                    "GBPAUD_1": "1.91324,1.9128,1700179200",
                    "GBPAUD-P_1": "1.91323,1.91282,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -90.6,
                "floatingLongProfitEOD": -62.48,
                "floatingProfit": -1691.61,
                "floatingProfitEOD": 6.07,
                "floatingShortProfit": -1601.01,
                "floatingShortProfitEOD": 68.55,
                "force": false,
                "longPositions": 0.21,
                "priceAsk": 1.90802,
                "priceBid": 1.9077899999999999,
                "priority": 1,
                "ref": "B|GBPAUD",
                "shortPositions": -0.21,
                "weightedAvgLongPrice": 1.91476,
                "weightedAvgShortPrice": 1.79153,
                "symbol": "GBPAUD",
                "net": "0.00",
                "dailyNet": "117.62"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 117.18,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 89.688,
                "eodPriceBid": 89.654,
                "eodPriceMap": {
                    "NZDJPY_1": "89.688,89.654,1700179200",
                    "NZDJPY-P_1": "89.687,89.656,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -13.74,
                "floatingLongProfitEOD": -13.2,
                "floatingProfit": -29.2,
                "floatingProfitEOD": -13.68,
                "floatingShortProfit": -15.46,
                "floatingShortProfitEOD": -0.48,
                "force": false,
                "longPositions": 0.11,
                "priceAsk": 89.724,
                "priceBid": 89.702,
                "priority": 1,
                "ref": "B|NZDJPY",
                "shortPositions": -0.02,
                "weightedAvgLongPrice": 89.901,
                "weightedAvgShortPrice": 88.572,
                "symbol": "NZDJPY",
                "net": "0.09",
                "dailyNet": "-13.68"
            },
            {
                "closedLongPositions": 0.7,
                "closedLongProfit": 120.75,
                "closedLongProfitEOD": 120.75,
                "closedProfit": -566.75,
                "closedProfitEOD": 1113.1,
                "closedShortPositions": 1.16,
                "closedShortProfit": -632,
                "closedShortProfitEOD": 992.35,
                "digits": 3,
                "eodPriceAsk": 23.849,
                "eodPriceBid": 23.594,
                "eodPriceMap": {
                    "XAGUSD_1": "23.849,23.594,1700179200",
                    "XAGUSD-P_1": "23.851,23.592,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -3377.5,
                "floatingLongProfitEOD": 3117.5,
                "floatingProfit": -26523.05,
                "floatingProfitEOD": 8073,
                "floatingShortProfit": -23145.55,
                "floatingShortProfitEOD": 4955.5,
                "force": false,
                "longPositions": 6.01,
                "priceAsk": 23.713,
                "priceBid": 23.696,
                "priority": 1,
                "ref": "B|XAGUSD",
                "shortPositions": -7.45,
                "weightedAvgLongPrice": 23.808,
                "weightedAvgShortPrice": 23.09,
                "symbol": "XAGUSD",
                "net": "-1.44",
                "dailyNet": "9186.10"
            },
            {
                "closedLongPositions": 15.64,
                "closedLongProfit": 219.35,
                "closedLongProfitEOD": 974.66,
                "closedProfit": -5.390000000000001,
                "closedProfitEOD": 2408.52,
                "closedShortPositions": 21.14,
                "closedShortProfit": 1778.95,
                "closedShortProfitEOD": 1433.86,
                "digits": 2,
                "eodPriceAsk": 1980.63,
                "eodPriceBid": 1980.44,
                "eodPriceMap": {
                    "XAUUSD_1": "1980.69,1980.37,1700179200",
                    "XAUUSD-P_1": "1980.63,1980.44,1700179200",
                    "XAUUSD-E_1": "1980.56,1980.5,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 62479.64,
                "floatingLongProfitEOD": 36136.9,
                "floatingProfit": -892503.35,
                "floatingProfitEOD": -9062.37,
                "floatingShortProfit": -954982.99,
                "floatingShortProfitEOD": -45199.27,
                "force": false,
                "longPositions": 59.37,
                "priceAsk": 1983.78,
                "priceBid": 1983.45,
                "priority": 1,
                "ref": "B|XAUUSD",
                "shortPositions": -96.88,
                "weightedAvgLongPrice": 1978.32,
                "weightedAvgShortPrice": 1926.14,
                "symbol": "XAUUSD",
                "net": "-37.51",
                "dailyNet": "-6653.85"
            },
            {
                "closedLongPositions": 0.09,
                "closedLongProfit": 8.74,
                "closedLongProfitEOD": 6.48,
                "closedProfit": 28.18,
                "closedProfitEOD": 45.88,
                "closedShortPositions": 0.12,
                "closedShortProfit": 7.44,
                "closedShortProfitEOD": 39.4,
                "digits": 5,
                "eodPriceAsk": 1.67524,
                "eodPriceBid": 1.675,
                "eodPriceMap": {
                    "EURAUD-P_1": "1.67524,1.675,1700179200",
                    "EURAUD_1": "1.67525,1.67498,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -30.83,
                "floatingLongProfitEOD": -29.7,
                "floatingProfit": -62.82,
                "floatingProfitEOD": 55.65,
                "floatingShortProfit": -31.99,
                "floatingShortProfitEOD": 85.35,
                "force": false,
                "longPositions": 0.07,
                "priceAsk": 1.6694900000000001,
                "priceBid": 1.6693099999999998,
                "priority": 1,
                "ref": "B|EURAUD",
                "shortPositions": -0.28,
                "weightedAvgLongPrice": 1.67638,
                "weightedAvgShortPrice": 1.66809,
                "symbol": "EURAUD",
                "net": "-0.21",
                "dailyNet": "101.53"
            },
            {
                "closedLongPositions": 0.11,
                "closedLongProfit": -15.76,
                "closedLongProfitEOD": -11.97,
                "closedProfit": 0,
                "closedProfitEOD": 7.15,
                "closedShortPositions": 0.2,
                "closedShortProfit": 16.33,
                "closedShortProfitEOD": 19.12,
                "digits": 5,
                "eodPriceAsk": 1.37181,
                "eodPriceBid": 1.3715,
                "eodPriceMap": {
                    "USDCAD-P_1": "1.37181,1.3715,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -251.15,
                "floatingLongProfitEOD": -84.69,
                "floatingProfit": -679.19,
                "floatingProfitEOD": -44.52,
                "floatingShortProfit": -428.04,
                "floatingShortProfitEOD": 40.17,
                "force": false,
                "longPositions": 0.84,
                "priceAsk": 1.37033,
                "priceBid": 1.37012,
                "priority": 1,
                "ref": "B|USDCAD",
                "shortPositions": -0.38,
                "weightedAvgLongPrice": 1.37421,
                "weightedAvgShortPrice": 1.35489,
                "symbol": "USDCAD",
                "net": "0.46",
                "dailyNet": "-37.37"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": -68.34,
                "closedShortPositions": 2.01,
                "closedShortProfit": 3071.28,
                "closedShortProfitEOD": -68.34,
                "digits": 3,
                "eodPriceAsk": 76.188,
                "eodPriceBid": 76.092,
                "eodPriceMap": {
                    "WTI02_1": "76.188,76.092,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -3838.8,
                "floatingLongProfitEOD": 3051.2,
                "floatingProfit": -31348.8,
                "floatingProfitEOD": -30766.8,
                "floatingShortProfit": -27510,
                "floatingShortProfitEOD": -33818,
                "force": false,
                "longPositions": 6.2,
                "priceAsk": 76.645,
                "priceBid": 76.596,
                "priority": 1,
                "ref": "B|WTI02",
                "shortPositions": -74,
                "weightedAvgLongPrice": 77.215,
                "weightedAvgShortPrice": 76.273,
                "symbol": "WTI02",
                "net": "-67.80",
                "dailyNet": "-30835.14"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": -52.53,
                "closedShortPositions": 8,
                "closedShortProfit": 28.94,
                "closedShortProfitEOD": -52.53,
                "digits": 2,
                "eodPriceAsk": 17657.89,
                "eodPriceBid": 17645.47,
                "eodPriceMap": {
                    "HK50_1": "17657.89,17645.47,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -7213.71,
                "floatingLongProfitEOD": 95,
                "floatingProfit": -7128.67,
                "floatingProfitEOD": 89.94,
                "floatingShortProfit": 85.04,
                "floatingShortProfitEOD": -5.06,
                "force": false,
                "longPositions": 95,
                "priceAsk": 17660.94,
                "priceBid": 17655.52,
                "priority": 1,
                "ref": "B|HK50",
                "shortPositions": -2,
                "weightedAvgLongPrice": 18247.3,
                "weightedAvgShortPrice": 17992.31,
                "symbol": "HK50",
                "net": "93.00",
                "dailyNet": "37.41"
            }
        ],
        "B2": [
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 2,
                "eodPriceAsk": 77.61,
                "eodPriceBid": 77.58,
                "eodPriceMap": {
                    "BABA_1": "77.61,77.58,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -1.65,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -1.65,
                "floatingProfitEOD": 0,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 5,
                "priceAsk": 77.61,
                "priceBid": 77.58,
                "priority": 1,
                "ref": "B|BABA",
                "shortPositions": 0,
                "weightedAvgLongPrice": 77.91,
                "weightedAvgShortPrice": 0,
                "symbol": "BABA",
                "net": "5.00",
                "dailyNet": "0.00"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": -18.69,
                "closedProfitEOD": -7.8,
                "closedShortPositions": 0.05,
                "closedShortProfit": -18.72,
                "closedShortProfitEOD": -7.8,
                "digits": 5,
                "eodPriceAsk": 0.87579,
                "eodPriceBid": 0.87549,
                "eodPriceMap": {
                    "EURGBP_1": "0.87579,0.87549,1700179200",
                    "EURGBP-P_1": "0.87578,0.87551,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -5.41,
                "floatingLongProfitEOD": -7.87,
                "floatingProfit": -1508.54,
                "floatingProfitEOD": 209.58,
                "floatingShortProfit": -1503.13,
                "floatingShortProfitEOD": 217.45,
                "force": false,
                "longPositions": 0.1,
                "priceAsk": 0.87502,
                "priceBid": 0.87487,
                "priority": 1,
                "ref": "B|EURGBP",
                "shortPositions": -2.28,
                "weightedAvgLongPrice": 0.8753,
                "weightedAvgShortPrice": 0.86973,
                "symbol": "EURGBP",
                "net": "-2.18",
                "dailyNet": "201.78"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 2,
                "eodPriceAsk": 15951.26,
                "eodPriceBid": 15946.34,
                "eodPriceMap": {
                    "DE40_1": "15951.26,15946.34,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -1105.2,
                "floatingProfitEOD": 26.8,
                "floatingShortProfit": -1105.2,
                "floatingShortProfitEOD": 26.8,
                "force": false,
                "longPositions": 0,
                "priceAsk": 15946.36,
                "priceBid": 15942.64,
                "priority": 1,
                "ref": "B|DE40",
                "shortPositions": -5,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 15744.01,
                "symbol": "DE40",
                "net": "-5.00",
                "dailyNet": "26.80"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": -69.05000000000001,
                "closedProfitEOD": 46.2,
                "closedShortPositions": 0.27,
                "closedShortProfit": -43.27,
                "closedShortProfitEOD": 46.2,
                "digits": 3,
                "eodPriceAsk": 163.32,
                "eodPriceBid": 163.289,
                "eodPriceMap": {
                    "EURJPY-P_1": "163.319,163.291,1700179200",
                    "EURJPY-E_1": "163.312,163.297,1700179200",
                    "EURJPY_1": "163.32,163.289,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -332.53,
                "floatingLongProfitEOD": -167.99,
                "floatingProfit": -11979.45,
                "floatingProfitEOD": 1024.32,
                "floatingShortProfit": -11646.92,
                "floatingShortProfitEOD": 1192.31,
                "force": false,
                "longPositions": 0.48,
                "priceAsk": 162.718,
                "priceBid": 162.699,
                "priority": 1,
                "ref": "B|EURJPY",
                "shortPositions": -3,
                "weightedAvgLongPrice": 163.731,
                "weightedAvgShortPrice": 156.944,
                "symbol": "EURJPY",
                "net": "-2.52",
                "dailyNet": "1070.52"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 1.10404,
                "eodPriceBid": 1.10369,
                "eodPriceMap": {
                    "GBPCHF-P_1": "1.10404,1.10369,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -9.23,
                "floatingLongProfitEOD": 0.07,
                "floatingProfit": -9.23,
                "floatingProfitEOD": 0.07,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.02,
                "priceAsk": 1.10395,
                "priceBid": 1.10372,
                "priority": 1,
                "ref": "B|GBPCHF",
                "shortPositions": 0,
                "weightedAvgLongPrice": 1.10779,
                "weightedAvgShortPrice": 0,
                "symbol": "GBPCHF",
                "net": "0.02",
                "dailyNet": "0.07"
            },
            {
                "closedLongPositions": 0.99,
                "closedLongProfit": 7,
                "closedLongProfitEOD": -0.18,
                "closedProfit": 0,
                "closedProfitEOD": -0.18,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 13.653,
                "eodPriceBid": 13.643,
                "eodPriceMap": {
                    "LNKUSD_1": "14.986,14.979,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -2.3,
                "floatingProfitEOD": -2.3,
                "floatingShortProfit": -2.3,
                "floatingShortProfitEOD": -2.3,
                "force": false,
                "longPositions": 0,
                "priceAsk": 15.133,
                "priceBid": 15.122,
                "priority": 1,
                "ref": "B|LNKUSD",
                "shortPositions": -5,
                "weightedAvgLongPrice": -8,
                "weightedAvgShortPrice": 15.087,
                "symbol": "LNKUSD",
                "net": "-5.00",
                "dailyNet": "-2.48"
            },
            {
                "closedLongPositions": 0.02,
                "closedLongProfit": -5.65,
                "closedLongProfitEOD": -3.73,
                "closedProfit": -2.74,
                "closedProfitEOD": -3.73,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 109.105,
                "eodPriceBid": 109.09,
                "eodPriceMap": {
                    "CADJPY-E_1": "109.105,109.09,1700179200",
                    "CADJPY_1": "109.113,109.082,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": 0,
                "floatingProfitEOD": 0,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0,
                "priceAsk": 108.722,
                "priceBid": 108.702,
                "priority": 1,
                "ref": "B|CADJPY",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0,
                "symbol": "CADJPY",
                "net": "0.00",
                "dailyNet": "-3.73"
            },
            {
                "closedLongPositions": 0.1,
                "closedLongProfit": -9.18,
                "closedLongProfitEOD": -0.9,
                "closedProfit": 0,
                "closedProfitEOD": 0.07,
                "closedShortPositions": 0.02,
                "closedShortProfit": 6.51,
                "closedShortProfitEOD": 0.97,
                "digits": 5,
                "eodPriceAsk": 0.88583,
                "eodPriceBid": 0.8856,
                "eodPriceMap": {
                    "USDCHF-P_1": "0.88583,0.8856,1700179200",
                    "USDCHF-E_1": "0.88576,0.88566,1700179200",
                    "USDCHF_1": "0.88584,0.88558,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -160.07,
                "floatingLongProfitEOD": -49.04,
                "floatingProfit": -160.07,
                "floatingProfitEOD": -49.04,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.32,
                "priceAsk": 0.88442,
                "priceBid": 0.88424,
                "priority": 1,
                "ref": "B|USDCHF",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0.88864,
                "weightedAvgShortPrice": 0,
                "symbol": "USDCHF",
                "net": "0.32",
                "dailyNet": "-48.97"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.53089,
                "eodPriceBid": 0.53058,
                "eodPriceMap": {
                    "NZDCHF_1": "0.53089,0.53058,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0.48,
                "floatingLongProfitEOD": 0.48,
                "floatingProfit": 0.48,
                "floatingProfitEOD": 0.48,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.01,
                "priceAsk": 0.53264,
                "priceBid": 0.53239,
                "priority": 1,
                "ref": "B|NZDCHF",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0.53197,
                "weightedAvgShortPrice": 0,
                "symbol": "NZDCHF",
                "net": "0.01",
                "dailyNet": "0.48"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 41.85,
                "closedProfitEOD": 6.36,
                "closedShortPositions": 0.05,
                "closedShortProfit": 9.01,
                "closedShortProfitEOD": 6.36,
                "digits": 5,
                "eodPriceAsk": 2.08004,
                "eodPriceBid": 2.07948,
                "eodPriceMap": {
                    "GBPNZD_1": "2.08005,2.07946,1700179200",
                    "GBPNZD-P_1": "2.08004,2.07948,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -75.05,
                "floatingLongProfitEOD": -16.58,
                "floatingProfit": -75.78,
                "floatingProfitEOD": -8.14,
                "floatingShortProfit": -0.73,
                "floatingShortProfitEOD": 8.44,
                "force": false,
                "longPositions": 0.04,
                "priceAsk": 2.0730399999999998,
                "priceBid": 2.07259,
                "priority": 1,
                "ref": "B|GBPNZD",
                "shortPositions": -0.02,
                "weightedAvgLongPrice": 2.10375,
                "weightedAvgShortPrice": 2.07243,
                "symbol": "GBPNZD",
                "net": "0.02",
                "dailyNet": "-1.78"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 227.256,
                "eodPriceBid": 226.553,
                "eodPriceMap": {
                    "BCHUSD_1": "228.956,228.253,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -0.22,
                "floatingLongProfitEOD": 0.03,
                "floatingProfit": -0.48,
                "floatingProfitEOD": -0.04,
                "floatingShortProfit": -0.26,
                "floatingShortProfitEOD": -0.07,
                "force": false,
                "longPositions": 0.03,
                "priceAsk": 229.806,
                "priceBid": 229.123,
                "priority": 1,
                "ref": "B|BCHUSD",
                "shortPositions": -0.08,
                "weightedAvgLongPrice": 236.386,
                "weightedAvgShortPrice": 226.519,
                "symbol": "BCHUSD",
                "net": "-0.05",
                "dailyNet": "-0.04"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": -8.27,
                "closedShortPositions": 0.04,
                "closedShortProfit": -17.41,
                "closedShortProfitEOD": -8.27,
                "digits": 5,
                "eodPriceAsk": 0.89373,
                "eodPriceBid": 0.89351,
                "eodPriceMap": {
                    "AUDCAD-P_1": "0.89373,0.89351,1700179200",
                    "AUDCAD-E_1": "0.89366,0.89357,1700179200",
                    "AUDCAD_1": "0.89374,0.89349,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -1295.52,
                "floatingProfitEOD": -486.7,
                "floatingShortProfit": -1295.52,
                "floatingShortProfitEOD": -486.7,
                "force": false,
                "longPositions": 0,
                "priceAsk": 0.89652,
                "priceBid": 0.89629,
                "priority": 1,
                "ref": "B|AUDCAD",
                "shortPositions": -2.62,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0.88961,
                "symbol": "AUDCAD",
                "net": "-2.62",
                "dailyNet": "-494.97"
            },
            {
                "closedLongPositions": 0.23,
                "closedLongProfit": 23.05,
                "closedLongProfitEOD": 7.62,
                "closedProfit": 5.08,
                "closedProfitEOD": 7.62,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.6516,
                "eodPriceBid": 0.65142,
                "eodPriceMap": {
                    "AUDUSD_1": "0.65161,0.6514,1700179200",
                    "AUDUSD-E_1": "0.65153,0.65148,1700179200",
                    "AUDUSD-P_1": "0.6516,0.65142,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -441.25,
                "floatingLongProfitEOD": 255.81,
                "floatingProfit": -2543.97,
                "floatingProfitEOD": -315.86,
                "floatingShortProfit": -2102.72,
                "floatingShortProfitEOD": -571.67,
                "force": false,
                "longPositions": 1.01,
                "priceAsk": 0.65436,
                "priceBid": 0.65414,
                "priority": 1,
                "ref": "B|AUDUSD",
                "shortPositions": -2.26,
                "weightedAvgLongPrice": 0.65843,
                "weightedAvgShortPrice": 0.64489,
                "symbol": "AUDUSD",
                "net": "-1.25",
                "dailyNet": "-308.24"
            },
            {
                "closedLongPositions": 0.2,
                "closedLongProfit": 28.31,
                "closedLongProfitEOD": 10.75,
                "closedProfit": 23.77,
                "closedProfitEOD": 72.85,
                "closedShortPositions": 0.23,
                "closedShortProfit": 41.39,
                "closedShortProfitEOD": 62.1,
                "digits": 3,
                "eodPriceAsk": 186.524,
                "eodPriceBid": 186.483,
                "eodPriceMap": {
                    "GBPJPY-E_1": "186.517,186.489,1700179200",
                    "GBPJPY_1": "186.525,186.481,1700179200",
                    "GBPJPY-P_1": "186.524,186.483,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -240.23,
                "floatingLongProfitEOD": -179.83,
                "floatingProfit": -1372.52,
                "floatingProfitEOD": 274.79,
                "floatingShortProfit": -1132.29,
                "floatingShortProfitEOD": 454.62,
                "force": false,
                "longPositions": 0.51,
                "priceAsk": 185.966,
                "priceBid": 185.947,
                "priority": 1,
                "ref": "B|GBPJPY",
                "shortPositions": -1.3,
                "weightedAvgLongPrice": 186.657,
                "weightedAvgShortPrice": 184.693,
                "symbol": "GBPJPY",
                "net": "-0.79",
                "dailyNet": "347.64"
            },
            {
                "closedLongPositions": 0.01,
                "closedLongProfit": 1.81,
                "closedLongProfitEOD": -0.74,
                "closedProfit": 0,
                "closedProfitEOD": 111.55,
                "closedShortPositions": 0.57,
                "closedShortProfit": 34.69,
                "closedShortProfitEOD": 112.29,
                "digits": 5,
                "eodPriceAsk": 1.91323,
                "eodPriceBid": 1.91282,
                "eodPriceMap": {
                    "GBPAUD-E_1": "1.91316,1.91288,1700179200",
                    "GBPAUD_1": "1.91324,1.9128,1700179200",
                    "GBPAUD-P_1": "1.91323,1.91282,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -90.6,
                "floatingLongProfitEOD": -62.48,
                "floatingProfit": -1691.61,
                "floatingProfitEOD": 6.07,
                "floatingShortProfit": -1601.01,
                "floatingShortProfitEOD": 68.55,
                "force": false,
                "longPositions": 0.21,
                "priceAsk": 1.90802,
                "priceBid": 1.9077899999999999,
                "priority": 1,
                "ref": "B|GBPAUD",
                "shortPositions": -0.21,
                "weightedAvgLongPrice": 1.91476,
                "weightedAvgShortPrice": 1.79153,
                "symbol": "GBPAUD",
                "net": "0.00",
                "dailyNet": "117.62"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 117.18,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 3,
                "eodPriceAsk": 89.688,
                "eodPriceBid": 89.654,
                "eodPriceMap": {
                    "NZDJPY_1": "89.688,89.654,1700179200",
                    "NZDJPY-P_1": "89.687,89.656,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -13.74,
                "floatingLongProfitEOD": -13.2,
                "floatingProfit": -29.2,
                "floatingProfitEOD": -13.68,
                "floatingShortProfit": -15.46,
                "floatingShortProfitEOD": -0.48,
                "force": false,
                "longPositions": 0.11,
                "priceAsk": 89.724,
                "priceBid": 89.702,
                "priority": 1,
                "ref": "B|NZDJPY",
                "shortPositions": -0.02,
                "weightedAvgLongPrice": 89.901,
                "weightedAvgShortPrice": 88.572,
                "symbol": "NZDJPY",
                "net": "0.09",
                "dailyNet": "-13.68"
            },
            {
                "closedLongPositions": 0.7,
                "closedLongProfit": 120.75,
                "closedLongProfitEOD": 120.75,
                "closedProfit": -566.75,
                "closedProfitEOD": 1113.1,
                "closedShortPositions": 1.16,
                "closedShortProfit": -632,
                "closedShortProfitEOD": 992.35,
                "digits": 3,
                "eodPriceAsk": 23.849,
                "eodPriceBid": 23.594,
                "eodPriceMap": {
                    "XAGUSD_1": "23.849,23.594,1700179200",
                    "XAGUSD-P_1": "23.851,23.592,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -3377.5,
                "floatingLongProfitEOD": 3117.5,
                "floatingProfit": -26523.05,
                "floatingProfitEOD": 8073,
                "floatingShortProfit": -23145.55,
                "floatingShortProfitEOD": 4955.5,
                "force": false,
                "longPositions": 6.01,
                "priceAsk": 23.713,
                "priceBid": 23.696,
                "priority": 1,
                "ref": "B|XAGUSD",
                "shortPositions": -7.45,
                "weightedAvgLongPrice": 23.808,
                "weightedAvgShortPrice": 23.09,
                "symbol": "XAGUSD",
                "net": "-1.44",
                "dailyNet": "9186.10"
            },
            {
                "closedLongPositions": 15.64,
                "closedLongProfit": 219.35,
                "closedLongProfitEOD": 974.66,
                "closedProfit": -5.390000000000001,
                "closedProfitEOD": 2408.52,
                "closedShortPositions": 21.14,
                "closedShortProfit": 1778.95,
                "closedShortProfitEOD": 1433.86,
                "digits": 2,
                "eodPriceAsk": 1980.63,
                "eodPriceBid": 1980.44,
                "eodPriceMap": {
                    "XAUUSD_1": "1980.69,1980.37,1700179200",
                    "XAUUSD-P_1": "1980.63,1980.44,1700179200",
                    "XAUUSD-E_1": "1980.56,1980.5,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 62479.64,
                "floatingLongProfitEOD": 36136.9,
                "floatingProfit": -892503.35,
                "floatingProfitEOD": -9062.37,
                "floatingShortProfit": -954982.99,
                "floatingShortProfitEOD": -45199.27,
                "force": false,
                "longPositions": 59.37,
                "priceAsk": 1983.78,
                "priceBid": 1983.45,
                "priority": 1,
                "ref": "B|XAUUSD",
                "shortPositions": -96.88,
                "weightedAvgLongPrice": 1978.32,
                "weightedAvgShortPrice": 1926.14,
                "symbol": "XAUUSD",
                "net": "-37.51",
                "dailyNet": "-6653.85"
            },
            {
                "closedLongPositions": 0.09,
                "closedLongProfit": 8.74,
                "closedLongProfitEOD": 6.48,
                "closedProfit": 28.18,
                "closedProfitEOD": 45.88,
                "closedShortPositions": 0.12,
                "closedShortProfit": 7.44,
                "closedShortProfitEOD": 39.4,
                "digits": 5,
                "eodPriceAsk": 1.67524,
                "eodPriceBid": 1.675,
                "eodPriceMap": {
                    "EURAUD-P_1": "1.67524,1.675,1700179200",
                    "EURAUD_1": "1.67525,1.67498,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -30.83,
                "floatingLongProfitEOD": -29.7,
                "floatingProfit": -62.82,
                "floatingProfitEOD": 55.65,
                "floatingShortProfit": -31.99,
                "floatingShortProfitEOD": 85.35,
                "force": false,
                "longPositions": 0.07,
                "priceAsk": 1.6694900000000001,
                "priceBid": 1.6693099999999998,
                "priority": 1,
                "ref": "B|EURAUD",
                "shortPositions": -0.28,
                "weightedAvgLongPrice": 1.67638,
                "weightedAvgShortPrice": 1.66809,
                "symbol": "EURAUD",
                "net": "-0.21",
                "dailyNet": "101.53"
            },
            {
                "closedLongPositions": 0.11,
                "closedLongProfit": -15.76,
                "closedLongProfitEOD": -11.97,
                "closedProfit": 0,
                "closedProfitEOD": 7.15,
                "closedShortPositions": 0.2,
                "closedShortProfit": 16.33,
                "closedShortProfitEOD": 19.12,
                "digits": 5,
                "eodPriceAsk": 1.37181,
                "eodPriceBid": 1.3715,
                "eodPriceMap": {
                    "USDCAD-P_1": "1.37181,1.3715,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -251.15,
                "floatingLongProfitEOD": -84.69,
                "floatingProfit": -679.19,
                "floatingProfitEOD": -44.52,
                "floatingShortProfit": -428.04,
                "floatingShortProfitEOD": 40.17,
                "force": false,
                "longPositions": 0.84,
                "priceAsk": 1.37033,
                "priceBid": 1.37012,
                "priority": 1,
                "ref": "B|USDCAD",
                "shortPositions": -0.38,
                "weightedAvgLongPrice": 1.37421,
                "weightedAvgShortPrice": 1.35489,
                "symbol": "USDCAD",
                "net": "0.46",
                "dailyNet": "-37.37"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": -68.34,
                "closedShortPositions": 2.01,
                "closedShortProfit": 3071.28,
                "closedShortProfitEOD": -68.34,
                "digits": 3,
                "eodPriceAsk": 76.188,
                "eodPriceBid": 76.092,
                "eodPriceMap": {
                    "WTI02_1": "76.188,76.092,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -3838.8,
                "floatingLongProfitEOD": 3051.2,
                "floatingProfit": -31348.8,
                "floatingProfitEOD": -30766.8,
                "floatingShortProfit": -27510,
                "floatingShortProfitEOD": -33818,
                "force": false,
                "longPositions": 6.2,
                "priceAsk": 76.645,
                "priceBid": 76.596,
                "priority": 1,
                "ref": "B|WTI02",
                "shortPositions": -74,
                "weightedAvgLongPrice": 77.215,
                "weightedAvgShortPrice": 76.273,
                "symbol": "WTI02",
                "net": "-67.80",
                "dailyNet": "-30835.14"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": -52.53,
                "closedShortPositions": 8,
                "closedShortProfit": 28.94,
                "closedShortProfitEOD": -52.53,
                "digits": 2,
                "eodPriceAsk": 17657.89,
                "eodPriceBid": 17645.47,
                "eodPriceMap": {
                    "HK50_1": "17657.89,17645.47,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -7213.71,
                "floatingLongProfitEOD": 95,
                "floatingProfit": -7128.67,
                "floatingProfitEOD": 89.94,
                "floatingShortProfit": 85.04,
                "floatingShortProfitEOD": -5.06,
                "force": false,
                "longPositions": 95,
                "priceAsk": 17660.94,
                "priceBid": 17655.52,
                "priority": 1,
                "ref": "B|HK50",
                "shortPositions": -2,
                "weightedAvgLongPrice": 18247.3,
                "weightedAvgShortPrice": 17992.31,
                "symbol": "HK50",
                "net": "93.00",
                "dailyNet": "37.41"
            }
        ],
        "Test": [
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.96667,
                "eodPriceBid": 0.96648,
                "eodPriceMap": {
                    "EURCHF_1": "0.96675,0.9664,1700179200",
                    "EURCHF-E_1": "0.96667,0.96648,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": 2.73,
                "floatingProfitEOD": 5.64,
                "floatingShortProfit": 2.73,
                "floatingShortProfitEOD": 5.64,
                "force": false,
                "longPositions": 0,
                "priceAsk": 0.96584,
                "priceBid": 0.96582,
                "priority": 1,
                "ref": "B|EURCHF",
                "shortPositions": -0.06,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0.96625,
                "symbol": "EURCHF",
                "net": "-0.06",
                "dailyNet": "5.64"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": -1.29,
                "closedProfitEOD": -1.29,
                "closedShortPositions": 0.05,
                "closedShortProfit": -1.29,
                "closedShortProfitEOD": -1.29,
                "digits": 2,
                "eodPriceAsk": 1985,
                "eodPriceBid": 1982.48,
                "eodPriceMap": {
                    "ETHUSD_1": "1985.0,1982.48,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": 0,
                "floatingProfitEOD": 0,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0,
                "priceAsk": 2005.26,
                "priceBid": 2003.09,
                "priority": 1,
                "ref": "B|ETHUSD",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0,
                "symbol": "ETHUSD",
                "net": "0.00",
                "dailyNet": "-1.29"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.08052,
                "eodPriceBid": 0.08038,
                "eodPriceMap": {
                    "DOGUSD_1": "0.07972,0.07958,1700352000"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": 0,
                "floatingProfitEOD": 0,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0,
                "priceAsk": 0.08042,
                "priceBid": 0.08038,
                "priority": 1,
                "ref": "B|DOGUSD",
                "shortPositions": -0.01,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 0.08476,
                "symbol": "DOGUSD",
                "net": "-0.01",
                "dailyNet": "0.00"
            },
            {
                "closedLongPositions": 0.39,
                "closedLongProfit": 39.25,
                "closedLongProfitEOD": 55.6,
                "closedProfit": 7.049999999999999,
                "closedProfitEOD": 133.98,
                "closedShortPositions": 0.85,
                "closedShortProfit": 82.47,
                "closedShortProfitEOD": 78.38,
                "digits": 3,
                "eodPriceAsk": 149.654,
                "eodPriceBid": 149.635,
                "eodPriceMap": {
                    "USDJPY_1": "149.655,149.633,1700179200",
                    "USDJPY-E_1": "149.647,149.641,1700179200",
                    "USDJPY-P_1": "149.654,149.635,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -1757.04,
                "floatingLongProfitEOD": -913.63,
                "floatingProfit": -5501.03,
                "floatingProfitEOD": 414.17,
                "floatingShortProfit": -3743.99,
                "floatingShortProfitEOD": 1327.8,
                "force": false,
                "longPositions": 2.26,
                "priceAsk": 148.982,
                "priceBid": 148.963,
                "priority": 1,
                "ref": "B|USDJPY",
                "shortPositions": -3.07,
                "weightedAvgLongPrice": 150.123,
                "weightedAvgShortPrice": 147.165,
                "symbol": "USDJPY",
                "net": "-0.81",
                "dailyNet": "548.15"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 3.38,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 1.08737,
                "eodPriceBid": 1.08699,
                "eodPriceMap": {
                    "AUDNZD-P_1": "1.08737,1.08699,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -3.72,
                "floatingLongProfitEOD": -0.32,
                "floatingProfit": -3.72,
                "floatingProfitEOD": -0.32,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.05,
                "priceAsk": 1.0865,
                "priceBid": 1.08629,
                "priority": 1,
                "ref": "B|AUDNZD",
                "shortPositions": 0,
                "weightedAvgLongPrice": 1.08752,
                "weightedAvgShortPrice": 0,
                "symbol": "AUDNZD",
                "net": "0.05",
                "dailyNet": "-0.32"
            },
            {
                "closedLongPositions": 0.1,
                "closedLongProfit": -65.5,
                "closedLongProfitEOD": -31.06,
                "closedProfit": -65.36,
                "closedProfitEOD": -31.06,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 5,
                "eodPriceAsk": 0.64582,
                "eodPriceBid": 0.64565,
                "eodPriceMap": {
                    "CADCHF-E_1": "0.64582,0.64565,1700179200",
                    "CADCHF_1": "0.6459,0.64557,1700179200",
                    "CADCHF-P_1": "0.64589,0.64559,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": -2246.72,
                "floatingLongProfitEOD": -8.59,
                "floatingProfit": -2246.72,
                "floatingProfitEOD": -8.59,
                "floatingShortProfit": 0,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0.28,
                "priceAsk": 0.64545,
                "priceBid": 0.64538,
                "priority": 1,
                "ref": "B|CADCHF",
                "shortPositions": 0,
                "weightedAvgLongPrice": 0.71631,
                "weightedAvgShortPrice": 0,
                "symbol": "CADCHF",
                "net": "0.28",
                "dailyNet": "-39.65"
            },
            {
                "closedLongPositions": 0,
                "closedLongProfit": 0,
                "closedLongProfitEOD": 0,
                "closedProfit": 0,
                "closedProfitEOD": 0,
                "closedShortPositions": 0,
                "closedShortProfit": 0,
                "closedShortProfitEOD": 0,
                "digits": 2,
                "eodPriceAsk": 234.4,
                "eodPriceBid": 234.32,
                "eodPriceMap": {
                    "TSLA_1": "234.4,234.32,1700179200"
                },
                "externalMsgType": "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                "floatingLongProfit": 0,
                "floatingLongProfitEOD": 0,
                "floatingProfit": -171.1,
                "floatingProfitEOD": 0,
                "floatingShortProfit": -171.1,
                "floatingShortProfitEOD": 0,
                "force": false,
                "longPositions": 0,
                "priceAsk": 234.4,
                "priceBid": 234.32,
                "priority": 1,
                "ref": "B|TSLA",
                "shortPositions": -5,
                "weightedAvgLongPrice": 0,
                "weightedAvgShortPrice": 200.18,
                "symbol": "TSLA",
                "net": "-5.00",
                "dailyNet": "0.00"
            }
        ]
    },

    mockedSymbolDetail: {
        USTEC: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -130.74,
                floatingProfitEOD: 2.04,
                floatingShortProfit: -130.74,
                floatingShortProfitEOD: 2.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809662",
                longPositions: 0,
                netPositions: -0.2,
                shortPositions: 0.2,
                symbol: "USTEC",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15162.84,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2791.64,
                floatingProfitEOD: 61.5,
                floatingShortProfit: -2791.64,
                floatingShortProfitEOD: 61.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810148",
                longPositions: 0,
                netPositions: -6,
                shortPositions: 6,
                symbol: "USTEC",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15351.286666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -704.02,
                floatingProfitEOD: 56.38,
                floatingShortProfit: -704.02,
                floatingShortProfitEOD: 56.38,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802300",
                longPositions: 0,
                netPositions: -5.5,
                shortPositions: 5.5,
                symbol: "USTEC",
                user_trade_number: 19,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15688.548181818185,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 371.71,
                floatingLongProfitEOD: -6.12,
                floatingProfit: 371.71,
                floatingProfitEOD: -6.12,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807267",
                longPositions: 0.6,
                netPositions: 0.6,
                shortPositions: 0,
                symbol: "USTEC",
                user_trade_number: 6,
                weightedAvgLongPrice: 15194.135,
                weightedAvgShortPrice: 0,
            },
        ],
        GBPJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 5.93,
                floatingLongProfitEOD: 0.93,
                floatingProfit: 5.93,
                floatingProfitEOD: 0.93,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813732",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 185.733,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.66,
                floatingProfitEOD: -0.87,
                floatingShortProfit: -3.66,
                floatingShortProfitEOD: -0.87,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814888",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 186.106,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.95,
                floatingLongProfitEOD: 1.84,
                floatingProfit: -4.95,
                floatingProfitEOD: 1.84,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814569",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 186.992,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -43.78,
                floatingProfitEOD: -6.92,
                floatingShortProfit: -43.78,
                floatingShortProfitEOD: -6.92,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813822",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "GBPJPY",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 185.83325000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -254.14,
                floatingProfitEOD: -17.35,
                floatingShortProfit: -254.14,
                floatingShortProfitEOD: -17.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812186",
                longPositions: 0,
                netPositions: -0.2,
                shortPositions: 0.2,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 184.75,
            },
            {
                closedLongPositions: 0.19,
                closedLongProfit: 23.77,
                closedLongProfitEOD: 11.3,
                closedProfit: 23.77,
                closedProfitEOD: 11.3,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -30.06,
                floatingProfitEOD: -9.45,
                floatingShortProfit: -30.06,
                floatingShortProfitEOD: -9.45,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 8.673617379884035e-18,
                netPositions: -0.10999999999999999,
                shortPositions: 0.11,
                symbol: "GBPJPY",
                user_trade_number: 9,
                weightedAvgLongPrice: -76.8,
                weightedAvgShortPrice: 186.24418181818183,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -8.14,
                floatingLongProfitEOD: 36.18,
                floatingProfit: -8.14,
                floatingProfitEOD: 36.18,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813167",
                longPositions: 0.39,
                netPositions: 0.39,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 3,
                weightedAvgLongPrice: 186.65123076923078,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -524.89,
                floatingProfitEOD: -22.56,
                floatingShortProfit: -524.89,
                floatingShortProfitEOD: -22.56,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813740",
                longPositions: 0,
                netPositions: -0.26,
                shortPositions: 0.26,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 183.63,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -129.61,
                floatingProfitEOD: -34.3,
                floatingShortProfit: -129.61,
                floatingShortProfitEOD: -34.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800893",
                longPositions: 0,
                netPositions: -0.4,
                shortPositions: 0.4,
                symbol: "GBPJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 186.16,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -488.82,
                floatingProfitEOD: -17.26,
                floatingShortProfit: -488.82,
                floatingShortProfitEOD: -17.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.19999999999999998,
                shortPositions: 0.19999999999999998,
                symbol: "GBPJPY",
                user_trade_number: 8,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 182.99095000000003,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.33,
                floatingLongProfitEOD: 9.28,
                floatingProfit: 0.33,
                floatingProfitEOD: 9.28,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813958",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 186.617,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -240.86,
                floatingProfitEOD: -8.48,
                floatingShortProfit: -240.86,
                floatingShortProfitEOD: -8.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808668",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 183.043,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -24.7,
                floatingLongProfitEOD: 9.21,
                floatingProfit: -24.7,
                floatingProfitEOD: 9.21,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799396",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 186.991,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 32.51,
                floatingLongProfitEOD: 0.93,
                floatingProfit: 0.02,
                floatingProfitEOD: -7.73,
                floatingShortProfit: -32.49,
                floatingShortProfitEOD: -8.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0.01,
                netPositions: -0.09,
                shortPositions: 0.09999999999999999,
                symbol: "GBPJPY",
                user_trade_number: 8,
                weightedAvgLongPrice: 181.751,
                weightedAvgShortPrice: 186.16660000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -532.23,
                floatingProfitEOD: -43.38,
                floatingShortProfit: -532.23,
                floatingShortProfitEOD: -43.38,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799351",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 185.059,
            },
        ],
        EURJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -37.07,
                floatingProfitEOD: -1.66,
                floatingShortProfit: -37.07,
                floatingShortProfitEOD: -1.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813224",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 160.66699999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.17,
                floatingProfitEOD: -1.66,
                floatingShortProfit: -3.17,
                floatingShortProfitEOD: -1.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813216",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 163.20749999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -429.44,
                floatingProfitEOD: -23.73,
                floatingShortProfit: -429.44,
                floatingShortProfitEOD: -23.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813822",
                longPositions: 0,
                netPositions: -0.30999999999999994,
                shortPositions: 0.30999999999999994,
                symbol: "EURJPY",
                user_trade_number: 12,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 161.36822580645165,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -70.4,
                closedProfitEOD: -2.14,
                closedShortPositions: 0.1,
                closedShortProfit: -70.4,
                closedShortProfitEOD: -2.14,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813152",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -251.45,
                floatingProfitEOD: -13.35,
                floatingShortProfit: -251.45,
                floatingShortProfitEOD: -13.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813991",
                longPositions: 0,
                netPositions: -0.16,
                shortPositions: 0.16,
                symbol: "EURJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 161.089125,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -18.49,
                floatingLongProfitEOD: 8.94,
                floatingProfit: -18.49,
                floatingProfitEOD: 8.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813167",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 163.7,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -109.33,
                floatingLongProfitEOD: 26.83,
                floatingProfit: -109.33,
                floatingProfitEOD: 26.83,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814894",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 163.969,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.74,
                floatingProfitEOD: -16.68,
                floatingShortProfit: -7.74,
                floatingShortProfitEOD: -16.68,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800893",
                longPositions: 0,
                netPositions: -0.2,
                shortPositions: 0.2,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 163.379,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -215.53,
                floatingProfitEOD: -12.5,
                floatingShortProfit: -215.53,
                floatingShortProfitEOD: -12.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814194",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "EURJPY",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 161.291,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -8752.57,
                floatingProfitEOD: -155.15,
                floatingShortProfit: -8752.57,
                floatingShortProfitEOD: -155.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802384",
                longPositions: 0,
                netPositions: -1.8600000000000003,
                shortPositions: 1.8600000000000003,
                symbol: "EURJPY",
                user_trade_number: 13,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 156.39399999999995,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -24.25,
                floatingProfitEOD: -5,
                floatingShortProfit: -24.25,
                floatingShortProfitEOD: -5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812848",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "EURJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 162.8385,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -282.9,
                floatingProfitEOD: -8.34,
                floatingShortProfit: -282.9,
                floatingShortProfitEOD: -8.34,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808668",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 159.206,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3037.43,
                floatingProfitEOD: -16.6,
                floatingShortProfit: -3037.43,
                floatingShortProfitEOD: -16.6,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807466",
                longPositions: 0,
                netPositions: -0.20000000000000004,
                shortPositions: 0.20000000000000004,
                symbol: "EURJPY",
                user_trade_number: 20,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 140.68874999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1741.4,
                floatingProfitEOD: -120.12,
                floatingShortProfit: -1741.4,
                floatingShortProfitEOD: -120.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799360",
                longPositions: 0,
                netPositions: -1.44,
                shortPositions: 1.44,
                symbol: "EURJPY",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 161.63197222222223,
            },
            {
                closedLongPositions: 0.02,
                closedLongProfit: 1.35,
                closedLongProfitEOD: 1.24,
                closedProfit: 1.35,
                closedProfitEOD: 1.24,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -230.42,
                floatingProfitEOD: -71.63,
                floatingShortProfit: -230.42,
                floatingShortProfitEOD: -71.63,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -1.0200000000000002,
                shortPositions: 1.0300000000000002,
                symbol: "EURJPY",
                user_trade_number: 24,
                weightedAvgLongPrice: 163.425,
                weightedAvgShortPrice: 163.10879611650483,
            },
        ],
        EURUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 34611.11,
                floatingLongProfitEOD: -6275.5,
                floatingProfit: -426719.29,
                floatingProfitEOD: 113.35,
                floatingShortProfit: -461330.4,
                floatingShortProfitEOD: 6388.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808384",
                longPositions: 114.1,
                netPositions: -2.3100000000000165,
                shortPositions: 116.41000000000001,
                symbol: "EURUSD",
                user_trade_number: 72,
                weightedAvgLongPrice: 1.0876465985977215,
                weightedAvgShortPrice: 1.0512113856197924,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.58,
                floatingProfitEOD: 1.1,
                floatingShortProfit: -5.58,
                floatingShortProfitEOD: 1.1,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08798,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 55618.9,
                floatingLongProfitEOD: -6820.55,
                floatingProfit: -448541.99,
                floatingProfitEOD: 76.99,
                floatingShortProfit: -504160.89,
                floatingShortProfitEOD: 6897.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808385",
                longPositions: 124.01,
                netPositions: -2,
                shortPositions: 126.01,
                symbol: "EURUSD",
                user_trade_number: 76,
                weightedAvgLongPrice: 1.0861949665349568,
                weightedAvgShortPrice: 1.0508330259503216,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.45,
                floatingProfitEOD: 0.55,
                floatingShortProfit: -3.45,
                floatingShortProfitEOD: 0.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814888",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08739,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 5.35,
                closedLongProfitEOD: -0.46,
                closedProfit: 5.35,
                closedProfitEOD: -0.46,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 3.35,
                floatingLongProfitEOD: -0.55,
                floatingProfit: -29.46,
                floatingProfitEOD: 1.65,
                floatingShortProfit: -32.81,
                floatingShortProfitEOD: 2.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812524",
                longPositions: 0.01,
                netPositions: -0.03,
                shortPositions: 0.04,
                symbol: "EURUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1.08734,
                weightedAvgShortPrice: 1.0826375000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -61.46,
                floatingProfitEOD: 6.53,
                floatingShortProfit: -61.46,
                floatingShortProfitEOD: 6.53,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811779",
                longPositions: 0,
                netPositions: -0.12000000000000001,
                shortPositions: 0.12000000000000001,
                symbol: "EURUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0857241666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.37,
                floatingProfitEOD: 1.06,
                floatingShortProfit: -5.37,
                floatingShortProfitEOD: 1.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810469",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "EURUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08905,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 15.3,
                floatingLongProfitEOD: -5.5,
                floatingProfit: 15.3,
                floatingProfitEOD: -5.5,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810787",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "EURUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.08915,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0.02,
                floatingProfitEOD: 0.02,
                floatingShortProfit: 0.02,
                floatingShortProfitEOD: 0.02,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812186",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.09086,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 43.48,
                floatingLongProfitEOD: -1.1,
                floatingProfit: 43.48,
                floatingProfitEOD: -1.1,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811059",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "EURUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.06894,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -344.34,
                floatingProfitEOD: 7.15,
                floatingShortProfit: -344.34,
                floatingShortProfitEOD: 7.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8806738",
                longPositions: 0,
                netPositions: -0.13,
                shortPositions: 0.13,
                symbol: "EURUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0642823076923076,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 2.13,
                closedLongProfitEOD: -0.6,
                closedProfit: 2.13,
                closedProfitEOD: -0.6,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -42.28,
                floatingProfitEOD: 0.54,
                floatingShortProfit: -42.28,
                floatingShortProfitEOD: 0.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805626",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.04857,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 61.5,
                floatingLongProfitEOD: -24.75,
                floatingProfit: -1824.6,
                floatingProfitEOD: 2.75,
                floatingShortProfit: -1886.1,
                floatingShortProfitEOD: 27.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807928",
                longPositions: 0.44999999999999996,
                netPositions: -0.04999999999999993,
                shortPositions: 0.4999999999999999,
                symbol: "EURUSD",
                user_trade_number: 12,
                weightedAvgLongPrice: 1.0893233333333334,
                weightedAvgShortPrice: 1.0531180000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -787.54,
                floatingProfitEOD: 26.95,
                floatingShortProfit: -787.54,
                floatingShortProfitEOD: 26.95,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.4900000000000001,
                shortPositions: 0.4900000000000001,
                symbol: "EURUSD",
                user_trade_number: 15,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0747677551020403,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -455.57,
                floatingProfitEOD: 17.05,
                floatingShortProfit: -455.57,
                floatingShortProfitEOD: 17.05,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803208",
                longPositions: 0,
                netPositions: -0.31000000000000005,
                shortPositions: 0.31000000000000005,
                symbol: "EURUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.076074193548387,
            },
        ],
        USOUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -615471.92,
                floatingLongProfitEOD: 6697.98,
                floatingProfit: -615471.92,
                floatingProfitEOD: 6697.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808384",
                longPositions: 42.31,
                netPositions: 42.31,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 24,
                weightedAvgLongPrice: 90.85303190735051,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -293603.12,
                floatingLongProfitEOD: 4113.08,
                floatingProfit: -293603.12,
                floatingProfitEOD: 4113.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808385",
                longPositions: 26.009999999999998,
                netPositions: 26.009999999999998,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 17,
                weightedAvgLongPrice: 87.59422068435218,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 11.45,
                floatingLongProfitEOD: 7.9,
                floatingProfit: 11.45,
                floatingProfitEOD: 7.9,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802181",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 76.077,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 2.01,
                closedLongProfit: -468.33,
                closedLongProfitEOD: -631.14,
                closedProfit: -468.33,
                closedProfitEOD: -631.14,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809051",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.07,
                closedLongProfit: 67.08,
                closedLongProfitEOD: 0.07,
                closedProfit: 67.08,
                closedProfitEOD: 0.07,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 111.97,
                floatingLongProfitEOD: 9.48,
                floatingProfit: 111.97,
                floatingProfitEOD: 9.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811093",
                longPositions: 0.06,
                netPositions: 0.06,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 9,
                weightedAvgLongPrice: 74.4408333333333,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.03,
                closedLongProfit: -0.51,
                closedLongProfitEOD: -4.03,
                closedProfit: -0.51,
                closedProfitEOD: -4.03,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -72.75,
                floatingProfitEOD: -8.3,
                floatingShortProfit: -72.75,
                floatingShortProfitEOD: -8.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810469",
                longPositions: -3.469446951953614e-18,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "USOUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: 64,
                weightedAvgShortPrice: 74.892,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 9660,
                floatingLongProfitEOD: 790,
                floatingProfit: 26338,
                floatingProfitEOD: -206,
                floatingShortProfit: 16678,
                floatingShortProfitEOD: -996,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812560",
                longPositions: 5,
                netPositions: -1,
                shortPositions: 6,
                symbol: "USOUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 74.381,
                weightedAvgShortPrice: 79.12066666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3448,
                floatingLongProfitEOD: 316,
                floatingProfit: -3448,
                floatingProfitEOD: 316,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814024",
                longPositions: 2,
                netPositions: 2,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 78.031,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -98.56,
                floatingLongProfitEOD: 3.16,
                floatingProfit: -101.47,
                floatingProfitEOD: 0.25,
                floatingShortProfit: -2.91,
                floatingShortProfitEOD: -2.91,
                isInPercentageMode: false,
                login: "DEMO:MT4:8804559",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "USOUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 81.23400000000001,
                weightedAvgShortPrice: 76.057,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -324.3,
                floatingLongProfitEOD: 3.17,
                floatingProfit: -324.3,
                floatingProfitEOD: 3.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802358",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 92.5215,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -715.28,
                floatingLongProfitEOD: 12.65,
                floatingProfit: -715.28,
                floatingProfitEOD: 12.65,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802656",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: 85.247125,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2227.06,
                floatingLongProfitEOD: 167.48,
                floatingProfit: 2227.06,
                floatingProfitEOD: 167.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802378",
                longPositions: 1.06,
                netPositions: 1.06,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 74.205,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -6.72,
                floatingLongProfitEOD: 6.32,
                floatingProfit: -19.12,
                floatingProfitEOD: -0.32,
                floatingShortProfit: -12.4,
                floatingShortProfitEOD: -6.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814293",
                longPositions: 0.04,
                netPositions: 0,
                shortPositions: 0.04,
                symbol: "USOUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 76.47500000000001,
                weightedAvgShortPrice: 76.037,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -6.75,
                floatingProfitEOD: -6.75,
                floatingShortProfit: -6.75,
                floatingShortProfitEOD: -6.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807401",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "USOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 76.213,
            },
            {
                closedLongPositions: 0.15,
                closedLongProfit: 0.45,
                closedLongProfitEOD: -18.65,
                closedProfit: 0.45,
                closedProfitEOD: -18.65,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -361.25,
                floatingProfitEOD: -41.5,
                floatingShortProfit: -361.25,
                floatingShortProfitEOD: -41.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812727",
                longPositions: 1.3877787807814457e-17,
                netPositions: -0.25,
                shortPositions: 0.25,
                symbol: "USOUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: -64,
                weightedAvgShortPrice: 74.902,
            },
        ],
        XAGUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -235.75,
                floatingProfitEOD: 87.5,
                floatingShortProfit: -235.75,
                floatingShortProfitEOD: 87.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810918",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "XAGUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.202499999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -24,
                floatingLongProfitEOD: -24,
                floatingProfit: -24,
                floatingProfitEOD: -24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813469",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 23.672,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1583.5,
                floatingProfitEOD: 437.5,
                floatingShortProfit: -1583.5,
                floatingShortProfitEOD: 437.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803781",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "XAGUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.040600000000005,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1580,
                floatingProfitEOD: 437.5,
                floatingShortProfit: -1580,
                floatingShortProfitEOD: 437.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802670",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "XAGUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.042,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -338.3,
                floatingProfitEOD: 17.4,
                floatingShortProfit: -338.3,
                floatingShortProfitEOD: 17.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802194",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 20.292,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -78,
                floatingProfitEOD: 131.25,
                floatingShortProfit: -78,
                floatingShortProfitEOD: 131.25,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812141",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.57,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 280,
                floatingProfitEOD: 875,
                floatingShortProfit: 280,
                floatingShortProfitEOD: 875,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810148",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.73,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -575,
                closedProfitEOD: 840,
                closedShortPositions: 1,
                closedShortProfit: -575,
                closedShortProfitEOD: 840,
                floatingLongProfit: -4575,
                floatingLongProfitEOD: 1920,
                floatingProfit: -22205,
                floatingProfitEOD: 6295,
                floatingShortProfit: -17630,
                floatingShortProfitEOD: 4375,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814024",
                longPositions: 6,
                netPositions: 1,
                shortPositions: 5,
                symbol: "XAGUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: 23.8085,
                weightedAvgShortPrice: 22.970799999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -675.75,
                floatingProfitEOD: 43.75,
                floatingShortProfit: -675.75,
                floatingShortProfitEOD: 43.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807100",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 20.971,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -24.85,
                floatingProfitEOD: 17.5,
                floatingShortProfit: -24.85,
                floatingShortProfitEOD: 17.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809754",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "XAGUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.4255,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 8.25,
                closedProfitEOD: 8.35,
                closedShortPositions: 0.01,
                closedShortProfit: 8.25,
                closedShortProfitEOD: 8.35,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -13,
                floatingProfitEOD: 8.75,
                floatingShortProfit: -13,
                floatingShortProfitEOD: 8.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802682",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "XAGUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.414,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 90.7,
                floatingLongProfitEOD: 6.4,
                floatingProfit: 90.7,
                floatingProfitEOD: 6.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799569",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 22.754,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 65520,
                floatingLongProfitEOD: 2240,
                floatingProfit: 65520,
                floatingProfitEOD: 2240,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:100000010",
                longPositions: 7,
                netPositions: 7,
                shortPositions: 0,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 21.789000000000005,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 250,
                floatingProfitEOD: 310,
                floatingShortProfit: 250,
                floatingShortProfitEOD: 310,
                isInPercentageMode: false,
                login: "DEMO:MT4:99999999",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.721,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -59.9,
                floatingProfitEOD: 17.4,
                floatingShortProfit: -59.9,
                floatingShortProfitEOD: 17.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811420",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.076,
            },
        ],
        BTCUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.66,
                floatingProfitEOD: -1.66,
                floatingShortProfit: -1.66,
                floatingShortProfitEOD: -1.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811143",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37400.19,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -91.74,
                closedProfitEOD: -54.58,
                closedShortPositions: 0.14,
                closedShortProfit: -91.74,
                closedShortProfitEOD: -54.58,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 12.88,
                floatingProfitEOD: -25.63,
                floatingShortProfit: 12.88,
                floatingShortProfitEOD: -25.63,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814835",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "BTCUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37584.43000000001,
            },
            {
                closedLongPositions: 0.1,
                closedLongProfit: 14.42,
                closedLongProfitEOD: 15.92,
                closedProfit: 14.42,
                closedProfitEOD: 15.92,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 12.75,
                floatingLongProfitEOD: 9.14,
                floatingProfit: 12.75,
                floatingProfitEOD: 9.14,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802181",
                longPositions: 0.01999999999999999,
                netPositions: 0.01999999999999999,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 36804.25000000003,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 18.22,
                floatingLongProfitEOD: 10.91,
                floatingProfit: 18.22,
                floatingProfitEOD: 10.91,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813989",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 36835,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -51.17,
                closedProfitEOD: -51.17,
                closedShortPositions: 0.25,
                closedShortProfit: -51.17,
                closedShortProfitEOD: -51.17,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 1.16,
                floatingProfitEOD: 1.16,
                floatingShortProfit: 1.16,
                floatingShortProfitEOD: 1.16,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809080",
                longPositions: 0,
                netPositions: -0.10000000000000002,
                shortPositions: 0.10000000000000002,
                symbol: "BTCUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37467.119999999995,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 2.22,
                closedLongProfitEOD: 1.46,
                closedProfit: 2.22,
                closedProfitEOD: 1.46,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813096",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.55,
                floatingProfitEOD: -0.55,
                floatingShortProfit: -0.55,
                floatingShortProfitEOD: -0.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810469",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37400.19,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 47.62,
                floatingLongProfitEOD: 91.44,
                floatingProfit: 47.62,
                floatingProfitEOD: 91.44,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813781",
                longPositions: 0.20000000000000004,
                netPositions: 0.20000000000000004,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 37203.439999999995,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 12.78,
                floatingProfitEOD: 12.78,
                floatingShortProfit: 12.78,
                floatingShortProfitEOD: 12.78,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812793",
                longPositions: 0,
                netPositions: -1.01,
                shortPositions: 1.01,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37468.22,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2051.99,
                floatingLongProfitEOD: 741.56,
                floatingProfit: -7363.78,
                floatingProfitEOD: -461.92,
                floatingShortProfit: -9415.77,
                floatingShortProfitEOD: -1203.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813740",
                longPositions: 1.62,
                netPositions: -1.1400000000000006,
                shortPositions: 2.7600000000000007,
                symbol: "BTCUSD",
                user_trade_number: 17,
                weightedAvgLongPrice: 36175.51666666666,
                weightedAvgShortPrice: 34044.34257246375,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -163.49,
                floatingProfitEOD: -140.28,
                floatingShortProfit: -163.49,
                floatingShortProfitEOD: -140.28,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812585",
                longPositions: 0,
                netPositions: -0.4,
                shortPositions: 0.4,
                symbol: "BTCUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37048.68,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -15.48,
                closedProfitEOD: -5.18,
                closedShortPositions: 0.02,
                closedShortProfit: -15.48,
                closedShortProfitEOD: -5.18,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802840",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.16,
                floatingProfitEOD: -0.16,
                floatingShortProfit: -0.16,
                floatingShortProfitEOD: -0.16,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813738",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37452.35,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.77,
                floatingProfitEOD: -2.77,
                floatingShortProfit: -2.77,
                floatingShortProfitEOD: -2.77,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812727",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37400.19,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 99.28,
                floatingLongProfitEOD: 4.57,
                floatingProfit: 99.28,
                floatingProfitEOD: 4.57,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999980",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 27513.31,
                weightedAvgShortPrice: 0,
            },
        ],
        GBPNZD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.05,
                floatingProfitEOD: 8.41,
                floatingShortProfit: -0.05,
                floatingShortProfitEOD: 8.41,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809024",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "GBPNZD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 2.077222,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 1.92,
                closedProfitEOD: 3.5,
                closedShortPositions: 0.04,
                closedShortProfit: 1.92,
                closedShortProfitEOD: 3.5,
                floatingLongProfit: -6.78,
                floatingLongProfitEOD: -3.24,
                floatingProfit: -6.78,
                floatingProfitEOD: -3.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814194",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 3,
                weightedAvgLongPrice: 2.08243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -57.94,
                floatingLongProfitEOD: -3.23,
                floatingProfit: -57.94,
                floatingProfitEOD: -3.23,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809801",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 2.125075,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 1.92,
                closedProfitEOD: 8.2,
                closedShortPositions: 0.04,
                closedShortProfit: 1.92,
                closedShortProfitEOD: 8.2,
                floatingLongProfit: -6.67,
                floatingLongProfitEOD: -3.24,
                floatingProfit: -6.67,
                floatingProfitEOD: -3.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0.02,
                netPositions: 0.020000000000000004,
                shortPositions: -1.734723475976807e-18,
                symbol: "GBPNZD",
                user_trade_number: 5,
                weightedAvgLongPrice: 2.08234,
                weightedAvgShortPrice: 2,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -496.31,
                floatingLongProfitEOD: -250.94,
                floatingProfit: -496.31,
                floatingProfitEOD: -250.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799838",
                longPositions: 1.55,
                netPositions: 1.55,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 2.08218,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 95453.18,
                floatingLongProfitEOD: -1878.04,
                floatingProfit: 95453.18,
                floatingProfitEOD: -1878.04,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799947",
                longPositions: 11.6,
                netPositions: 11.6,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.9396100000000003,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 10711.97,
                floatingLongProfitEOD: -210.47,
                floatingProfit: 10711.97,
                floatingProfitEOD: -210.47,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799946",
                longPositions: 1.3,
                netPositions: 1.3,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.93942,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 13.12,
                closedProfitEOD: 36.81,
                closedShortPositions: 0.22,
                closedShortProfit: 13.12,
                closedShortProfitEOD: 36.81,
                floatingLongProfit: -9.59,
                floatingLongProfitEOD: -7.46,
                floatingProfit: -10.66,
                floatingProfitEOD: -8.53,
                floatingShortProfit: -1.07,
                floatingShortProfitEOD: -1.07,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.08,
                netPositions: 0.059999999999999984,
                shortPositions: 0.02000000000000002,
                symbol: "GBPNZD",
                user_trade_number: 19,
                weightedAvgLongPrice: 2.07877625,
                weightedAvgShortPrice: 2.0763899999999995,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 40.52,
                floatingLongProfitEOD: -38.86,
                floatingProfit: 40.52,
                floatingProfitEOD: -38.86,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799402",
                longPositions: 0.24000000000000002,
                netPositions: 0.24000000000000002,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 2.073964166666667,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -110.52,
                floatingLongProfitEOD: -6.48,
                floatingProfit: -110.52,
                floatingProfitEOD: -6.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799710",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 2.12286,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -954.2,
                floatingProfitEOD: 66.46,
                floatingShortProfit: -954.2,
                floatingShortProfitEOD: 66.46,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799732",
                longPositions: 0,
                netPositions: -0.4,
                shortPositions: 0.4,
                symbol: "GBPNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 2.0374999999999996,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 1.92,
                closedProfitEOD: 8.16,
                closedShortPositions: 0.04,
                closedShortProfit: 1.92,
                closedShortProfitEOD: 8.16,
                floatingLongProfit: -6.68,
                floatingLongProfitEOD: -3.24,
                floatingProfit: -6.68,
                floatingProfitEOD: -3.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799775",
                longPositions: 0.02,
                netPositions: 0.020000000000000004,
                shortPositions: -3.469446951953614e-18,
                symbol: "GBPNZD",
                user_trade_number: 5,
                weightedAvgLongPrice: 2.08235,
                weightedAvgShortPrice: 2,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 21.03,
                closedProfitEOD: 69.93,
                closedShortPositions: 0.44,
                closedShortProfit: 21.03,
                closedShortProfitEOD: 69.93,
                floatingLongProfit: -66.8,
                floatingLongProfitEOD: -32.38,
                floatingProfit: -66.8,
                floatingProfitEOD: -32.38,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999596",
                longPositions: 0.2,
                netPositions: 0.19999999999999996,
                shortPositions: 5.551115123125783e-17,
                symbol: "GBPNZD",
                user_trade_number: 5,
                weightedAvgLongPrice: 2.08235,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 1.94,
                closedProfitEOD: 7.58,
                closedShortPositions: 0.04,
                closedShortProfit: 1.94,
                closedShortProfitEOD: 7.58,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.05,
                floatingLongProfitEOD: -1.62,
                floatingProfit: -3.05,
                floatingProfitEOD: -1.62,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808263",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 2.08184,
                weightedAvgShortPrice: 0,
            },
        ],
        XAUUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -78.66,
                floatingProfitEOD: 18.79,
                floatingShortProfit: -78.66,
                floatingShortProfitEOD: 18.79,
                isInPercentageMode: false,
                login: "DEMO:MT4:8815017",
                longPositions: 17,
                netPositions: 16.88000000000000001,
                shortPositions: 0.12000000000000001,
                symbol: "XAUUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1971.1558333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -99.46,
                floatingProfitEOD: 14.85,
                floatingShortProfit: -99.46,
                floatingShortProfitEOD: 14.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814844",
                longPositions: 21,
                netPositions: 20.955,
                shortPositions: 0.05,
                symbol: "XAUUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1957.768,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1417.54,
                floatingLongProfitEOD: -216.41,
                floatingProfit: -8028.57,
                floatingProfitEOD: 77.17,
                floatingShortProfit: -6611.03,
                floatingShortProfitEOD: 293.58,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812423",
                longPositions: 0.72,
                netPositions: -0.28000000000000025,
                shortPositions: 1.0000000000000002,
                symbol: "XAUUSD",
                user_trade_number: 22,
                weightedAvgLongPrice: 1997.1223611111113,
                weightedAvgShortPrice: 1911.5838999999996,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -189.35,
                floatingProfitEOD: 32.43,
                floatingShortProfit: -189.35,
                floatingShortProfitEOD: 32.43,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814843",
                longPositions: 0,
                netPositions: -0.10999999999999999,
                shortPositions: 0.10999999999999999,
                symbol: "XAUUSD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1960.468181818182,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -304.7,
                floatingLongProfitEOD: -36.18,
                floatingProfit: -883.72,
                floatingProfitEOD: 10.36,
                floatingShortProfit: -579.02,
                floatingShortProfitEOD: 46.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813877",
                longPositions: 0.12000000000000001,
                netPositions: -0.039999999999999994,
                shortPositions: 0.16,
                symbol: "XAUUSD",
                user_trade_number: 12,
                weightedAvgLongPrice: 2002.8166666666662,
                weightedAvgShortPrice: 1941.5324999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 69.55,
                floatingLongProfitEOD: -14.7,
                floatingProfit: -1554.13,
                floatingProfitEOD: 32.66,
                floatingShortProfit: -1623.68,
                floatingShortProfitEOD: 47.36,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812667",
                longPositions: 0.05,
                netPositions: -0.11,
                shortPositions: 0.16,
                symbol: "XAUUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1963.59,
                weightedAvgShortPrice: 1876.19,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: -11.99,
                closedLongProfitEOD: -2.56,
                closedProfit: -11.99,
                closedProfitEOD: -2.56,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802181",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "XAUUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -223.4,
                floatingLongProfitEOD: -57.38,
                floatingProfit: -1048.23,
                floatingProfitEOD: -1.23,
                floatingShortProfit: -824.83,
                floatingShortProfitEOD: 56.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810480",
                longPositions: 0.19,
                netPositions: 0,
                shortPositions: 0.19,
                symbol: "XAUUSD",
                user_trade_number: 10,
                weightedAvgLongPrice: 1989.177894736842,
                weightedAvgShortPrice: 1934.262631578947,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 47.63,
                floatingProfitEOD: 14.66,
                floatingShortProfit: 47.63,
                floatingShortProfitEOD: 14.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814969",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "XAUUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1987.224,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.36,
                floatingLongProfitEOD: -6.08,
                floatingProfit: -403.88,
                floatingProfitEOD: 5.74,
                floatingShortProfit: -403.52,
                floatingShortProfitEOD: 11.82,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812660",
                longPositions: 0.02,
                netPositions: -0.02,
                shortPositions: 0.04,
                symbol: "XAUUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1977.5799999999997,
                weightedAvgShortPrice: 1876.7949999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 6.6,
                closedProfitEOD: 15.35,
                closedShortPositions: 0.05,
                closedShortProfit: 6.6,
                closedShortProfitEOD: 15.35,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813991",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "XAUUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -120.1,
                floatingLongProfitEOD: -52,
                floatingProfit: -4898.1,
                floatingProfitEOD: 100.4,
                floatingShortProfit: -4778,
                floatingShortProfitEOD: 152.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809929",
                longPositions: 0.2,
                netPositions: -0.39999999999999997,
                shortPositions: 0.6,
                symbol: "XAUUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: 1983.775,
                weightedAvgShortPrice: 1898.5166666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -69.12,
                floatingLongProfitEOD: -31.2,
                floatingProfit: -501.8,
                floatingProfitEOD: -3.26,
                floatingShortProfit: -432.68,
                floatingShortProfitEOD: 27.94,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807988",
                longPositions: 0.12,
                netPositions: 0.009999999999999995,
                shortPositions: 0.11,
                symbol: "XAUUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1983.53,
                weightedAvgShortPrice: 1938.8154545454545,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -73.06,
                floatingLongProfitEOD: -35.94,
                floatingProfit: -853.08,
                floatingProfitEOD: 17.36,
                floatingShortProfit: -780.02,
                floatingShortProfitEOD: 53.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814609",
                longPositions: 0.12000000000000001,
                netPositions: -0.059999999999999984,
                shortPositions: 0.18,
                symbol: "XAUUSD",
                user_trade_number: 15,
                weightedAvgLongPrice: 1983.533333333333,
                weightedAvgShortPrice: 1934.3344444444447,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3851.81,
                floatingLongProfitEOD: -1751.87,
                floatingProfit: -26336.27,
                floatingProfitEOD: -27.91,
                floatingShortProfit: -22484.46,
                floatingShortProfitEOD: 1723.96,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808295",
                longPositions: 5.8599999999999985,
                netPositions: -0.030000000000002025,
                shortPositions: 5.890000000000001,
                symbol: "XAUUSD",
                user_trade_number: 49,
                weightedAvgLongPrice: 1984.0235153583626,
                weightedAvgShortPrice: 1939.4206451612897,
            },
        ],
        NZDUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -13.1,
                floatingLongProfitEOD: 9.4,
                floatingProfit: -13.1,
                floatingProfitEOD: 9.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799396",
                longPositions: 0.2,
                netPositions: 0.2,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.6002749999999999,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -15.53,
                floatingProfitEOD: -2.73,
                floatingShortProfit: -15.53,
                floatingShortProfitEOD: -2.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799361",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "NZDUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5972066666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.32,
                floatingLongProfitEOD: 1.92,
                floatingProfit: 0.32,
                floatingProfitEOD: 1.92,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799394",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.59955,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.28,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -30.14,
                floatingProfitEOD: -5.04,
                floatingShortProfit: -30.42,
                floatingShortProfitEOD: -5.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.14,
                shortPositions: 0.15000000000000002,
                symbol: "NZDUSD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0.59935,
                weightedAvgShortPrice: 0.5977679999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.46,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -30.21,
                floatingProfitEOD: -5.09,
                floatingShortProfit: -30.67,
                floatingShortProfitEOD: -5.57,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.01,
                netPositions: -0.14,
                shortPositions: 0.15000000000000002,
                symbol: "NZDUSD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0.59917,
                weightedAvgShortPrice: 0.5977546666666664,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.26,
                floatingProfitEOD: -1.85,
                floatingShortProfit: -9.26,
                floatingShortProfitEOD: -1.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799515",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "NZDUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5974875,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.41,
                floatingLongProfitEOD: 1.41,
                floatingProfit: -9.59,
                floatingProfitEOD: 0.48,
                floatingShortProfit: -5.18,
                floatingShortProfitEOD: -0.93,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799426",
                longPositions: 0.03,
                netPositions: 0.009999999999999998,
                shortPositions: 0.02,
                symbol: "NZDUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0.60109,
                weightedAvgShortPrice: 0.597215,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -96.9,
                floatingLongProfitEOD: 14.1,
                floatingProfit: -96.9,
                floatingProfitEOD: 14.1,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999546",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.60291,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1516,
                floatingProfitEOD: -94,
                floatingShortProfit: -1516,
                floatingShortProfitEOD: -94,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799589",
                longPositions: 0,
                netPositions: -2,
                shortPositions: 2,
                symbol: "NZDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.59223,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -744.1,
                floatingProfitEOD: -16.45,
                floatingShortProfit: -744.1,
                floatingShortProfitEOD: -16.45,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0,
                netPositions: -0.35000000000000003,
                shortPositions: 0.35000000000000003,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.57855,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -9.8,
                floatingLongProfitEOD: 0.94,
                floatingProfit: -9.8,
                floatingProfitEOD: 0.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814325",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.6045,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3,
                floatingLongProfitEOD: 0.47,
                floatingProfit: -3,
                floatingProfitEOD: 0.47,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813995",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.6026,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -17.3,
                floatingLongProfitEOD: 2.35,
                floatingProfit: -17.3,
                floatingProfitEOD: 2.35,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809252",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.60306,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.05,
                floatingLongProfitEOD: 0.47,
                floatingProfit: -3.05,
                floatingProfitEOD: 0.47,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812103",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.60265,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -18.43,
                floatingProfitEOD: -1.41,
                floatingShortProfit: -18.43,
                floatingShortProfitEOD: -1.41,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811132",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "NZDUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5936766666666667,
            },
        ],
        USDJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 82379.87,
                floatingLongProfitEOD: 2942.14,
                floatingProfit: 82379.87,
                floatingProfitEOD: 2942.14,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799947",
                longPositions: 23.2,
                netPositions: 23.2,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 144.511,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 9010.1,
                floatingLongProfitEOD: 329.72,
                floatingProfit: 9010.1,
                floatingProfitEOD: 329.72,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799946",
                longPositions: 2.6,
                netPositions: 2.6,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 144.639,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.06,
                closedLongProfit: 4.03,
                closedLongProfitEOD: 6.66,
                closedProfit: 4.03,
                closedProfitEOD: 6.66,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.01,
                floatingLongProfitEOD: 0.01,
                floatingProfit: -30.16,
                floatingProfitEOD: -22.24,
                floatingShortProfit: -30.17,
                floatingShortProfitEOD: -22.25,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.010000000000000004,
                netPositions: -0.16999999999999998,
                shortPositions: 0.18,
                symbol: "USDJPY",
                user_trade_number: 16,
                weightedAvgLongPrice: 149.82399999999987,
                weightedAvgShortPrice: 149.58816666666664,
            },
            {
                closedLongPositions: 0.08,
                closedLongProfit: 5.33,
                closedLongProfitEOD: 9.28,
                closedProfit: 5.33,
                closedProfitEOD: 9.28,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.11,
                floatingLongProfitEOD: -0.11,
                floatingProfit: -24.05,
                floatingProfitEOD: -18.68,
                floatingShortProfit: -23.94,
                floatingShortProfitEOD: -18.57,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.009999999999999986,
                netPositions: -0.14,
                shortPositions: 0.15,
                symbol: "USDJPY",
                user_trade_number: 16,
                weightedAvgLongPrice: 149.84200000000013,
                weightedAvgShortPrice: 149.60033333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 83.7,
                floatingLongProfitEOD: 115.06,
                floatingProfit: -33.61,
                floatingProfitEOD: 14.52,
                floatingShortProfit: -117.31,
                floatingShortProfitEOD: -100.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999999",
                longPositions: 1.61,
                netPositions: 0.8000000000000002,
                shortPositions: 0.8099999999999999,
                symbol: "USDJPY",
                user_trade_number: 9,
                weightedAvgLongPrice: 149.7451055900621,
                weightedAvgShortPrice: 149.624,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1814.13,
                floatingLongProfitEOD: 382.45,
                floatingProfit: -2789.16,
                floatingProfitEOD: 10.06,
                floatingShortProfit: -975.03,
                floatingShortProfitEOD: -372.39,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799711",
                longPositions: 3,
                netPositions: 0,
                shortPositions: 3,
                symbol: "USDJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 150.73,
                weightedAvgShortPrice: 149.354,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -37.71,
                floatingProfitEOD: -62.07,
                floatingShortProfit: -37.71,
                floatingShortProfitEOD: -62.07,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999103",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 149.728,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -210.81,
                floatingProfitEOD: -8.69,
                floatingShortProfit: -210.81,
                floatingShortProfitEOD: -8.69,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.07,
                shortPositions: 0.07,
                symbol: "USDJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 145.32742857142858,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: -2.31,
                closedLongProfitEOD: -1.35,
                closedProfit: -2.31,
                closedProfitEOD: -1.35,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -60.67,
                floatingLongProfitEOD: 12.68,
                floatingProfit: -60.67,
                floatingProfitEOD: 12.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812489",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 150.732,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 13.98,
                floatingLongProfitEOD: 6.37,
                floatingProfit: 13.98,
                floatingProfitEOD: 6.37,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812009",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 149.405,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -15.6,
                floatingLongProfitEOD: 7.6,
                floatingProfit: -15.6,
                floatingProfitEOD: 7.6,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814627",
                longPositions: 0.06,
                netPositions: 0.06,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 150.2145,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 23.43,
                floatingLongProfitEOD: 12.68,
                floatingProfit: 23.43,
                floatingProfitEOD: 12.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813152",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 149.472,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2.06,
                floatingLongProfitEOD: 14.01,
                floatingProfit: 2.06,
                floatingProfitEOD: 14.01,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0.11,
                netPositions: 0.11,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 4,
                weightedAvgLongPrice: 149.79572727272728,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.73,
                floatingLongProfitEOD: 1.27,
                floatingProfit: -9.79,
                floatingProfitEOD: 0.03,
                floatingShortProfit: -4.06,
                floatingShortProfitEOD: -1.24,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805626",
                longPositions: 0.01,
                netPositions: 0,
                shortPositions: 0.01,
                symbol: "USDJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 150.683,
                weightedAvgShortPrice: 149.232,
            },
        ],
        GBPAUD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.72,
                floatingProfitEOD: 1.8,
                floatingShortProfit: -9.72,
                floatingShortProfitEOD: 1.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9102799999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -52.55,
                floatingProfitEOD: 19.2,
                floatingShortProfit: -52.55,
                floatingShortProfitEOD: 19.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999604",
                longPositions: 0,
                netPositions: -0.51,
                shortPositions: 0.51,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9110701960784313,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.44,
                floatingLongProfitEOD: -0.97,
                floatingProfit: -8.06,
                floatingProfitEOD: -0.21,
                floatingShortProfit: -2.62,
                floatingShortProfitEOD: 0.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799426",
                longPositions: 0.03,
                netPositions: 0.009999999999999998,
                shortPositions: 0.02,
                symbol: "GBPAUD",
                user_trade_number: 5,
                weightedAvgLongPrice: 1.9151066666666667,
                weightedAvgShortPrice: 1.9106299999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -32.53,
                floatingLongProfitEOD: -1.36,
                floatingProfit: -32.53,
                floatingProfitEOD: -1.36,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799403",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPAUD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.9247899999999998,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -6.17,
                floatingLongProfitEOD: -0.61,
                floatingProfit: -7.36,
                floatingProfitEOD: -0.23,
                floatingShortProfit: -1.19,
                floatingShortProfitEOD: 0.38,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799446",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "GBPAUD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.9170900000000002,
                weightedAvgShortPrice: 1.9108199999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -24.47,
                floatingLongProfitEOD: -4.93,
                floatingProfit: -45.79,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -21.32,
                floatingShortProfitEOD: 5.57,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799488",
                longPositions: 0.18,
                netPositions: 0.01999999999999999,
                shortPositions: 0.16,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.914486666666667,
                weightedAvgShortPrice: 1.910650625,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.8,
                floatingProfitEOD: 1.76,
                floatingShortProfit: -9.8,
                floatingShortProfitEOD: 1.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799806",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9102683333333332,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 106463.31,
                floatingLongProfitEOD: -445.67,
                floatingProfit: 106463.31,
                floatingProfitEOD: -445.67,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799947",
                longPositions: 11.6,
                netPositions: 11.6,
                shortPositions: 0,
                symbol: "GBPAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.7713500000000002,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 11971.87,
                floatingLongProfitEOD: -52.49,
                floatingProfit: 11971.87,
                floatingProfitEOD: -52.49,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799946",
                longPositions: 1.3,
                netPositions: 1.3,
                shortPositions: 0,
                symbol: "GBPAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.77084,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.73,
                floatingProfitEOD: 1.73,
                floatingShortProfit: -9.73,
                floatingShortProfitEOD: 1.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799801",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9102933333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -11.83,
                floatingProfitEOD: 5.52,
                floatingShortProfit: -11.83,
                floatingShortProfitEOD: 5.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799823",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9114540000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -7.03,
                floatingLongProfitEOD: -6.25,
                floatingProfit: -7.03,
                floatingProfitEOD: -6.25,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999545",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "GBPAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.91292,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -14.91,
                floatingProfitEOD: 5.64,
                floatingShortProfit: -14.91,
                floatingShortProfitEOD: 5.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799775",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9111253333333333,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.12,
                floatingLongProfitEOD: -1.22,
                floatingProfit: -13.33,
                floatingProfitEOD: -0.62,
                floatingShortProfit: -3.21,
                floatingShortProfitEOD: 0.6,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799312",
                longPositions: 0.04,
                netPositions: 0.02,
                shortPositions: 0.02,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.9162350000000001,
                weightedAvgShortPrice: 1.910305,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.4,
                floatingProfitEOD: 2.26,
                floatingShortProfit: -7.4,
                floatingShortProfitEOD: 2.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799455",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9107499999999997,
            },
        ],
        NZDCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -732.58,
                floatingLongProfitEOD: 58.79,
                floatingProfit: -732.58,
                floatingProfitEOD: 58.79,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799462",
                longPositions: 0.8400000000000001,
                netPositions: 0.8400000000000001,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.5390057142857142,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.99,
                floatingLongProfitEOD: 2.09,
                floatingProfit: -3.73,
                floatingProfitEOD: 1.47,
                floatingShortProfit: -2.74,
                floatingShortProfitEOD: -0.62,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799361",
                longPositions: 0.03,
                netPositions: 0.019999999999999997,
                shortPositions: 0.01,
                symbol: "NZDCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0.53151,
                weightedAvgShortPrice: 0.529,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.5,
                floatingLongProfitEOD: 21.49,
                floatingProfit: -12.48,
                floatingProfitEOD: 16.39,
                floatingShortProfit: -12.98,
                floatingShortProfitEOD: -5.1,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.30999999999999994,
                netPositions: 0.22999999999999995,
                shortPositions: 0.07999999999999999,
                symbol: "NZDCHF",
                user_trade_number: 19,
                weightedAvgLongPrice: 0.5312,
                weightedAvgShortPrice: 0.53000875,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -16.6,
                floatingLongProfitEOD: 21.61,
                floatingProfit: -29.69,
                floatingProfitEOD: 16.51,
                floatingShortProfit: -13.09,
                floatingShortProfitEOD: -5.1,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.31000000000000005,
                netPositions: 0.23000000000000007,
                shortPositions: 0.07999999999999999,
                symbol: "NZDCHF",
                user_trade_number: 19,
                weightedAvgLongPrice: 0.5316919354838711,
                weightedAvgShortPrice: 0.5299962500000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -31.21,
                floatingLongProfitEOD: 3.5,
                floatingProfit: -31.21,
                floatingProfitEOD: 3.5,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.53675,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -449.9,
                floatingProfitEOD: -22.51,
                floatingShortProfit: -449.9,
                floatingShortProfitEOD: -22.51,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0,
                netPositions: -0.35000000000000003,
                shortPositions: 0.35000000000000003,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.52006,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -19.08,
                floatingProfitEOD: -2.55,
                floatingShortProfit: -19.08,
                floatingShortProfitEOD: -2.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813752",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.52723,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.3,
                floatingLongProfitEOD: 0.7,
                floatingProfit: -2.3,
                floatingProfitEOD: 0.7,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814962",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.53324,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -29.15,
                floatingProfitEOD: -3.8,
                floatingShortProfit: -29.15,
                floatingShortProfitEOD: -3.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812026",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5271450000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.74,
                floatingProfitEOD: -1.26,
                floatingShortProfit: -9.74,
                floatingShortProfitEOD: -1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812302",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.527135,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -29.25,
                floatingProfitEOD: -3.8,
                floatingShortProfit: -29.25,
                floatingShortProfitEOD: -3.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812304",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.52713,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.14,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -3.14,
                floatingProfitEOD: 1.44,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812305",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.53261,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -68.81,
                floatingProfitEOD: -8.92,
                floatingShortProfit: -68.81,
                floatingShortProfitEOD: -8.92,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810008",
                longPositions: 0,
                netPositions: -0.14,
                shortPositions: 0.14,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5270999999999999,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.61,
                floatingLongProfitEOD: 1.4,
                floatingProfit: -4.61,
                floatingProfitEOD: 1.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814682",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.53324,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.73,
                floatingProfitEOD: -1.26,
                floatingShortProfit: -9.73,
                floatingShortProfitEOD: -1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810247",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5271399999999999,
            },
        ],
        CHFJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -366.39,
                floatingProfitEOD: -44.87,
                floatingShortProfit: -366.39,
                floatingShortProfitEOD: -44.87,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799360",
                longPositions: 0,
                netPositions: -0.41000000000000003,
                shortPositions: 0.41000000000000003,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 167.79400000000004,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.67,
                closedLongProfitEOD: 0.55,
                closedProfit: 0.67,
                closedProfitEOD: 0.55,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.21,
                floatingLongProfitEOD: -0.21,
                floatingProfit: -62.41,
                floatingProfitEOD: -26.09,
                floatingShortProfit: -62.2,
                floatingShortProfitEOD: -25.88,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.25,
                shortPositions: 0.26,
                symbol: "CHFJPY",
                user_trade_number: 14,
                weightedAvgLongPrice: 169.131,
                weightedAvgShortPrice: 168.77465384615388,
            },
            {
                closedLongPositions: 0.02,
                closedLongProfit: 1.33,
                closedLongProfitEOD: 2.3,
                closedProfit: 1.33,
                closedProfitEOD: 2.3,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.47,
                floatingLongProfitEOD: -0.47,
                floatingProfit: -62.04,
                floatingProfitEOD: -25.86,
                floatingShortProfit: -61.57,
                floatingShortProfitEOD: -25.39,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.01,
                netPositions: -0.25,
                shortPositions: 0.26,
                symbol: "CHFJPY",
                user_trade_number: 15,
                weightedAvgLongPrice: 169.16999999999996,
                weightedAvgShortPrice: 168.7781923076923,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -136.08,
                floatingProfitEOD: -2.19,
                floatingShortProfit: -136.08,
                floatingShortProfitEOD: -2.19,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 158.938,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2298.98,
                floatingLongProfitEOD: 76.25,
                floatingProfit: 1633.66,
                floatingProfitEOD: 54.49,
                floatingShortProfit: -665.32,
                floatingShortProfitEOD: -21.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999994",
                longPositions: 0.7,
                netPositions: 0.49999999999999994,
                shortPositions: 0.2,
                symbol: "CHFJPY",
                user_trade_number: 9,
                weightedAvgLongPrice: 164.1757142857143,
                weightedAvgShortPrice: 164.1485,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: -1.13,
                closedLongProfitEOD: -2.44,
                closedProfit: -1.13,
                closedProfitEOD: -2.44,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814712",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1292.86,
                floatingProfitEOD: -76.16,
                floatingShortProfit: -1292.86,
                floatingShortProfitEOD: -76.16,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808668",
                longPositions: 0,
                netPositions: -0.7,
                shortPositions: 0.7,
                symbol: "CHFJPY",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 166.36557142857146,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.49,
                floatingProfitEOD: -3.27,
                floatingShortProfit: -7.49,
                floatingShortProfitEOD: -3.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "CHFJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 168.75900000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -8.2,
                floatingProfitEOD: -4.35,
                floatingShortProfit: -8.2,
                floatingShortProfitEOD: -4.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 168.826,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.05,
                floatingProfitEOD: -1.09,
                floatingShortProfit: -2.05,
                floatingShortProfitEOD: -1.09,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 168.826,
            },
        ],
        AUDUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2354.94,
                floatingLongProfitEOD: -26.46,
                floatingProfit: 2354.94,
                floatingProfitEOD: -26.46,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808905",
                longPositions: 1.26,
                netPositions: 1.26,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.63258,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -23174.56,
                floatingProfitEOD: 664.75,
                floatingShortProfit: -23174.56,
                floatingShortProfitEOD: 664.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807907",
                longPositions: 0,
                netPositions: -30.479999999999997,
                shortPositions: 30.479999999999997,
                symbol: "AUDUSD",
                user_trade_number: 32,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.6437087040682414,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -18549.14,
                floatingLongProfitEOD: -78.54,
                floatingProfit: -18549.14,
                floatingProfitEOD: -78.54,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800001",
                longPositions: 3.1399999999999997,
                netPositions: 3.1399999999999997,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 14,
                weightedAvgLongPrice: 0.7103035668789809,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -53851.15,
                floatingLongProfitEOD: -276.4,
                floatingProfit: -62943.4,
                floatingProfitEOD: -43.4,
                floatingShortProfit: -9092.25,
                floatingShortProfitEOD: 233,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800002",
                longPositions: 12.649999999999995,
                netPositions: 2.3499999999999943,
                shortPositions: 10.3,
                symbol: "AUDUSD",
                user_trade_number: 83,
                weightedAvgLongPrice: 0.6938315810276683,
                weightedAvgShortPrice: 0.6424763592233009,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.15,
                floatingProfitEOD: 0.22,
                floatingShortProfit: -0.15,
                floatingShortProfitEOD: 0.22,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.65116,
            },
            {
                closedLongPositions: 0.02,
                closedLongProfit: 1.4,
                closedLongProfitEOD: -0.3,
                closedProfit: 1.4,
                closedProfitEOD: -0.3,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813797",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.26,
                floatingProfitEOD: 0.88,
                floatingShortProfit: -4.26,
                floatingShortProfitEOD: 0.88,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814646",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "AUDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.650325,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -125.78,
                floatingProfitEOD: 5.32,
                floatingShortProfit: -125.78,
                floatingShortProfitEOD: 5.32,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809295",
                longPositions: 0,
                netPositions: -0.24000000000000005,
                shortPositions: 0.24000000000000005,
                symbol: "AUDUSD",
                user_trade_number: 12,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.6461374999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1.07,
                floatingLongProfitEOD: -0.42,
                floatingProfit: -47.75,
                floatingProfitEOD: 1.12,
                floatingShortProfit: -48.82,
                floatingShortProfitEOD: 1.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.02,
                netPositions: -0.05,
                shortPositions: 0.07,
                symbol: "AUDUSD",
                user_trade_number: 7,
                weightedAvgLongPrice: 0.650675,
                weightedAvgShortPrice: 0.6444057142857144,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.66,
                floatingProfitEOD: 0.26,
                floatingShortProfit: -0.66,
                floatingShortProfitEOD: 0.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.65069,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.88,
                closedLongProfitEOD: -0.06,
                closedProfit: 0.88,
                closedProfitEOD: -0.06,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -23.77,
                floatingProfitEOD: 0.21,
                floatingShortProfit: -23.77,
                floatingShortProfitEOD: 0.21,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805626",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.62763,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -191.31,
                floatingProfitEOD: 4.94,
                floatingShortProfit: -191.31,
                floatingShortProfitEOD: 4.94,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.22999999999999995,
                shortPositions: 0.22999999999999995,
                symbol: "AUDUSD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.643067391304348,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.57,
                floatingLongProfitEOD: -0.21,
                floatingProfit: 0.57,
                floatingProfitEOD: -0.21,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814807",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.65062,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.63,
                floatingProfitEOD: 0.22,
                floatingShortProfit: -4.63,
                floatingShortProfitEOD: 0.22,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809801",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.64676,
            },
            {
                closedLongPositions: 0.04,
                closedLongProfit: 2.8,
                closedLongProfitEOD: -0.6,
                closedProfit: 2.8,
                closedProfitEOD: -0.6,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814609",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
        ],
        AUDCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -18.42,
                floatingProfitEOD: 0.23,
                floatingShortProfit: -18.42,
                floatingShortProfitEOD: 0.23,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.57312,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -87.24,
                floatingLongProfitEOD: 0.16,
                floatingProfit: -87.24,
                floatingProfitEOD: 0.16,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809483",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.61554,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.57,
                floatingLongProfitEOD: 0.08,
                floatingProfit: -2.57,
                floatingProfitEOD: 0.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808263",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.57918,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -570.41,
                floatingLongProfitEOD: 2.22,
                floatingProfit: -603.33,
                floatingProfitEOD: 2.34,
                floatingShortProfit: -32.92,
                floatingShortProfitEOD: 0.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807494",
                longPositions: 0.28,
                netPositions: 0.26,
                shortPositions: 0.02,
                symbol: "AUDCHF",
                user_trade_number: 6,
                weightedAvgLongPrice: 0.5949464285714284,
                weightedAvgShortPrice: 0.562615,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -46.27,
                floatingProfitEOD: 0.56,
                floatingShortProfit: -46.27,
                floatingShortProfitEOD: 0.56,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808198",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5731,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -17.49,
                floatingLongProfitEOD: 0.4,
                floatingProfit: -17.49,
                floatingProfitEOD: 0.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810680",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.58,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.18,
                floatingLongProfitEOD: 0.08,
                floatingProfit: -2.18,
                floatingProfitEOD: 0.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809760",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.57883,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1046.87,
                floatingLongProfitEOD: 1.9,
                floatingProfit: -1046.87,
                floatingProfitEOD: 1.9,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808280",
                longPositions: 0.24,
                netPositions: 0.24,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.61554,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.4,
                floatingLongProfitEOD: 0.79,
                floatingProfit: -4.4,
                floatingProfitEOD: 0.79,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812920",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.57729,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.81,
                floatingProfitEOD: 0.17,
                floatingShortProfit: -4.81,
                floatingShortProfitEOD: 0.17,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811348",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.57578,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -15.44,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -15.44,
                floatingProfitEOD: 0.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811722",
                longPositions: 0.06,
                netPositions: 0.06,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.57918,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -25.86,
                floatingLongProfitEOD: 0.64,
                floatingProfit: -25.86,
                floatingProfitEOD: 0.64,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810850",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.57976375,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.6,
                floatingProfitEOD: 0.06,
                floatingShortProfit: -4.6,
                floatingShortProfitEOD: 0.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.57312,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -11.06,
                floatingLongProfitEOD: 0.4,
                floatingProfit: -11.06,
                floatingProfitEOD: 0.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807669",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.57886,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -16.19,
                floatingLongProfitEOD: 0.24,
                floatingProfit: -16.19,
                floatingProfitEOD: 0.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810417",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.58168,
                weightedAvgShortPrice: 0,
            },
        ],
        EURCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.62,
                floatingLongProfitEOD: -0.29,
                floatingProfit: -2.62,
                floatingProfitEOD: -0.29,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814325",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.96743,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -186.37,
                floatingProfitEOD: 11.55,
                floatingShortProfit: -186.37,
                floatingShortProfitEOD: 11.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813874",
                longPositions: 0,
                netPositions: -0.42000000000000004,
                shortPositions: 0.42000000000000004,
                symbol: "EURCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9625742857142856,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1062.58,
                floatingProfitEOD: 24.64,
                floatingShortProfit: -1062.58,
                floatingShortProfitEOD: 24.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810483",
                longPositions: 0,
                netPositions: -0.91,
                shortPositions: 0.91,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9561635164835165,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.13,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.13,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813995",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.96725,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -352.55,
                floatingProfitEOD: 8.12,
                floatingShortProfit: -352.55,
                floatingShortProfitEOD: 8.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810482",
                longPositions: 0,
                netPositions: -0.3,
                shortPositions: 0.3,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9560970000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.31,
                floatingLongProfitEOD: -0.15,
                floatingProfit: -1.31,
                floatingProfitEOD: -0.15,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812303",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.96743,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -48.4,
                floatingProfitEOD: 0.81,
                floatingShortProfit: -48.4,
                floatingShortProfitEOD: 0.81,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811293",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "EURCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9522133333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.19,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.19,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811050",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.9673,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -65.18,
                floatingProfitEOD: 1.64,
                floatingShortProfit: -65.18,
                floatingShortProfitEOD: 1.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810800",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9568800000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.59,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.59,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813471",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.9676600000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.31,
                floatingLongProfitEOD: -0.15,
                floatingProfit: -1.31,
                floatingProfitEOD: -0.15,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812382",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.96743,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -27.15,
                floatingProfitEOD: 1.93,
                floatingShortProfit: -27.15,
                floatingShortProfitEOD: 1.93,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814164",
                longPositions: 0,
                netPositions: -0.07,
                shortPositions: 0.07,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9630685714285713,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.59,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.59,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813474",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.9676600000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -27.15,
                floatingProfitEOD: 1.93,
                floatingShortProfit: -27.15,
                floatingShortProfitEOD: 1.93,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814323",
                longPositions: 0,
                netPositions: -0.07,
                shortPositions: 0.07,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9630685714285713,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.59,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.59,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812386",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.9676600000000001,
                weightedAvgShortPrice: 0,
            },
        ],
        DOGUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 13.4,
                floatingLongProfitEOD: 2.13,
                floatingProfit: 13.4,
                floatingProfitEOD: 2.13,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807629",
                longPositions: 30.750000000000004,
                netPositions: 30.750000000000004,
                shortPositions: 0,
                symbol: "DOGUSD",
                user_trade_number: 15,
                weightedAvgLongPrice: 0.07592469268292683,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812792",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "DOGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.08476,
            },
        ],
        EURGBP: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -8.47,
                floatingProfitEOD: -0.06,
                floatingShortProfit: -8.47,
                floatingShortProfitEOD: -0.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812554",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURGBP",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.872405,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.46,
                floatingProfitEOD: -0.02,
                floatingShortProfit: -4.46,
                floatingShortProfitEOD: -0.02,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809024",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURGBP",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8722199999999999,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -34.93,
                floatingProfitEOD: -0.2,
                floatingShortProfit: -34.93,
                floatingShortProfitEOD: -0.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812325",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "EURGBP",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8722975000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.47,
                floatingLongProfitEOD: 0.09,
                floatingProfit: -11.37,
                floatingProfitEOD: 0.05,
                floatingShortProfit: -10.9,
                floatingShortProfitEOD: -0.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809496",
                longPositions: 0.01,
                netPositions: -0.01,
                shortPositions: 0.02,
                symbol: "EURGBP",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.87596,
                weightedAvgShortPrice: 0.871425,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -33.48,
                floatingProfitEOD: -0.12,
                floatingShortProfit: -33.48,
                floatingShortProfitEOD: -0.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808353",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "EURGBP",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8713200000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.42,
                floatingLongProfitEOD: 0.2,
                floatingProfit: 0.42,
                floatingProfitEOD: 0.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813636",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "EURGBP",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8754,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -21.7,
                floatingProfitEOD: -0.08,
                floatingShortProfit: -21.7,
                floatingShortProfitEOD: -0.08,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808172",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "EURGBP",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.871445,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -53.45,
                floatingProfitEOD: -0.32,
                floatingShortProfit: -53.45,
                floatingShortProfitEOD: -0.32,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809295",
                longPositions: 0,
                netPositions: -0.13,
                shortPositions: 0.13,
                symbol: "EURGBP",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8725007692307692,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.45,
                floatingLongProfitEOD: 0.2,
                floatingProfit: 0.45,
                floatingProfitEOD: 0.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802848",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "EURGBP",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.87539,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 4.6,
                floatingLongProfitEOD: 0.26,
                floatingProfit: 4.6,
                floatingProfitEOD: 0.26,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813241",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "EURGBP",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.87435,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.4,
                floatingProfitEOD: -0.12,
                floatingShortProfit: -2.4,
                floatingShortProfitEOD: -0.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "EURGBP",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8751800000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -34.61,
                floatingProfitEOD: -0.22,
                floatingShortProfit: -34.61,
                floatingShortProfitEOD: -0.22,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807946",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "EURGBP",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8723299999999999,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.67,
                floatingProfitEOD: -0.04,
                floatingShortProfit: -4.67,
                floatingShortProfitEOD: -0.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808721",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURGBP",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8739250000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.31,
                floatingLongProfitEOD: 0.09,
                floatingProfit: -24.11,
                floatingProfitEOD: -0.09,
                floatingShortProfit: -23.8,
                floatingShortProfitEOD: -0.18,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808127",
                longPositions: 0.01,
                netPositions: -0.049999999999999996,
                shortPositions: 0.06,
                symbol: "EURGBP",
                user_trade_number: 6,
                weightedAvgLongPrice: 0.87583,
                weightedAvgShortPrice: 0.8726216666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -18.69,
                closedProfitEOD: -7.79,
                closedShortPositions: 0.05,
                closedShortProfit: -18.69,
                closedShortProfitEOD: -7.79,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1295.53,
                floatingProfitEOD: -3.29,
                floatingShortProfit: -1295.53,
                floatingShortProfitEOD: -3.29,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802384",
                longPositions: 0,
                netPositions: -1.23,
                shortPositions: 1.23,
                symbol: "EURGBP",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8673563414634148,
            },
        ],
        EURNZD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.38,
                floatingProfitEOD: 2.8,
                floatingShortProfit: -1.38,
                floatingShortProfitEOD: 2.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812554",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.8178400000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.02,
                floatingProfitEOD: 4.22,
                floatingShortProfit: -5.02,
                floatingShortProfitEOD: 4.22,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814635",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "EURNZD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.8161933333333333,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -30.36,
                floatingLongProfitEOD: -28.96,
                floatingProfit: -122.49,
                floatingProfitEOD: 0.66,
                floatingShortProfit: -92.13,
                floatingShortProfitEOD: 29.62,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812526",
                longPositions: 0.21000000000000002,
                netPositions: 2.7755575615628914e-17,
                shortPositions: 0.21,
                symbol: "EURNZD",
                user_trade_number: 8,
                weightedAvgLongPrice: 1.8210014285714287,
                weightedAvgShortPrice: 1.8116642857142857,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0.96,
                closedProfitEOD: 2.17,
                closedShortPositions: 0.02,
                closedShortProfit: 0.96,
                closedShortProfitEOD: 2.17,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -93.15,
                floatingProfitEOD: 18.12,
                floatingShortProfit: -93.15,
                floatingShortProfitEOD: 18.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.13000000000000003,
                shortPositions: 0.13000000000000003,
                symbol: "EURNZD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.807060769230769,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.06,
                floatingLongProfitEOD: -1.38,
                floatingProfit: -40.65,
                floatingProfitEOD: 11.15,
                floatingShortProfit: -38.59,
                floatingShortProfitEOD: 12.53,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.01,
                netPositions: -0.08,
                shortPositions: 0.09,
                symbol: "EURNZD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.8220199999999998,
                weightedAvgShortPrice: 1.8118600000000005,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -117.07,
                floatingProfitEOD: 1.34,
                floatingShortProfit: -117.07,
                floatingShortProfitEOD: 1.34,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803780",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6239400000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.09,
                floatingLongProfitEOD: -1.38,
                floatingProfit: -38.9,
                floatingProfitEOD: 11.14,
                floatingShortProfit: -36.81,
                floatingShortProfitEOD: 12.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814055",
                longPositions: 0.01,
                netPositions: -0.08,
                shortPositions: 0.09,
                symbol: "EURNZD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.8220700000000003,
                weightedAvgShortPrice: 1.8121944444444447,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -674.63,
                floatingProfitEOD: 40.57,
                floatingShortProfit: -674.63,
                floatingShortProfitEOD: 40.57,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812570",
                longPositions: 0,
                netPositions: -0.29000000000000004,
                shortPositions: 0.29000000000000004,
                symbol: "EURNZD",
                user_trade_number: 12,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7802127586206895,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.48,
                floatingProfitEOD: 0.18,
                floatingShortProfit: -0.48,
                floatingShortProfitEOD: 0.18,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814845",
                longPositions: 0,
                netPositions: -0.14,
                shortPositions: 0.14,
                symbol: "EURNZD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.8131928571428568,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -14.07,
                floatingProfitEOD: 16.87,
                floatingShortProfit: -14.07,
                floatingShortProfitEOD: 16.87,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "EURNZD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.8170308333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -9.45,
                floatingLongProfitEOD: -7.11,
                floatingProfit: -10.54,
                floatingProfitEOD: 36.39,
                floatingShortProfit: -1.09,
                floatingShortProfitEOD: 43.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.08,
                netPositions: -0.23000000000000004,
                shortPositions: 0.31000000000000005,
                symbol: "EURNZD",
                user_trade_number: 19,
                weightedAvgLongPrice: 1.8205612500000001,
                weightedAvgShortPrice: 1.8189322580645158,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.55,
                floatingLongProfitEOD: -1.38,
                floatingProfit: -40.54,
                floatingProfitEOD: 11.15,
                floatingShortProfit: -37.99,
                floatingShortProfitEOD: 12.53,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799426",
                longPositions: 0.01,
                netPositions: -0.08,
                shortPositions: 0.09,
                symbol: "EURNZD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.82285,
                weightedAvgShortPrice: 1.811971111111111,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1900.57,
                floatingLongProfitEOD: -110.63,
                floatingProfit: 1900.57,
                floatingProfitEOD: -110.63,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999999",
                longPositions: 0.8200000000000001,
                netPositions: 0.8200000000000001,
                shortPositions: 0,
                symbol: "EURNZD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.7799660975609755,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.04,
                floatingLongProfitEOD: -1.38,
                floatingProfit: -32.8,
                floatingProfitEOD: 8.38,
                floatingShortProfit: -30.76,
                floatingShortProfitEOD: 9.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799446",
                longPositions: 0.01,
                netPositions: -0.060000000000000005,
                shortPositions: 0.07,
                symbol: "EURNZD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.822,
                weightedAvgShortPrice: 1.8116814285714284,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -62.52,
                floatingProfitEOD: 23.83,
                floatingShortProfit: -62.52,
                floatingShortProfitEOD: 23.83,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799452",
                longPositions: 0,
                netPositions: -0.17000000000000004,
                shortPositions: 0.17000000000000004,
                symbol: "EURNZD",
                user_trade_number: 13,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.812861764705882,
            },
        ],
        AUDCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -18.89,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -18.89,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813499",
                longPositions: 0,
                netPositions: -0.07999999999999999,
                shortPositions: 0.07999999999999999,
                symbol: "AUDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8903775,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0.07,
                floatingProfitEOD: 0.04,
                floatingShortProfit: 0.07,
                floatingShortProfitEOD: 0.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8937,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -25.85,
                floatingProfitEOD: 0.73,
                floatingShortProfit: -25.85,
                floatingShortProfitEOD: 0.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809295",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8906883333333333,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -73.39,
                floatingProfitEOD: 0.91,
                floatingShortProfit: -73.39,
                floatingShortProfitEOD: 0.91,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "AUDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8869346666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -25.75,
                floatingProfitEOD: 0.66,
                floatingShortProfit: -25.75,
                floatingShortProfitEOD: 0.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808556",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8907083333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -34.7,
                floatingProfitEOD: 0.4,
                floatingShortProfit: -34.7,
                floatingShortProfitEOD: 0.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812408",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "AUDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8876400000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -61.21,
                floatingProfitEOD: 1.47,
                floatingShortProfit: -61.21,
                floatingShortProfitEOD: 1.47,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808037",
                longPositions: 0,
                netPositions: -0.27,
                shortPositions: 0.27,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8905448148148147,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -25.63,
                floatingProfitEOD: 0.73,
                floatingShortProfit: -25.63,
                floatingShortProfitEOD: 0.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808532",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8907141666666665,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -87.3,
                floatingProfitEOD: 1.36,
                floatingShortProfit: -87.3,
                floatingShortProfitEOD: 1.36,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813983",
                longPositions: 0,
                netPositions: -0.30000000000000004,
                shortPositions: 0.30000000000000004,
                symbol: "AUDCAD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8896749999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.22,
                floatingProfitEOD: 0.08,
                floatingShortProfit: -5.22,
                floatingShortProfitEOD: 0.08,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812554",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "AUDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8900950000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -29.45,
                floatingProfitEOD: 0.62,
                floatingShortProfit: -29.45,
                floatingShortProfitEOD: 0.62,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812533",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "AUDCAD",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8885700000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -45.33,
                floatingProfitEOD: 0.48,
                floatingShortProfit: -45.33,
                floatingShortProfitEOD: 0.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814635",
                longPositions: 0,
                netPositions: -0.09999999999999998,
                shortPositions: 0.09999999999999998,
                symbol: "AUDCAD",
                user_trade_number: 8,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8874440000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -25.65,
                floatingProfitEOD: 0.73,
                floatingShortProfit: -25.65,
                floatingShortProfitEOD: 0.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808353",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8907108333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -13.06,
                floatingProfitEOD: 0.32,
                floatingShortProfit: -13.06,
                floatingShortProfitEOD: 0.32,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812810",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "AUDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.89006,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.46,
                floatingProfitEOD: 0.11,
                floatingShortProfit: -4.46,
                floatingShortProfitEOD: 0.11,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809061",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "AUDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.890525,
            },
        ],
        CADCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -24.33,
                floatingLongProfitEOD: 0.16,
                floatingProfit: -24.33,
                floatingProfitEOD: 0.16,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8806329",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.656415,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -760.26,
                floatingLongProfitEOD: 0.76,
                floatingProfit: -760.26,
                floatingProfitEOD: 0.76,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803208",
                longPositions: 0.09000000000000001,
                netPositions: 0.09000000000000001,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0.7205544444444444,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.1,
                closedLongProfit: -65.36,
                closedLongProfitEOD: -30.99,
                closedProfit: -65.36,
                closedProfitEOD: -30.99,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808215",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -77.18,
                floatingLongProfitEOD: 0.08,
                floatingProfit: -77.18,
                floatingProfitEOD: 0.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803780",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.71401,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -684.77,
                floatingLongProfitEOD: 0.77,
                floatingProfit: -684.77,
                floatingProfitEOD: 0.77,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803004",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0.7215600000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -684.87,
                floatingLongProfitEOD: 0.68,
                floatingProfit: -684.87,
                floatingProfitEOD: 0.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803013",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0.7215612499999999,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -30.52,
                floatingLongProfitEOD: 0.16,
                floatingProfit: -30.52,
                floatingProfitEOD: 0.16,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799363",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.65918,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -485.97,
                floatingLongProfitEOD: 3.95,
                floatingProfit: -485.97,
                floatingProfitEOD: 3.95,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799351",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.65427,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -115.08,
                floatingLongProfitEOD: 1.58,
                floatingProfit: -115.08,
                floatingProfitEOD: 1.58,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.18,
                netPositions: 0.18,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 10,
                weightedAvgLongPrice: 0.6513311111111111,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -126.88,
                floatingLongProfitEOD: 1.9,
                floatingProfit: -126.88,
                floatingProfitEOD: 1.9,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.21999999999999997,
                netPositions: 0.21999999999999997,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 11,
                weightedAvgLongPrice: 0.6507754545454545,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4030.02,
                floatingLongProfitEOD: 47.42,
                floatingProfit: -4030.02,
                floatingProfitEOD: 47.42,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799711",
                longPositions: 6,
                netPositions: 6,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.65159,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -218.99,
                floatingLongProfitEOD: 3.95,
                floatingProfit: -218.99,
                floatingProfitEOD: 3.95,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799831",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.64952,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.73,
                floatingLongProfitEOD: 0.08,
                floatingProfit: -0.73,
                floatingProfitEOD: 0.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808263",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.64629,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.58,
                floatingProfitEOD: -0.06,
                floatingShortProfit: -0.58,
                floatingShortProfitEOD: -0.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810043",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.64544,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -338.36,
                floatingLongProfitEOD: 1.26,
                floatingProfit: -338.36,
                floatingProfitEOD: 1.26,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807494",
                longPositions: 0.16,
                netPositions: 0.16,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.6643737499999998,
                weightedAvgShortPrice: 0,
            },
        ],
        USDCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -6.42,
                floatingLongProfitEOD: 0.72,
                floatingProfit: -7,
                floatingProfitEOD: 0.57,
                floatingShortProfit: -0.58,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814732",
                longPositions: 0.03,
                netPositions: 0.019999999999999997,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1.3747633333333336,
                weightedAvgShortPrice: 1.37122,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 1.84,
                floatingProfitEOD: -0.91,
                floatingShortProfit: 1.84,
                floatingShortProfitEOD: -0.91,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812533",
                longPositions: 0,
                netPositions: -0.06000000000000001,
                shortPositions: 0.06000000000000001,
                symbol: "USDCAD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.3724416666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.03,
                floatingProfitEOD: -0.45,
                floatingShortProfit: -5.03,
                floatingShortProfitEOD: -0.45,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809024",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "USDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.369716666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.23,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.84,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.61,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812325",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741716666666666,
                weightedAvgShortPrice: 1.37118,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.9,
                floatingLongProfitEOD: 0.72,
                floatingProfit: -6.5,
                floatingProfitEOD: 0.57,
                floatingShortProfit: -0.6,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809496",
                longPositions: 0.03,
                netPositions: 0.019999999999999997,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1.374526666666667,
                weightedAvgShortPrice: 1.3712,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.21,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.79,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.58,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809295",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741649999999999,
                weightedAvgShortPrice: 1.37122,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.08,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.65,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.57,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808172",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741349999999997,
                weightedAvgShortPrice: 1.37124,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.43,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -9.37,
                floatingProfitEOD: 0.03,
                floatingShortProfit: -4.94,
                floatingShortProfitEOD: -0.45,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.02,
                netPositions: -0.009999999999999998,
                shortPositions: 0.03,
                symbol: "USDCAD",
                user_trade_number: 5,
                weightedAvgLongPrice: 1.3748699999999998,
                weightedAvgShortPrice: 1.3697633333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.72,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -4.57,
                floatingProfitEOD: 0.33,
                floatingShortProfit: 0.15,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814055",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.375065,
                weightedAvgShortPrice: 1.37223,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -462.89,
                floatingProfitEOD: -4.6,
                floatingShortProfit: -462.89,
                floatingShortProfitEOD: -4.6,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807818",
                longPositions: 0,
                netPositions: -0.3,
                shortPositions: 0.3,
                symbol: "USDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.3508499999999999,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.18,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.78,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.6,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807946",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741583333333331,
                weightedAvgShortPrice: 1.37119,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.13,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.7,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.57,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808127",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741449999999997,
                weightedAvgShortPrice: 1.37124,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.1,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.7,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.6,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808556",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.374138333333333,
                weightedAvgShortPrice: 1.3712,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.13,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.7,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.57,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812914",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741466666666666,
                weightedAvgShortPrice: 1.37124,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.13,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.7,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.57,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807991",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.374145,
                weightedAvgShortPrice: 1.37124,
            },
        ],
        GBPUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -77.88,
                floatingProfitEOD: 6.4,
                floatingShortProfit: -77.88,
                floatingShortProfitEOD: 6.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813499",
                longPositions: 0,
                netPositions: -0.09999999999999999,
                shortPositions: 0.09999999999999999,
                symbol: "GBPUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.2379820000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -65.43,
                floatingProfitEOD: 1.92,
                floatingShortProfit: -65.43,
                floatingShortProfitEOD: 1.92,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807393",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.22397,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 278.4,
                floatingLongProfitEOD: -73.2,
                floatingProfit: -2702.72,
                floatingProfitEOD: 62.23,
                floatingShortProfit: -2981.12,
                floatingShortProfitEOD: 135.43,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808384",
                longPositions: 1.2,
                netPositions: -0.9100000000000008,
                shortPositions: 2.1100000000000008,
                symbol: "GBPUSD",
                user_trade_number: 25,
                weightedAvgLongPrice: 1.24323,
                weightedAvgShortPrice: 1.23164962085308,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.06,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -0.06,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.24564,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.08,
                floatingLongProfitEOD: -0.61,
                floatingProfit: -3.08,
                floatingProfitEOD: -0.61,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814027",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.24865,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1.5,
                floatingLongProfitEOD: -91.5,
                floatingProfit: -1638.02,
                floatingProfitEOD: -18.22,
                floatingShortProfit: -1639.52,
                floatingShortProfitEOD: 73.28,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808385",
                longPositions: 1.5,
                netPositions: 0.3599999999999999,
                shortPositions: 1.1400000000000001,
                symbol: "GBPUSD",
                user_trade_number: 23,
                weightedAvgLongPrice: 1.24554,
                weightedAvgShortPrice: 1.2313954385964914,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.29,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -4.29,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814888",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.24148,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.08,
                floatingLongProfitEOD: -2.44,
                floatingProfit: -2.08,
                floatingProfitEOD: -2.44,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814569",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.24609,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4250,
                floatingProfitEOD: 640,
                floatingShortProfit: -4250,
                floatingShortProfitEOD: 640,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814865",
                longPositions: 0,
                netPositions: -10,
                shortPositions: 10,
                symbol: "GBPUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.24145,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.8,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -2.8,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807194",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.2429,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.5,
                floatingLongProfitEOD: -1.83,
                floatingProfit: -108.76,
                floatingProfitEOD: 5.28,
                floatingShortProfit: -107.26,
                floatingShortProfitEOD: 7.11,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.03,
                netPositions: -0.08,
                shortPositions: 0.11,
                symbol: "GBPUSD",
                user_trade_number: 10,
                weightedAvgLongPrice: 1.24607,
                weightedAvgShortPrice: 1.2360127272727273,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -31.35,
                floatingProfitEOD: 3.84,
                floatingShortProfit: -31.35,
                floatingShortProfitEOD: 3.84,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810200",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.240545,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.96,
                floatingProfitEOD: 1.92,
                floatingShortProfit: -3.96,
                floatingShortProfitEOD: 1.92,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.2444600000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1391.88,
                floatingLongProfitEOD: -12.69,
                floatingProfit: -2913.68,
                floatingProfitEOD: 15.56,
                floatingShortProfit: -1521.8,
                floatingShortProfitEOD: 28.25,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802655",
                longPositions: 0.21000000000000005,
                netPositions: -0.2500000000000002,
                shortPositions: 0.46000000000000024,
                symbol: "GBPUSD",
                user_trade_number: 67,
                weightedAvgLongPrice: 1.311835714285714,
                weightedAvgShortPrice: 1.2126963043478254,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.71,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -7.71,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805626",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.23807,
            },
        ],
        GBPCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.58,
                floatingProfitEOD: -0.04,
                floatingShortProfit: -1.58,
                floatingShortProfitEOD: -0.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813499",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "GBPCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.708045,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.75,
                floatingProfitEOD: 0.84,
                floatingShortProfit: -3.75,
                floatingShortProfitEOD: 0.84,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814635",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "GBPCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7065549999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.78,
                floatingLongProfitEOD: -1,
                floatingProfit: -1.97,
                floatingProfitEOD: -0.58,
                floatingShortProfit: -1.19,
                floatingShortProfitEOD: 0.42,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813603",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.70917,
                weightedAvgShortPrice: 1.7075,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.82,
                floatingLongProfitEOD: -0.5,
                floatingProfit: -7.3,
                floatingProfitEOD: 0.34,
                floatingShortProfit: -6.48,
                floatingShortProfitEOD: 0.84,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.01,
                netPositions: -0.01,
                shortPositions: 0.02,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.70977,
                weightedAvgShortPrice: 1.70468,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.22,
                floatingProfitEOD: 0.42,
                floatingShortProfit: -1.22,
                floatingShortProfitEOD: 0.42,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812570",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7074599999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.81,
                floatingProfitEOD: 2.55,
                floatingShortProfit: -9.81,
                floatingShortProfitEOD: 2.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811401",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7068866666666669,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.66,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.66,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810200",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7065433333333333,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.6,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.6,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810893",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.70657,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.12,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.12,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812497",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7067900000000003,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -64.52,
                floatingProfitEOD: 4.2,
                floatingShortProfit: -64.52,
                floatingShortProfitEOD: 4.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810858",
                longPositions: 0,
                netPositions: -0.09999999999999999,
                shortPositions: 0.09999999999999999,
                symbol: "GBPCAD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7002780000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -279.97,
                floatingProfitEOD: 9.31,
                floatingShortProfit: -279.97,
                floatingShortProfitEOD: 9.31,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814194",
                longPositions: 0,
                netPositions: -0.21999999999999997,
                shortPositions: 0.21999999999999997,
                symbol: "GBPCAD",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6916699999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -23.55,
                floatingProfitEOD: 2.52,
                floatingShortProfit: -23.55,
                floatingShortProfitEOD: 2.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809854",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7037449999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.56,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -4.56,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808037",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7070433333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.68,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.68,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809951",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7065333333333337,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.59,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.59,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7065733333333337,
            },
        ],
        WTI02: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7023,
                floatingProfitEOD: -6333,
                floatingShortProfit: -7023,
                floatingShortProfitEOD: -6333,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808384",
                longPositions: 0,
                netPositions: -43,
                shortPositions: 43,
                symbol: "WTI02",
                user_trade_number: 17,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 76.1719534883721,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5738,
                floatingLongProfitEOD: 1152,
                floatingProfit: -438.86,
                floatingProfitEOD: -3686.48,
                floatingShortProfit: 5299.14,
                floatingShortProfitEOD: -4838.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808385",
                longPositions: 6,
                netPositions: -27.009999999999998,
                shortPositions: 33.01,
                symbol: "WTI02",
                user_trade_number: 23,
                weightedAvgLongPrice: 77.24033333333334,
                weightedAvgShortPrice: 76.49510754316874,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2328,
                floatingLongProfitEOD: 193,
                floatingProfit: 2328,
                floatingProfitEOD: 193,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999110",
                longPositions: 1,
                netPositions: 1,
                shortPositions: 0,
                symbol: "WTI02",
                user_trade_number: 1,
                weightedAvgLongPrice: 73.957,
                weightedAvgShortPrice: 0,
            },
        ],
        HK50: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 80.59,
                floatingLongProfitEOD: 0,
                floatingProfit: 170.65,
                floatingProfitEOD: 0,
                floatingShortProfit: 90.06,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8804559",
                longPositions: 2,
                netPositions: 1,
                shortPositions: 1,
                symbol: "HK50",
                user_trade_number: 3,
                weightedAvgLongPrice: 17331.324999999997,
                weightedAvgShortPrice: 18360.11,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -185.03,
                floatingLongProfitEOD: 0,
                floatingProfit: -185.03,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808010",
                longPositions: 3,
                netPositions: 3,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 3,
                weightedAvgLongPrice: 18126.246666666666,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 252.37,
                floatingLongProfitEOD: 0,
                floatingProfit: 252.37,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808397",
                longPositions: 4,
                netPositions: 4,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 2,
                weightedAvgLongPrice: 17153.655,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 887.58,
                floatingLongProfitEOD: 0,
                floatingProfit: 887.58,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802346",
                longPositions: 10,
                netPositions: 10,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 1,
                weightedAvgLongPrice: 16953.58,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -7528.31,
                floatingLongProfitEOD: 0,
                floatingProfit: -7446.87,
                floatingProfitEOD: 0,
                floatingShortProfit: 81.44,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807843",
                longPositions: 40,
                netPositions: 32,
                shortPositions: 8,
                symbol: "HK50",
                user_trade_number: 10,
                weightedAvgLongPrice: 19112.6,
                weightedAvgShortPrice: 17737.263750000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 62.68,
                floatingLongProfitEOD: 0,
                floatingProfit: 62.68,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807267",
                longPositions: 2,
                netPositions: 2,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 2,
                weightedAvgLongPrice: 17401.190000000002,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -876.88,
                floatingLongProfitEOD: 0,
                floatingProfit: -876.88,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802111",
                longPositions: 3,
                netPositions: 3,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 3,
                weightedAvgLongPrice: 19923.953333333335,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 264.82,
                floatingLongProfitEOD: 0,
                floatingProfit: 264.82,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT5:999999",
                longPositions: 4,
                netPositions: 4,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 1,
                weightedAvgLongPrice: 17129.39,
                weightedAvgShortPrice: 0,
            },
        ],
        TSLA: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.61,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.61,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813904",
                longPositions: 3,
                netPositions: 3,
                shortPositions: 0,
                symbol: "TSLA",
                user_trade_number: 1,
                weightedAvgLongPrice: 235.18999999999997,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 12.41,
                floatingProfitEOD: 0,
                floatingShortProfit: 12.41,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999825",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "TSLA",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 246.81,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -49.02,
                floatingLongProfitEOD: 0,
                floatingProfit: -49.02,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 3,
                netPositions: 3,
                shortPositions: 0,
                symbol: "TSLA",
                user_trade_number: 1,
                weightedAvgLongPrice: 250.66,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -171.1,
                floatingProfitEOD: 0,
                floatingShortProfit: -171.1,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812768",
                longPositions: 0,
                netPositions: -5,
                shortPositions: 5,
                symbol: "TSLA",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 200.18,
            },
        ],
        AUDNZD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 27.25,
                floatingLongProfitEOD: -4.51,
                floatingProfit: 27.25,
                floatingProfitEOD: -4.51,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799817",
                longPositions: 0.07,
                netPositions: 0.07,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.0794214285714285,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -8.25,
                floatingProfitEOD: 38.53,
                floatingShortProfit: -8.25,
                floatingShortProfitEOD: 38.53,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799429",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "AUDNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08574,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2,
                floatingLongProfitEOD: -1.48,
                floatingProfit: -20.26,
                floatingProfitEOD: 25.32,
                floatingShortProfit: -18.26,
                floatingShortProfitEOD: 26.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.03,
                netPositions: -0.32999999999999996,
                shortPositions: 0.36,
                symbol: "AUDNZD",
                user_trade_number: 17,
                weightedAvgLongPrice: 1.0870433333333331,
                weightedAvgShortPrice: 1.085283888888889,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 3.66,
                floatingProfitEOD: 7.8,
                floatingShortProfit: 3.66,
                floatingShortProfitEOD: 7.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799439",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08668,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2,
                floatingLongProfitEOD: -1.48,
                floatingProfit: -18.81,
                floatingProfitEOD: 25.37,
                floatingShortProfit: -16.81,
                floatingShortProfitEOD: 26.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.03,
                netPositions: -0.32999999999999996,
                shortPositions: 0.36,
                symbol: "AUDNZD",
                user_trade_number: 17,
                weightedAvgLongPrice: 1.0870433333333331,
                weightedAvgShortPrice: 1.0853483333333336,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -181.44,
                floatingProfitEOD: 36.29,
                floatingShortProfit: -181.44,
                floatingShortProfitEOD: 36.29,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799812",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08012,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 1.8,
                floatingProfitEOD: 3.9,
                floatingShortProfit: 1.8,
                floatingShortProfitEOD: 3.9,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799438",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.86,
                floatingLongProfitEOD: -0.62,
                floatingProfit: -0.86,
                floatingProfitEOD: -0.62,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799876",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.08737,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -30.58,
                floatingLongProfitEOD: -19.61,
                floatingProfit: -30.58,
                floatingProfitEOD: -19.61,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999545",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.08766,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 17.6,
                floatingLongProfitEOD: -3.21,
                floatingProfit: 17.6,
                floatingProfitEOD: -3.21,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.08005,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -31.2,
                floatingProfitEOD: 4.36,
                floatingShortProfit: -31.2,
                floatingShortProfitEOD: 4.36,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809033",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "AUDNZD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0775033333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 3.38,
                closedProfitEOD: 2.5,
                closedShortPositions: 0.04,
                closedShortProfit: 3.38,
                closedShortProfitEOD: 2.5,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.57,
                floatingLongProfitEOD: -4.94,
                floatingProfit: -2.57,
                floatingProfitEOD: -4.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812247",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.0864775,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -24.17,
                floatingProfitEOD: 7.26,
                floatingShortProfit: -24.17,
                floatingShortProfitEOD: 7.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808198",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08214,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -9.95,
                floatingLongProfitEOD: -6.18,
                floatingProfit: -9.95,
                floatingProfitEOD: -6.18,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812920",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.0876,
                weightedAvgShortPrice: 0,
            },
        ],
        EURCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -199.02,
                floatingProfitEOD: 2.47,
                floatingShortProfit: -199.02,
                floatingShortProfitEOD: 2.47,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799363",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "EURCAD",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.4624637500000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.01,
                floatingLongProfitEOD: -0.37,
                floatingProfit: -4733.36,
                floatingProfitEOD: 356.49,
                floatingShortProfit: -4733.35,
                floatingShortProfitEOD: 356.86,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799372",
                longPositions: 0.01,
                netPositions: -11.390000000000002,
                shortPositions: 11.400000000000002,
                symbol: "EURCAD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.49627,
                weightedAvgShortPrice: 1.490893745614035,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.19,
                floatingLongProfitEOD: -0.37,
                floatingProfit: -40.99,
                floatingProfitEOD: 6.48,
                floatingShortProfit: -41.18,
                floatingShortProfitEOD: 6.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.21000000000000002,
                shortPositions: 0.22000000000000003,
                symbol: "EURCAD",
                user_trade_number: 12,
                weightedAvgLongPrice: 1.49599,
                weightedAvgShortPrice: 1.4940250000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.19,
                floatingLongProfitEOD: -0.37,
                floatingProfit: -41.11,
                floatingProfitEOD: 6.48,
                floatingShortProfit: -41.3,
                floatingShortProfitEOD: 6.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.01,
                netPositions: -0.21000000000000002,
                shortPositions: 0.22000000000000003,
                symbol: "EURCAD",
                user_trade_number: 12,
                weightedAvgLongPrice: 1.49599,
                weightedAvgShortPrice: 1.4940177272727273,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2846.88,
                floatingProfitEOD: 80.9,
                floatingShortProfit: -2846.88,
                floatingShortProfitEOD: 80.9,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799711",
                longPositions: 0,
                netPositions: -3,
                shortPositions: 3,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.48364,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -162.9,
                floatingProfitEOD: 13.48,
                floatingShortProfit: -162.9,
                floatingShortProfitEOD: 13.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799831",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.49219,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1384.85,
                floatingProfitEOD: 53.25,
                floatingShortProfit: -1384.85,
                floatingShortProfitEOD: 53.25,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799488",
                longPositions: 0,
                netPositions: -1.7100000000000009,
                shortPositions: 1.7100000000000009,
                symbol: "EURCAD",
                user_trade_number: 21,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.485481228070175,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -78.15,
                floatingProfitEOD: 6.51,
                floatingShortProfit: -78.15,
                floatingShortProfitEOD: 6.51,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799312",
                longPositions: 0,
                netPositions: -0.21,
                shortPositions: 0.21,
                symbol: "EURCAD",
                user_trade_number: 12,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.4914919047619044,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 811.77,
                floatingLongProfitEOD: -11.15,
                floatingProfit: 811.77,
                floatingProfitEOD: -11.15,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.45913,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -189.33,
                floatingProfitEOD: 6.21,
                floatingShortProfit: -189.33,
                floatingShortProfitEOD: 6.21,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: -0.23,
                shortPositions: 0.23,
                symbol: "EURCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.485365652173913,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -216.16,
                floatingProfitEOD: 15.11,
                floatingShortProfit: -216.16,
                floatingShortProfitEOD: 15.11,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813697",
                longPositions: 0,
                netPositions: -0.56,
                shortPositions: 0.56,
                symbol: "EURCAD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.4913639285714284,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 14.43,
                floatingLongProfitEOD: -4.92,
                floatingProfit: 14.43,
                floatingProfitEOD: -4.92,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810483",
                longPositions: 0.15,
                netPositions: 0.15,
                shortPositions: 0,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.49497,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -475.21,
                floatingProfitEOD: 13.48,
                floatingShortProfit: -475.21,
                floatingShortProfitEOD: 13.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807296",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.48362,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -80.45,
                floatingProfitEOD: 4.59,
                floatingShortProfit: -80.45,
                floatingShortProfitEOD: 4.59,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813471",
                longPositions: 0,
                netPositions: -0.17,
                shortPositions: 0.17,
                symbol: "EURCAD",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.4901664705882351,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.89,
                floatingProfitEOD: 0.54,
                floatingShortProfit: -5.89,
                floatingShortProfitEOD: 0.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812283",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.49262,
            },
        ],
        GBPCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.09,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -14.58,
                floatingProfitEOD: 0.87,
                floatingShortProfit: -14.49,
                floatingShortProfitEOD: 1.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0.01,
                netPositions: -0.03,
                shortPositions: 0.04,
                symbol: "GBPCHF",
                user_trade_number: 5,
                weightedAvgLongPrice: 1.10348,
                weightedAvgShortPrice: 1.10056,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.1,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -27.02,
                floatingProfitEOD: 3.31,
                floatingShortProfit: -25.92,
                floatingShortProfitEOD: 3.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.11,
                shortPositions: 0.12,
                symbol: "GBPCHF",
                user_trade_number: 9,
                weightedAvgLongPrice: 1.10437,
                weightedAvgShortPrice: 1.1018566666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.11,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -27.01,
                floatingProfitEOD: 3.31,
                floatingShortProfit: -25.9,
                floatingShortProfitEOD: 3.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.01,
                netPositions: -0.11,
                shortPositions: 0.12,
                symbol: "GBPCHF",
                user_trade_number: 9,
                weightedAvgLongPrice: 1.10438,
                weightedAvgShortPrice: 1.1018575000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -304.8,
                floatingLongProfitEOD: -16.37,
                floatingProfit: -304.8,
                floatingProfitEOD: -16.37,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799812",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10878,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.15,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -1,
                floatingProfitEOD: -0.03,
                floatingShortProfit: -0.85,
                floatingShortProfitEOD: 0.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799515",
                longPositions: 0.01,
                netPositions: 0,
                shortPositions: 0.01,
                symbol: "GBPCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.10353,
                weightedAvgShortPrice: 1.10302,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -6.96,
                floatingLongProfitEOD: -0.66,
                floatingProfit: -6.96,
                floatingProfitEOD: -0.66,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799457",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.10648,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -73.66,
                floatingLongProfitEOD: -1.64,
                floatingProfit: -73.66,
                floatingProfitEOD: -1.64,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.11645,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.2,
                floatingLongProfitEOD: -1.31,
                floatingProfit: -4.2,
                floatingProfitEOD: -1.31,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10431,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.08,
                floatingLongProfitEOD: -0.33,
                floatingProfit: 0.08,
                floatingProfitEOD: -0.33,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813995",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10331,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.05,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -0.05,
                floatingProfitEOD: -0.33,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812103",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10342,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.14,
                floatingLongProfitEOD: -0.98,
                floatingProfit: -0.14,
                floatingProfitEOD: -0.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813137",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10342,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -161.44,
                floatingProfitEOD: 10.66,
                floatingShortProfit: -161.44,
                floatingShortProfitEOD: 10.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813698",
                longPositions: 0,
                netPositions: -0.35,
                shortPositions: 0.35,
                symbol: "GBPCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0996928571428575,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.14,
                floatingLongProfitEOD: -0.98,
                floatingProfit: -0.14,
                floatingProfitEOD: -0.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812247",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10342,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.18,
                floatingLongProfitEOD: -1.31,
                floatingProfit: -0.18,
                floatingProfitEOD: -1.31,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811326",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10342,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.04,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -1.04,
                floatingProfitEOD: -0.33,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.1043,
                weightedAvgShortPrice: 0,
            },
        ],
        USDCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.88,
                floatingLongProfitEOD: 0.6,
                floatingProfit: -6.99,
                floatingProfitEOD: 0.33,
                floatingShortProfit: -1.11,
                floatingShortProfitEOD: -0.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799361",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "USDCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.8884700000000001,
                weightedAvgShortPrice: 0.88509,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -832.34,
                floatingLongProfitEOD: 63.8,
                floatingProfit: -832.84,
                floatingProfitEOD: 63.53,
                floatingShortProfit: -0.5,
                floatingShortProfitEOD: -0.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 2.1400000000000006,
                netPositions: 2.130000000000001,
                shortPositions: 0.01,
                symbol: "USDCHF",
                user_trade_number: 27,
                weightedAvgLongPrice: 0.8893098130841118,
                weightedAvgShortPrice: 0.88563,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -833.84,
                floatingLongProfitEOD: 63.8,
                floatingProfit: -834.34,
                floatingProfitEOD: 63.53,
                floatingShortProfit: -0.5,
                floatingShortProfitEOD: -0.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 2.1400000000000006,
                netPositions: 2.130000000000001,
                shortPositions: 0.01,
                symbol: "USDCHF",
                user_trade_number: 27,
                weightedAvgLongPrice: 0.8893160280373829,
                weightedAvgShortPrice: 0.88563,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -167.64,
                floatingLongProfitEOD: 15.24,
                floatingProfit: -167.64,
                floatingProfitEOD: 15.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799831",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.88882,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 5700.61,
                floatingLongProfitEOD: 65.47,
                floatingProfit: 5700.61,
                floatingProfitEOD: 65.47,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799952",
                longPositions: 2,
                netPositions: 2,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.86062,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -316.08,
                floatingLongProfitEOD: 34.14,
                floatingProfit: -316.08,
                floatingProfitEOD: 34.14,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799665",
                longPositions: 1.12,
                netPositions: 1.12,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.88835,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1373.09,
                floatingLongProfitEOD: 3.63,
                floatingProfit: -1041.77,
                floatingProfitEOD: 2.82,
                floatingShortProfit: 331.32,
                floatingShortProfitEOD: -0.81,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999698",
                longPositions: 0.11999999999999998,
                netPositions: 0.08999999999999998,
                shortPositions: 0.03,
                symbol: "USDCHF",
                user_trade_number: 15,
                weightedAvgLongPrice: 0.9872125,
                weightedAvgShortPrice: 0.98394,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 10.66,
                floatingProfitEOD: -9.48,
                floatingShortProfit: 10.66,
                floatingShortProfitEOD: -9.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999104",
                longPositions: 0,
                netPositions: -0.35000000000000003,
                shortPositions: 0.35000000000000003,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.88635,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -120.28,
                floatingLongProfitEOD: 1.52,
                floatingProfit: -120.28,
                floatingProfitEOD: 1.52,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.90718,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 782.78,
                floatingProfitEOD: -24.39,
                floatingShortProfit: 782.78,
                floatingShortProfitEOD: -24.39,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0,
                netPositions: -0.8999999999999999,
                shortPositions: 0.8999999999999999,
                symbol: "USDCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8937766666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -99.2,
                floatingLongProfitEOD: 2.44,
                floatingProfit: -99.2,
                floatingProfitEOD: 2.44,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813752",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.8968349999999999,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -32.34,
                floatingLongProfitEOD: 0.91,
                floatingProfit: -32.34,
                floatingProfitEOD: 0.91,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813995",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.8954000000000002,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -485.77,
                floatingLongProfitEOD: 12.63,
                floatingProfit: -485.77,
                floatingProfitEOD: 12.63,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810483",
                longPositions: 0.42000000000000004,
                netPositions: 0.42000000000000004,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.8960921428571429,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.76,
                floatingLongProfitEOD: 0.3,
                floatingProfit: -10.76,
                floatingProfitEOD: 0.3,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809373",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.89538,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -49.8,
                floatingLongProfitEOD: 1.21,
                floatingProfit: -49.8,
                floatingProfitEOD: 1.21,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812302",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.8968799999999999,
                weightedAvgShortPrice: 0,
            },
        ],
        NZDJPY: [
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.53,
                closedLongProfitEOD: 0.53,
                closedProfit: 0.53,
                closedProfitEOD: 0.53,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.39,
                floatingProfitEOD: -5.04,
                floatingShortProfit: -9.39,
                floatingShortProfitEOD: -5.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "NZDJPY",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 89.51975,
            },
            {
                closedLongPositions: 1.38,
                closedLongProfit: 92.56,
                closedLongProfitEOD: 168.55,
                closedProfit: 92.56,
                closedProfitEOD: 168.55,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.24,
                floatingLongProfitEOD: -0.24,
                floatingProfit: -22.9,
                floatingProfitEOD: -14.23,
                floatingShortProfit: -22.66,
                floatingShortProfitEOD: -13.99,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.009999999999999917,
                netPositions: -0.1400000000000001,
                shortPositions: 0.15000000000000002,
                symbol: "NZDJPY",
                user_trade_number: 33,
                weightedAvgLongPrice: 89.88400000000145,
                weightedAvgShortPrice: 89.64513333333332,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -17.41,
                floatingLongProfitEOD: 3.85,
                floatingProfit: -17.41,
                floatingProfitEOD: 3.85,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 3,
                weightedAvgLongPrice: 90.71833333333335,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -45.3,
                floatingProfitEOD: -2.46,
                floatingShortProfit: -45.3,
                floatingShortProfitEOD: -2.46,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 86.477,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -641.41,
                floatingProfitEOD: -42.98,
                floatingShortProfit: -641.41,
                floatingShortProfitEOD: -42.98,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0,
                netPositions: -0.35000000000000003,
                shortPositions: 0.35000000000000003,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 87.125,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -808.86,
                floatingProfitEOD: -185.19,
                floatingShortProfit: -808.86,
                floatingShortProfitEOD: -185.19,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799310",
                longPositions: 0,
                netPositions: -1.5,
                shortPositions: 1.5,
                symbol: "NZDJPY",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 89.06400000000001,
            },
            {
                closedLongPositions: 0.15,
                closedLongProfit: -0.2,
                closedLongProfitEOD: -17.52,
                closedProfit: -0.2,
                closedProfitEOD: -17.52,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808280",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 46.03,
                floatingLongProfitEOD: 24.09,
                floatingProfit: 46.03,
                floatingProfitEOD: 24.09,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811737",
                longPositions: 0.19,
                netPositions: 0.19,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 89.48100000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.2,
                closedLongProfit: 24.29,
                closedLongProfitEOD: 1.2,
                closedProfit: 24.29,
                closedProfitEOD: 1.2,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811038",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.32,
                floatingProfitEOD: -1.23,
                floatingShortProfit: -3.32,
                floatingShortProfitEOD: -1.23,
                isInPercentageMode: false,
                login: "DEMO:MT4:8806537",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 89.375,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.74,
                floatingLongProfitEOD: 1.27,
                floatingProfit: 0.74,
                floatingProfitEOD: 1.27,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814715",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 89.736,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -14.03,
                floatingProfitEOD: -1.23,
                floatingShortProfit: -14.03,
                floatingShortProfitEOD: -1.23,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809801",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 87.77,
            },
        ],
        MANU: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -160.32,
                floatingLongProfitEOD: 0,
                floatingProfit: -160.32,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999703",
                longPositions: 501,
                netPositions: 501,
                shortPositions: 0,
                symbol: "MANU",
                user_trade_number: 2,
                weightedAvgLongPrice: 20.17,
                weightedAvgShortPrice: 0,
            },
        ],
        EURAUD: [
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.52,
                closedLongProfitEOD: 0.85,
                closedProfit: 0.52,
                closedProfitEOD: 0.85,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.63,
                floatingLongProfitEOD: -0.63,
                floatingProfit: -11.03,
                floatingProfitEOD: 0.39,
                floatingShortProfit: -10.4,
                floatingShortProfitEOD: 1.02,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799363",
                longPositions: 0.01,
                netPositions: -0.03,
                shortPositions: 0.04,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.6757,
                weightedAvgShortPrice: 1.6708525,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.52,
                closedLongProfitEOD: 0.85,
                closedProfit: 0.52,
                closedProfitEOD: 0.85,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.63,
                floatingLongProfitEOD: -0.63,
                floatingProfit: -11.07,
                floatingProfitEOD: 0.39,
                floatingShortProfit: -10.44,
                floatingShortProfitEOD: 1.02,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799361",
                longPositions: 0.01,
                netPositions: -0.03,
                shortPositions: 0.04,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.6757,
                weightedAvgShortPrice: 1.6708350000000003,
            },
            {
                closedLongPositions: 0.03,
                closedLongProfit: 2.16,
                closedLongProfitEOD: 2.19,
                closedProfit: 2.16,
                closedProfitEOD: 2.19,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.6,
                floatingLongProfitEOD: -2.6,
                floatingProfit: -6.78,
                floatingProfitEOD: -2.1,
                floatingShortProfit: -4.18,
                floatingShortProfitEOD: 0.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799426",
                longPositions: 0.009999999999999997,
                netPositions: -0.010000000000000004,
                shortPositions: 0.02,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.6785700000000006,
                weightedAvgShortPrice: 1.6716399999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.22,
                floatingLongProfitEOD: -0.43,
                floatingProfit: -48.18,
                floatingProfitEOD: 1.09,
                floatingShortProfit: -45.96,
                floatingShortProfitEOD: 1.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799446",
                longPositions: 0.02,
                netPositions: -0.04999999999999999,
                shortPositions: 0.06999999999999999,
                symbol: "EURAUD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.676375,
                weightedAvgShortPrice: 1.664827142857143,
            },
            {
                closedLongPositions: 0.02,
                closedLongProfit: 1.04,
                closedLongProfitEOD: 1.72,
                closedProfit: 1.04,
                closedProfitEOD: 1.72,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.73,
                floatingLongProfitEOD: -0.73,
                floatingProfit: -24.39,
                floatingProfitEOD: 1.85,
                floatingShortProfit: -23.66,
                floatingShortProfitEOD: 2.58,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799775",
                longPositions: 0.02,
                netPositions: -0.08,
                shortPositions: 0.1,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.6751600000000002,
                weightedAvgShortPrice: 1.6712110000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -79.47,
                floatingProfitEOD: 8.49,
                floatingShortProfit: -79.47,
                floatingShortProfitEOD: 8.49,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799488",
                longPositions: 0,
                netPositions: -0.33,
                shortPositions: 0.33,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6711478787878784,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.24,
                floatingLongProfitEOD: -0.54,
                floatingProfit: -6.41,
                floatingProfitEOD: -0.03,
                floatingShortProfit: -5.17,
                floatingShortProfitEOD: 0.51,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799312",
                longPositions: 0.02,
                netPositions: 0,
                shortPositions: 0.02,
                symbol: "EURAUD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1.6755400000000003,
                weightedAvgShortPrice: 1.67088,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -110.01,
                floatingProfitEOD: 0.85,
                floatingShortProfit: -110.01,
                floatingShortProfitEOD: 0.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "EURAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6412,
            },
            {
                closedLongPositions: 0.06,
                closedLongProfit: 3.89,
                closedLongProfitEOD: 2.7,
                closedProfit: 3.89,
                closedProfitEOD: 2.7,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.5,
                floatingLongProfitEOD: -4.5,
                floatingProfit: -28.27,
                floatingProfitEOD: 0.56,
                floatingShortProfit: -23.77,
                floatingShortProfitEOD: 5.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.04000000000000001,
                netPositions: -0.18,
                shortPositions: 0.22,
                symbol: "EURAUD",
                user_trade_number: 20,
                weightedAvgLongPrice: 1.6762999999999997,
                weightedAvgShortPrice: 1.6732263636363633,
            },
            {
                closedLongPositions: 0.06,
                closedLongProfit: 3.91,
                closedLongProfitEOD: 2.63,
                closedProfit: 3.91,
                closedProfitEOD: 2.63,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.5,
                floatingLongProfitEOD: -4.5,
                floatingProfit: -28.21,
                floatingProfitEOD: 0.56,
                floatingShortProfit: -23.71,
                floatingShortProfitEOD: 5.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.04000000000000001,
                netPositions: -0.18,
                shortPositions: 0.22,
                symbol: "EURAUD",
                user_trade_number: 20,
                weightedAvgLongPrice: 1.6763000000000003,
                weightedAvgShortPrice: 1.6732313636363638,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -10.49,
                floatingProfitEOD: 0.96,
                floatingShortProfit: -10.49,
                floatingShortProfitEOD: 0.96,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799515",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "EURAUD",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.670845,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -130.29,
                floatingProfitEOD: 18.3,
                floatingShortProfit: -130.29,
                floatingShortProfitEOD: 18.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999596",
                longPositions: 0,
                netPositions: -0.7200000000000001,
                shortPositions: 0.7200000000000001,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6720719444444443,
            },
            {
                closedLongPositions: 0.04,
                closedLongProfit: 4.61,
                closedLongProfitEOD: 3.2,
                closedProfit: 4.61,
                closedProfitEOD: 3.2,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "EURAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -906.65,
                floatingProfitEOD: 5.76,
                floatingShortProfit: -906.65,
                floatingShortProfitEOD: 5.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807494",
                longPositions: 0,
                netPositions: -0.26,
                shortPositions: 0.26,
                symbol: "EURAUD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6213761538461537,
            },
            {
                closedLongPositions: 0.1,
                closedLongProfit: 11.53,
                closedLongProfitEOD: 8.01,
                closedProfit: 11.53,
                closedProfitEOD: 8.01,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808198",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "EURAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
        ],
        NZDCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -12.47,
                floatingLongProfitEOD: 6.2,
                floatingProfit: -12.47,
                floatingProfitEOD: 6.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808053",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -9.18,
                floatingLongProfitEOD: 9.29,
                floatingProfit: -9.18,
                floatingProfitEOD: 9.29,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810483",
                longPositions: 0.15,
                netPositions: 0.15,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82343,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -510.65,
                floatingProfitEOD: -32.89,
                floatingShortProfit: -510.65,
                floatingShortProfitEOD: -32.89,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808198",
                longPositions: 0,
                netPositions: -0.6000000000000001,
                shortPositions: 0.6000000000000001,
                symbol: "NZDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.811245,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -29.09,
                floatingLongProfitEOD: 6.34,
                floatingProfit: -29.09,
                floatingProfitEOD: 6.34,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812920",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8266000000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -47.89,
                floatingLongProfitEOD: 9.08,
                floatingProfit: -47.89,
                floatingProfitEOD: 9.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811597",
                longPositions: 0.15,
                netPositions: 0.15,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82695,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.09,
                floatingLongProfitEOD: 4.96,
                floatingProfit: -10.09,
                floatingProfitEOD: 4.96,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812134",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82432,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.99,
                floatingLongProfitEOD: 2.48,
                floatingProfit: -4.99,
                floatingProfitEOD: 2.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807708",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.7,
                closedLongProfitEOD: 0.93,
                closedProfit: 0.7,
                closedProfitEOD: 0.93,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808505",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -11.73,
                floatingProfitEOD: -1.59,
                floatingShortProfit: -11.73,
                floatingShortProfitEOD: -1.59,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "NZDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8175366666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -12.47,
                floatingLongProfitEOD: 6.2,
                floatingProfit: -12.47,
                floatingProfitEOD: 6.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809205",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -17.66,
                floatingLongProfitEOD: 8.67,
                floatingProfit: -17.66,
                floatingProfitEOD: 8.67,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807457",
                longPositions: 0.14,
                netPositions: 0.14,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82432,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -23.96,
                floatingLongProfitEOD: 11.77,
                floatingProfit: -23.96,
                floatingProfitEOD: 11.77,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808766",
                longPositions: 0.19,
                netPositions: 0.19,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82432,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -12.47,
                floatingLongProfitEOD: 6.2,
                floatingProfit: -12.47,
                floatingProfitEOD: 6.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808676",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -12.68,
                floatingLongProfitEOD: 6.2,
                floatingProfit: -12.68,
                floatingProfitEOD: 6.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807499",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82433,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.41,
                floatingProfitEOD: -1.65,
                floatingShortProfit: -7.41,
                floatingShortProfitEOD: -1.65,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "NZDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8195266666666666,
            },
        ],
        CADJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.39,
                floatingLongProfitEOD: 3.15,
                floatingProfit: -3.39,
                floatingProfitEOD: 3.15,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 109.327,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.42,
                floatingLongProfitEOD: 2.37,
                floatingProfit: -5.42,
                floatingProfitEOD: 2.37,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 109.47066666666666,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.45,
                floatingLongProfitEOD: 3.94,
                floatingProfit: -10.45,
                floatingProfitEOD: 3.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813889",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 109.513,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -41.98,
                floatingLongProfitEOD: 3.94,
                floatingProfit: -41.98,
                floatingProfitEOD: 3.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811737",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 110.458,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -19.11,
                floatingProfitEOD: -6.19,
                floatingShortProfit: -19.11,
                floatingShortProfitEOD: -6.19,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810133",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 108.87100000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -19,
                floatingLongProfitEOD: 336.79,
                floatingProfit: -37.6,
                floatingProfitEOD: 328.44,
                floatingShortProfit: -18.6,
                floatingShortProfitEOD: -8.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 4.37,
                netPositions: 4.25,
                shortPositions: 0.12000000000000001,
                symbol: "CADJPY",
                user_trade_number: 39,
                weightedAvgLongPrice: 109.20543707093822,
                weightedAvgShortPrice: 108.99691666666664,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -16.63,
                floatingLongProfitEOD: 336.79,
                floatingProfit: -29.68,
                floatingProfitEOD: 330.04,
                floatingShortProfit: -13.05,
                floatingShortProfitEOD: -6.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 4.37,
                netPositions: 4.2700000000000005,
                shortPositions: 0.1,
                symbol: "CADJPY",
                user_trade_number: 38,
                weightedAvgLongPrice: 109.20462242562928,
                weightedAvgShortPrice: 109.03360000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -98.95,
                floatingProfitEOD: -5.46,
                floatingShortProfit: -98.95,
                floatingShortProfitEOD: -5.46,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.07,
                shortPositions: 0.07,
                symbol: "CADJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 107.11071428571428,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: -2.74,
                closedLongProfitEOD: -1.17,
                closedProfit: -2.74,
                closedProfitEOD: -1.17,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.46,
                floatingLongProfitEOD: 0.79,
                floatingProfit: 0.46,
                floatingProfitEOD: 0.79,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809551",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 109.131,
                weightedAvgShortPrice: 0,
            },
        ],
        US30: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1876.65,
                floatingProfitEOD: -44.32,
                floatingShortProfit: -1876.65,
                floatingShortProfitEOD: -44.32,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810472",
                longPositions: 0,
                netPositions: -2.6,
                shortPositions: 2.6,
                symbol: "US30",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 34242.92076923076,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 3.66,
                floatingProfitEOD: -1.7,
                floatingShortProfit: 3.66,
                floatingShortProfitEOD: -1.7,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809227",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "US30",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 35001.34,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -387.53,
                floatingProfitEOD: -30.52,
                floatingShortProfit: -387.53,
                floatingShortProfitEOD: -30.52,
                isInPercentageMode: false,
                login: "DEMO:MT5:800038",
                longPositions: 0,
                netPositions: -1.8,
                shortPositions: 1.8,
                symbol: "US30",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 34749.415,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -22241.7,
                floatingProfitEOD: -160.5,
                floatingShortProfit: -22241.7,
                floatingShortProfitEOD: -160.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999936",
                longPositions: 0,
                netPositions: -10,
                shortPositions: 10,
                symbol: "US30",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 32739.54,
            },
        ],
        BCHUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.18,
                floatingLongProfitEOD: 0.06,
                floatingProfit: -0.18,
                floatingProfitEOD: 0.06,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814337",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "BCHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 236.386,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.37,
                floatingProfitEOD: -0.17,
                floatingShortProfit: -0.37,
                floatingShortProfitEOD: -0.17,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812260",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "BCHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 226.519,
            },
        ],
        AAPL: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.04,
                floatingProfitEOD: 0,
                floatingShortProfit: -7.04,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813904",
                longPositions: 0,
                netPositions: -4,
                shortPositions: 4,
                symbol: "AAPL",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 188.01,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 131.11,
                floatingLongProfitEOD: 0,
                floatingProfit: 131.11,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:601",
                longPositions: 8,
                netPositions: 8,
                shortPositions: 0,
                symbol: "AAPL",
                user_trade_number: 2,
                weightedAvgLongPrice: 173.28125,
                weightedAvgShortPrice: 0,
            },
        ],
        JP225: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2108.47,
                floatingProfitEOD: 8.33,
                floatingShortProfit: -2108.47,
                floatingShortProfitEOD: 8.33,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999955",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "JP225",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 27159.43,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 48.72,
                floatingLongProfitEOD: -1.87,
                floatingProfit: 48.72,
                floatingProfitEOD: -1.87,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799564",
                longPositions: 0.2,
                netPositions: 0.2,
                shortPositions: 0,
                symbol: "JP225",
                user_trade_number: 1,
                weightedAvgLongPrice: 32737.01,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.14,
                floatingProfitEOD: 0.83,
                floatingShortProfit: -7.14,
                floatingShortProfitEOD: 0.83,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814969",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "JP225",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 33263.99,
            },
        ],
        UKOUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -91.78,
                floatingLongProfitEOD: 0.82,
                floatingProfit: -91.78,
                floatingProfitEOD: 0.82,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999982",
                longPositions: 1,
                netPositions: 1,
                shortPositions: 0,
                symbol: "UKOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 90.274,
                weightedAvgShortPrice: 0,
            },
        ],
        AUDJPY: [
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.53,
                closedLongProfitEOD: 0.34,
                closedProfit: 0.53,
                closedProfitEOD: 0.34,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -10.98,
                floatingProfitEOD: -2.52,
                floatingShortProfit: -10.98,
                floatingShortProfitEOD: -2.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799363",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "AUDJPY",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 97.188,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -232.58,
                floatingProfitEOD: -25.99,
                floatingShortProfit: -232.58,
                floatingShortProfitEOD: -25.99,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799360",
                longPositions: 0,
                netPositions: -0.41000000000000003,
                shortPositions: 0.41000000000000003,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 96.75,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -52906.03,
                floatingProfitEOD: -1455.42,
                floatingShortProfit: -52906.03,
                floatingShortProfitEOD: -1455.42,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799947",
                longPositions: 0,
                netPositions: -23.2,
                shortPositions: 23.2,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 94.17499999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5831.95,
                floatingProfitEOD: -163.11,
                floatingShortProfit: -5831.95,
                floatingShortProfitEOD: -163.11,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799946",
                longPositions: 0,
                netPositions: -2.6,
                shortPositions: 2.6,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 94.231,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.67,
                closedLongProfitEOD: 0.57,
                closedProfit: 0.67,
                closedProfitEOD: 0.57,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.29,
                floatingLongProfitEOD: -0.29,
                floatingProfit: -28.92,
                floatingProfitEOD: -9.64,
                floatingShortProfit: -28.63,
                floatingShortProfitEOD: -9.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.21,
                shortPositions: 0.22,
                symbol: "AUDJPY",
                user_trade_number: 13,
                weightedAvgLongPrice: 97.612,
                weightedAvgShortPrice: 97.39840909090908,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -52.4,
                floatingProfitEOD: -3.5,
                floatingShortProfit: -52.4,
                floatingShortProfitEOD: -3.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0,
                netPositions: -0.06000000000000001,
                shortPositions: 0.06000000000000001,
                symbol: "AUDJPY",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 96.28366666666665,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 22.07,
                floatingLongProfitEOD: 0.61,
                floatingProfit: 22.07,
                floatingProfitEOD: 0.61,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799876",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 94.262,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -56.65,
                floatingProfitEOD: -1.12,
                floatingShortProfit: -56.65,
                floatingShortProfitEOD: -1.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 93.345,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.06,
                floatingProfitEOD: -0.56,
                floatingShortProfit: -1.06,
                floatingShortProfitEOD: -0.56,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 97.431,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -11.48,
                floatingProfitEOD: -2.54,
                floatingShortProfit: -11.48,
                floatingShortProfitEOD: -2.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 97.171,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.83,
                floatingProfitEOD: -0.56,
                floatingShortProfit: -1.83,
                floatingShortProfitEOD: -0.56,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 97.316,
            },
        ],
        AUDCNH: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2256.43,
                floatingLongProfitEOD: -85.04,
                floatingProfit: -2256.43,
                floatingProfitEOD: -85.04,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800001",
                longPositions: 5.58,
                netPositions: 5.58,
                shortPositions: 0,
                symbol: "AUDCNH",
                user_trade_number: 7,
                weightedAvgLongPrice: 4.729746254480287,
                weightedAvgShortPrice: 0,
            },
        ],
        US500: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -278.41,
                floatingProfitEOD: -1.68,
                floatingShortProfit: -278.41,
                floatingShortProfitEOD: -1.68,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814302",
                longPositions: 0,
                netPositions: -2,
                shortPositions: 2,
                symbol: "US500",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4377.965,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.94,
                floatingProfitEOD: -0.67,
                floatingShortProfit: -0.94,
                floatingShortProfitEOD: -0.67,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812788",
                longPositions: 0,
                netPositions: -0.8,
                shortPositions: 0.8,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4516,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 15.94,
                floatingProfitEOD: -2.77,
                floatingShortProfit: 15.94,
                floatingShortProfitEOD: -2.77,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812735",
                longPositions: 0,
                netPositions: -3.3000000000000003,
                shortPositions: 3.3000000000000003,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4522,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -464.18,
                floatingProfitEOD: -1.68,
                floatingShortProfit: -464.18,
                floatingShortProfitEOD: -1.68,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809249",
                longPositions: 0,
                netPositions: -2,
                shortPositions: 2,
                symbol: "US500",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4285.08,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -36.79,
                floatingProfitEOD: -0.17,
                floatingShortProfit: -36.79,
                floatingShortProfitEOD: -0.17,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813948",
                longPositions: 0,
                netPositions: -0.2,
                shortPositions: 0.2,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4333.22,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.64,
                floatingProfitEOD: -0.08,
                floatingShortProfit: -0.64,
                floatingShortProfitEOD: -0.08,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809227",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4510.79,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 5.16,
                floatingProfitEOD: -0.59,
                floatingShortProfit: 5.16,
                floatingShortProfitEOD: -0.59,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810997",
                longPositions: 0,
                netPositions: -0.7000000000000001,
                shortPositions: 0.7000000000000001,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4524.54,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -1044.85,
                closedProfitEOD: -7.41,
                closedShortPositions: 5.4,
                closedShortProfit: -1044.85,
                closedShortProfitEOD: -7.41,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810611",
                longPositions: 0,
                netPositions: 1.1102230246251565e-15,
                shortPositions: -1.1102230246251565e-15,
                symbol: "US500",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: -3686.4,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0.89,
                floatingProfitEOD: -0.08,
                floatingShortProfit: 0.89,
                floatingShortProfitEOD: -0.08,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811511",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4526.04,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 34.89,
                floatingProfitEOD: -4.12,
                floatingShortProfit: 34.89,
                floatingShortProfitEOD: -4.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811554",
                longPositions: 0,
                netPositions: -4.9,
                shortPositions: 4.9,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4524.29,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 116.03,
                floatingLongProfitEOD: 0.86,
                floatingProfit: 116.03,
                floatingProfitEOD: 0.86,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999076",
                longPositions: 1,
                netPositions: 1,
                shortPositions: 0,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 4400.5,
                weightedAvgShortPrice: 0,
            },
        ],
        ETHUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -1.29,
                closedProfitEOD: -1.29,
                closedShortPositions: 0.05,
                closedShortProfit: -1.29,
                closedShortProfitEOD: -1.29,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809080",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2.78,
                floatingLongProfitEOD: 1.77,
                floatingProfit: 2.78,
                floatingProfitEOD: 1.77,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807394",
                longPositions: 0.07,
                netPositions: 0.07,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.08,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 26.25,
                floatingLongProfitEOD: 16.68,
                floatingProfit: 26.25,
                floatingProfitEOD: 16.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809172",
                longPositions: 0.66,
                netPositions: 0.66,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1967.97,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 3.58,
                floatingLongProfitEOD: 2.27,
                floatingProfit: 3.58,
                floatingProfitEOD: 2.27,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811217",
                longPositions: 0.09,
                netPositions: 0.09,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1967.95,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 13.87,
                floatingLongProfitEOD: 8.84,
                floatingProfit: 13.87,
                floatingProfitEOD: 8.84,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810204",
                longPositions: 0.35000000000000003,
                netPositions: 0.35000000000000003,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.1199999999997,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -162.24,
                floatingLongProfitEOD: 57.08,
                floatingProfit: -162.24,
                floatingProfitEOD: 57.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807909",
                longPositions: 4,
                netPositions: 4,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 2048.31,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 34.58,
                floatingLongProfitEOD: 21.98,
                floatingProfit: 34.58,
                floatingProfitEOD: 21.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807869",
                longPositions: 0.87,
                netPositions: 0.87,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 52.85,
                floatingLongProfitEOD: 33.61,
                floatingProfit: 52.85,
                floatingProfitEOD: 33.61,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808578",
                longPositions: 1.33,
                netPositions: 1.33,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.01,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 87.85,
                floatingLongProfitEOD: 55.85,
                floatingProfit: 87.85,
                floatingProfitEOD: 55.85,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808315",
                longPositions: 2.21,
                netPositions: 2.21,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 31.44,
                floatingLongProfitEOD: 14.65,
                floatingProfit: 31.44,
                floatingProfitEOD: 14.65,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813718",
                longPositions: 0.58,
                netPositions: 0.58,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1953.5406896551724,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 34.87,
                floatingLongProfitEOD: 22.24,
                floatingProfit: 34.87,
                floatingProfitEOD: 22.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807667",
                longPositions: 0.88,
                netPositions: 0.88,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.1200000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 9.54,
                floatingLongProfitEOD: 6.06,
                floatingProfit: 9.54,
                floatingProfitEOD: 6.06,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808317",
                longPositions: 0.24,
                netPositions: 0.24,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.02,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 26.23,
                floatingLongProfitEOD: 16.68,
                floatingProfit: 26.23,
                floatingProfitEOD: 16.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808497",
                longPositions: 0.66,
                netPositions: 0.66,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.01,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 21.84,
                floatingLongProfitEOD: 13.9,
                floatingProfit: 21.84,
                floatingProfitEOD: 13.9,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807360",
                longPositions: 0.55,
                netPositions: 0.55,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.05,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1.99,
                floatingLongProfitEOD: 1.26,
                floatingProfit: 1.99,
                floatingProfitEOD: 1.26,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808472",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.05,
                weightedAvgShortPrice: 0,
            },
        ],
        DOTUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.09,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.01,
                floatingProfitEOD: 0,
                floatingShortProfit: -0.1,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813720",
                longPositions: 0.01,
                netPositions: 0,
                shortPositions: 0.01,
                symbol: "DOTUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 5.269,
                weightedAvgShortPrice: 5.267,
            },
        ],
        BABA: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -11.5,
                floatingLongProfitEOD: 0,
                floatingProfit: -11.5,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812667",
                longPositions: 10,
                netPositions: 10,
                shortPositions: 0,
                symbol: "BABA",
                user_trade_number: 3,
                weightedAvgLongPrice: 78.92583333333333,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -13,
                floatingLongProfitEOD: 0,
                floatingProfit: -13,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813822",
                longPositions: 10,
                netPositions: 10,
                shortPositions: 0,
                symbol: "BABA",
                user_trade_number: 2,
                weightedAvgLongPrice: 78.88,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -16.72,
                floatingLongProfitEOD: 0,
                floatingProfit: -16.72,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813904",
                longPositions: 10,
                netPositions: 10,
                shortPositions: 0,
                symbol: "BABA",
                user_trade_number: 2,
                weightedAvgLongPrice: 78.97333333333334,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.65,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.65,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812768",
                longPositions: 5,
                netPositions: 5,
                shortPositions: 0,
                symbol: "BABA",
                user_trade_number: 1,
                weightedAvgLongPrice: 77.91,
                weightedAvgShortPrice: 0,
            },
        ],
        BTCJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 133.25,
                floatingLongProfitEOD: 63.45,
                floatingProfit: -7.5,
                floatingProfitEOD: -1.62,
                floatingShortProfit: -140.75,
                floatingShortProfitEOD: -65.07,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813718",
                longPositions: 0.14,
                netPositions: -0.009999999999999981,
                shortPositions: 0.15,
                symbol: "BTCJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 5462052.3,
                weightedAvgShortPrice: 5477247.7,
            },
        ],
        DE40: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1086.21,
                floatingProfitEOD: 44.2,
                floatingShortProfit: -1086.21,
                floatingShortProfitEOD: 44.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802300",
                longPositions: 0,
                netPositions: -5,
                shortPositions: 5,
                symbol: "DE40",
                user_trade_number: 10,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15744.01,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1293.2,
                floatingLongProfitEOD: -14.07,
                floatingProfit: 1293.2,
                floatingProfitEOD: -14.07,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999459",
                longPositions: 1,
                netPositions: 1,
                shortPositions: 0,
                symbol: "DE40",
                user_trade_number: 1,
                weightedAvgLongPrice: 14747.76,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -86.09,
                floatingProfitEOD: 0,
                floatingShortProfit: -86.09,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT5:800038",
                longPositions: 0,
                netPositions: -1.4000000000000001,
                shortPositions: 1.4000000000000001,
                symbol: "DE40",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15894.89,
            },
        ],
        UK100: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -12.65,
                floatingProfitEOD: -0.27,
                floatingShortProfit: -12.65,
                floatingShortProfitEOD: -0.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802534",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "UK100",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 7415.9,
            },
        ],
        AMGN: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 57.82,
                floatingLongProfitEOD: 0.07,
                floatingProfit: 57.82,
                floatingProfitEOD: 0.07,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 7,
                netPositions: 7,
                shortPositions: 0,
                symbol: "AMGN",
                user_trade_number: 1,
                weightedAvgLongPrice: 256.97,
                weightedAvgShortPrice: 0,
            },
        ],
        USDSEK: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -498.54,
                floatingLongProfitEOD: 6.62,
                floatingProfit: -498.54,
                floatingProfitEOD: 6.62,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811420",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "USDSEK",
                user_trade_number: 1,
                weightedAvgLongPrice: 11.18858,
                weightedAvgShortPrice: 0,
            },
        ],
        NFLX: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 91.55,
                floatingLongProfitEOD: 0,
                floatingProfit: 91.55,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 5,
                netPositions: 5,
                shortPositions: 0,
                symbol: "NFLX",
                user_trade_number: 1,
                weightedAvgLongPrice: 447.36,
                weightedAvgShortPrice: 0,
            },
        ],
        NVDA: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -65.35,
                floatingProfitEOD: 0,
                floatingShortProfit: -65.35,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999825",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "NVDA",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 427.68,
            },
        ],
        META: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 73.98,
                floatingLongProfitEOD: 0,
                floatingProfit: 73.98,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 2,
                netPositions: 2,
                shortPositions: 0,
                symbol: "META",
                user_trade_number: 1,
                weightedAvgLongPrice: 298.02,
                weightedAvgShortPrice: 0,
            },
        ],
        PEP: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -77.13,
                floatingLongProfitEOD: 0,
                floatingProfit: -77.13,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 9,
                netPositions: 9,
                shortPositions: 0,
                symbol: "PEP",
                user_trade_number: 1,
                weightedAvgLongPrice: 175.27,
                weightedAvgShortPrice: 0,
            },
        ],
        WTI01: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 5.83,
                floatingLongProfitEOD: 5.79,
                floatingProfit: 5.83,
                floatingProfitEOD: 5.79,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814408",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "WTI01",
                user_trade_number: 2,
                weightedAvgLongPrice: 76.04066666666668,
                weightedAvgShortPrice: 0,
            },
        ],
        EOSUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.09,
                floatingLongProfitEOD: 0.01,
                floatingProfit: 0.09,
                floatingProfitEOD: 0.01,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809022",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EOSUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.6354,
                weightedAvgShortPrice: 0,
            },
        ],
        USDSGD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -116.73,
                floatingLongProfitEOD: 2.08,
                floatingProfit: -116.73,
                floatingProfitEOD: 2.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811420",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "USDSGD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.36284,
                weightedAvgShortPrice: 0,
            },
        ],
        USDCNH: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -271.65,
                floatingLongProfitEOD: 9.98,
                floatingProfit: -271.65,
                floatingProfitEOD: 9.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800002",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "USDCNH",
                user_trade_number: 2,
                weightedAvgLongPrice: 7.2576149999999995,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3269.01,
                floatingLongProfitEOD: 80.4,
                floatingProfit: -3269.01,
                floatingProfitEOD: 80.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999103",
                longPositions: 4.03,
                netPositions: 4.03,
                shortPositions: 0,
                symbol: "USDCNH",
                user_trade_number: 1,
                weightedAvgLongPrice: 7.27653,
                weightedAvgShortPrice: 0,
            },
        ],
        AUS200: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -25.15,
                floatingLongProfitEOD: -1.41,
                floatingProfit: -25.15,
                floatingProfitEOD: -1.41,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810673",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "AUS200",
                user_trade_number: 1,
                weightedAvgLongPrice: 7438.95,
                weightedAvgShortPrice: 0,
            },
        ],
        LNKUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 7.19,
                floatingLongProfitEOD: 0.01,
                floatingProfit: 7.19,
                floatingProfitEOD: 0.01,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812966",
                longPositions: 0.9899999999999993,
                netPositions: 0.9899999999999993,
                shortPositions: 0,
                symbol: "LNKUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 14.254000000000014,
                weightedAvgShortPrice: 0,
            },
        ],
        XRPUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.07,
                floatingLongProfitEOD: 0.12,
                floatingProfit: 0.07,
                floatingProfitEOD: 0.12,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808592",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "XRPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.62598,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 8.16,
                floatingLongProfitEOD: 2.56,
                floatingProfit: 8.16,
                floatingProfitEOD: 2.56,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805314",
                longPositions: 0.4,
                netPositions: 0.4,
                shortPositions: 0,
                symbol: "XRPUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.61773,
                weightedAvgShortPrice: 0,
            },
        ],
        ETHJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2.79,
                floatingLongProfitEOD: 2.11,
                floatingProfit: 2.79,
                floatingProfitEOD: 2.11,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813718",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "ETHJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 295429.6,
                weightedAvgShortPrice: 0,
            },
        ],
    },
};
