var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{ref:"card",staticClass:"flex-shrink-0 test1234",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-1",staticStyle:{"border-bottom":"1px solid #404040"}},[_c('div',{staticClass:"text-left",staticStyle:{"margin-left":"0","width":"50%","font-size":"13px","font-weight":"normal"}},[_vm._v(" ALL BOOKS ")]),_c('div',{staticClass:"text-right",staticStyle:{"margin-right":"0","width":"50%"}},[(_vm.loadDailySummaryIcon === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"dark":"","color":"primary"},on:{"click":function($event){_vm.dailySummaryDialog = true}}},'v-icon',attrs,false),on),[_vm._v("mdi-text-box")])]}}],null,false,1590029047)},[_c('span',[_vm._v("Historical Daily Summary")])]):_vm._e(),_c('download-excel',{staticStyle:{"display":"inline"},attrs:{"data":_vm.briefData,"fields":_vm.allBookCsvFields,"name":"All Book"}},[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"color":"green","dark":"","dense":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.briefHeader,"items":_vm.computedParentData,"item-key":"name","hide-default-footer":true,"height":_vm.tableHeight,"items-per-page":-1,"header-props":{ sortIcon: null },"fixed-header":"","expanded":_vm.expanded},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.expanded.includes(item.book))?_c('td',{staticStyle:{"padding":"0px"},attrs:{"colspan":headers.length}},[_c('v-data-table',{key:item.book,staticClass:"all-book-expand-item",attrs:{"dense":"","fixed-header":"","headers":_vm.briefChildrenHeader,"hide-default-header":true,"hide-default-footer":true,"header-props":{ sortIcon: null },"items":_vm.computedChildrenData(item.book),"item-key":"book"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"bookCell",attrs:{"align":"left"}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-subdirectory-arrow-right")]),_c('a',{on:{"click":function($event){return _vm.changeBook(item.book)}}},[_vm._v(_vm._s(item.book))])],1),_c('td',{staticClass:"NPNLCell",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( parseFloat(item.NPNL).toFixed(2) ))+" ")]),_c('td',{staticClass:"CPNLCell",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( parseFloat(item.CPNL).toFixed(2) ))+" ")]),_c('td',{staticClass:"FPNLCell",attrs:{"align":"right"}},[_c('div',{style:('backgroundColor:' + item.color)},[_vm._v(" "+_vm._s(_vm.numberWithCommas( parseFloat( item.FPNL ).toFixed(2) ))+" ")])]),(
                                        _vm.briefChildrenHeader.find(
                                            function (item) { return item.value === 'mtdPnl'; }
                                        ).align !== ' d-none'
                                    )?_c('td',{staticClass:"mtdPnlCell",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.outputMtdPnl(item.book))+" ")]):_vm._e(),(
                                        _vm.briefChildrenHeader.find(
                                            function (item) { return item.value === 'mtdVol'; }
                                        ).align !== ' d-none'
                                    )?_c('td',{staticClass:"mtdVolCell",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.outputMtdVolume(item.book))+" ")]):_vm._e()])]}}],null,true)})],1):_vm._e()]}},{key:"header.NPNL",fn:function(ref){
                                    var header = ref.header;
return [_c('div',{staticStyle:{"min-width":"90px","height":"100%"}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","size":"14","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Current net PNL")])])],1)]}},{key:"header.CPNL",fn:function(ref){
                                    var header = ref.header;
return [_c('div',{staticStyle:{"min-width":"90px","height":"100%"}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","size":"14","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Current closed PNL")])])],1)]}},{key:"header.FPNL",fn:function(ref){
                                    var header = ref.header;
return [_c('div',{staticStyle:{"min-width":"90px","height":"100%"}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","size":"14","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Current floating PNL")])])],1)]}},{key:"header.mtdPnl",fn:function(ref){
                                    var header = ref.header;
return [_c('div',{staticStyle:{"min-width":"90px","height":"100%"}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","size":"14","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Month to date PNL (Daily Updated)")])])],1)]}},{key:"header.mtdVol",fn:function(ref){
                                    var header = ref.header;
return [_c('div',{staticStyle:{"min-width":"90px","height":"100%"}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","size":"14","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Month to date volume (Daily Updated)")])])],1)]}},{key:"item",fn:function(ref){
                                    var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_c('a',{on:{"click":function($event){return _vm.changeBook(item.book)}}},[_vm._v(_vm._s(item.book))]),_c('span',[(
                                    item.hasChildren &&
                                    _vm.expanded.includes(item.book)
                                )?_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-arrow-down")]):(item.hasChildren)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-arrow-right")]):_vm._e()],1)]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( parseFloat(item.NPNL).toFixed(2) ))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas( parseFloat(item.CPNL).toFixed(2) ))+" ")]),_c('td',{attrs:{"align":"right"}},[_c('div',{style:('backgroundColor:' + item.color)},[_vm._v(" "+_vm._s(_vm.numberWithCommas( parseFloat(item.FPNL).toFixed(2) ))+" ")])]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.outputMtdPnl(item.book)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.outputMtdVolume(item.book)))])])]}}])})],1),_c('v-dialog',{attrs:{"width":"700","persistent":true},model:{value:(_vm.dailySummaryDialog),callback:function ($$v) {_vm.dailySummaryDialog=$$v},expression:"dailySummaryDialog"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v(" Daily Summary ")]),_c('v-spacer'),_c('v-icon',{attrs:{"disabled":_vm.dailySummaryTableData.length === 0 ? true : false,"color":"green"},on:{"click":_vm.onDailySummaryDataDownload}},[_vm._v(" mdi-microsoft-excel ")]),_c('download-excel',{staticStyle:{"display":"none"},attrs:{"id":"dailySummaryDownload","data":_vm.dailySummaryTableData,"fields":_vm.test,"type":"csv","name":"DailySummary.csv"}}),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dailySummaryDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.selectedDailySummaryDate),callback:function ($$v) {_vm.selectedDailySummaryDate=$$v},expression:"selectedDailySummaryDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.selectedDailySummaryDate),callback:function ($$v) {_vm.selectedDailySummaryDate=$$v},expression:"selectedDailySummaryDate"}})],1)],1),_c('v-col',{staticClass:"pt-7 pl-0 pb-0",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"fab":"","x-small":"","color":"primary","shape":"circle"},on:{"click":_vm.fetchDailySummary}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 flex-grow-1",attrs:{"dense":"","loading":_vm.loading,"headers":_vm.dailySummaryTableHeader,"items":_vm.dailySummaryTableData,"header-props":{ sortIcon: null },"item-key":"id","items-per-page":25,"id":"daily-summary-table","fixed-header":""}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }